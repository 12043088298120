import { Form, Formik, FormikHelpers } from "formik";
import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import Button from "../../Utils/Button";
import FormGroupFechas from "../../Utils/FormGroupFechas";
import { FormGroupRadioButton } from "../../Utils/FormGroupRadioButton";
import FormGroupText from "../../Utils/FormGroupText";
import { OnlyNumber, OnlyNumberFloat } from "../../Utils/Validaciones";

export const TasasModificacion = (props: tasasModificacionPropsInterface) => {
  const [valueRadio, setValueRadio] = useState("1");
  const location = useLocation();
  const [show, setShow] = useState(false);
  const [readOnly, setReadOnly] = useState(false);

  useEffect(() => {
    location.pathname.split("/")[1] === "prestamo" ||
    location.pathname.split("/")[1] === "prestamoCompany"
      ? setShow(true)
      : setShow(false);

    location.pathname.split("/")[1] === "prestamoDetail" ||
    location.pathname.split("/")[1] === "prestamoCompanyDetail"
      ? setReadOnly(true)
      : setReadOnly(false);
  }, []);

  const handleChange = (e: any) => {
    setValueRadio(e.target.value);
  };

  return (
    <>
      <Formik
        initialValues={props.modelo}
        onSubmit={props.click!}
        validationSchema={props.validationSchema}
      >
        {(formikProps) => (
          <>
            <Form>
              <h5 className="mb-2 mt-2">Condiciones de Pago</h5>
              <div
                className="row detalle-tasa font-16"
                // style={{ maxWidth: "100px" }}
              >
                <div className="col-12 col-md-4 d-flex  align-items-center">
                  <FormGroupText
                    campo="plazo"
                    clase="d-flex align-items-center"
                    claseInput="form-control text-center"
                    claseLabel="mr-2"
                    label="Plazo"
                    readonly={props.readonly}
                    type="text"
                    changeValue={OnlyNumber}
                  ></FormGroupText>
                </div>
                <div className="col-12 col-md-4  d-flex align-items-center">
                  <FormGroupFechas
                    campo="fecha"
                    clase="d-flex align-items-center"
                    claseInput="form-control text-center"
                    claseLabel="mr-4"
                    label="Fecha Aprobación"
                    readonly={readOnly}
                  ></FormGroupFechas>
                </div>
                <div className="col-12 col-md-4  d-flex align-items-center">
                  <FormGroupFechas
                    campo="fechaPago"
                    clase="d-flex align-items-center"
                    claseInput="form-control text-center"
                    claseLabel="mr-4"
                    label="Fecha de Pago"
                    readonly={readOnly}
                  ></FormGroupFechas>
                </div>
              </div>
              <h5 className="mt-2 mb-2">Tasas %</h5>
              <div className="row detalle-tasa font-16">
                <div className="col-12 col-md-3 d-flex  align-items-center mb-1">
                  <FormGroupText
                    campo="interes"
                    clase="d-flex align-items-center"
                    claseInput="form-control text-center"
                    claseLabel="mr-2"
                    label="Interés"
                    readonly={props.readonly}
                    type="text"
                    changeValue={OnlyNumberFloat}
                  ></FormGroupText>
                </div>
                <div className="col-12 col-md-3 d-flex align-items-center mb-1">
                  <FormGroupText
                    campo="mora"
                    clase="d-flex align-items-center"
                    claseInput="form-control text-center"
                    claseLabel="mr-2"
                    label="Mora"
                    readonly={props.readonly}
                    type="text"
                    changeValue={OnlyNumberFloat}
                  ></FormGroupText>
                </div>
                <div className="col-12 col-md-3 mb-1 d-flex align-items-center">
                  <FormGroupText
                    campo="gastosAdministracion"
                    clase="d-flex align-items-center"
                    claseInput="form-control text-center"
                    claseLabel="mr-2"
                    label="Gastos Admin."
                    readonly={props.readonly}
                    type="text"
                    changeValue={OnlyNumberFloat}
                  ></FormGroupText>
                </div>
                <div className="col-12 col-md-3 d-flex align-items-center">
                  <FormGroupText
                    campo="iva"
                    clase="d-flex align-items-center "
                    claseInput="form-control text-center"
                    claseLabel="mr-2"
                    label="IVA"
                    readonly={props.readonly}
                    type="text"
                    changeValue={OnlyNumberFloat}
                  ></FormGroupText>
                </div>
              </div>
              {/*  */}
              {show && (
                <div className="row font-16 mt-3">
                  <div className="col-12 col-md-2 align-items-center d-flex">
                    <Button className="orange-dark-button" type="submit">
                      Plan de Pago
                    </Button>
                  </div>
                </div>
              )}
            </Form>
          </>
        )}
      </Formik>
    </>
  );
};

interface tasasModificacionPropsInterface {
  interes?: any;
  mora?: any;
  iva?: any;
  plazo?: any;
  readonly?: boolean;
  click?(valores: any, acciones: FormikHelpers<any>): void;
  validationSchema: any;

  modelo: any;
}

TasasModificacion.defaultProps = {
  readonly: true,
};
