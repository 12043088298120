import { useEffect, useState } from "react";
import { selectData } from "../Interfaces/opciones.model";
import { GetDataSelect } from "../Helpers/GetDataSelect";

export const useAxiosSelectData = (endpoint: string) => {
  const [state, setstate] = useState<hookPersonal>({
    data: [],
    loading: true,
  });

  useEffect(() => {
    GetDataSelect(endpoint).then((response) => {
      return setstate({
        data: response,
        loading: false,
      });
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return state;
};

export const useAxiosSelectDataDescription = (endpoint: string) => {
  const [state, setstate] = useState<hookPersonal>({
    data: [],
    loading: true,
  });

  useEffect(() => {
    GetDataSelect(endpoint).then((response) => {
      let newData: any[] = [];
      response.forEach((element: any) => {
        newData.push({
          id: element.id,
          nombre: element.descripcion,
        });
      });
      return setstate({
        data: newData,
        loading: false,
      });
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return state;
};

interface hookPersonal {
  data: selectData[];
  loading: boolean;
}
