import React, { useRef } from "react";
import { Currency } from "../Utils/ConvertValueToLocalValue";
import ListadoGenerico from "../Utils/ListadoGenerico";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faClose, faPrint } from "@fortawesome/free-solid-svg-icons";
import { TableConsultaEstadoCuentaPrint } from "./TableConsultaEstadoCuentaPrint";
import ReactToPrint from "react-to-print";
import { useDownloadExcel } from "react-export-table-to-excel";
import moment from "moment";

export const TableConsultaEstadoCuenta = (
  props: tableEstadoCuentaInterface
) => {
  const componentRef = useRef(null);
  const tableRef = useRef(null);

  const { onDownload } = useDownloadExcel({
    currentTableRef: tableRef.current,
    filename: `Estado de Cuenta ${props.nombrePrestamo}`,
    sheet: "estado",
  });
  return (
    <>
      <div className="container-fluid">
        <div className="row">
          <div className="col-12 justify-content-between d-flex">
            <h3>
              Estado de Cuenta
              <ReactToPrint
                trigger={() => {
                  return (
                    <FontAwesomeIcon
                      className="mouse-pointer orange-icon ml-4"
                      icon={faPrint}
                    ></FontAwesomeIcon>
                  );
                }}
                content={() => componentRef.current}
                documentTitle="new Document"
                pageStyle="print"
              ></ReactToPrint>{" "}
              <TableConsultaEstadoCuentaPrint
                printReference={componentRef}
                data={props.data}
                nit={props.nit}
                prestamoNombre={props.nombrePrestamo}
                tipoPrestamo={props.tipoPrestamo}
              ></TableConsultaEstadoCuentaPrint>
              <button
                onClick={onDownload}
                className="ml-3 btn only-orange-button"
              >
                {" "}
                Exportar a Excel{" "}
              </button>
            </h3>
            <FontAwesomeIcon
              className="mouse-pointer orange-icon ml-4"
              icon={faClose}
              onClick={() => props.handleCloseModal!()}
              style={{ height: "24px" }}
            ></FontAwesomeIcon>
          </div>
        </div>
        <hr />
        <div className="table-responsive mt-3 container_head_sticky mb-4">
          <table
            className="table table-bordered text-center"
            rules="all"
            ref={tableRef}
          >
            <thead className="header_sticky">
              <tr>
                <th scope="col">FECHA</th>
                <th scope="col">TRANSACCIÓN</th>
                <th scope="col">CONCEPTO</th>
                <th scope="col">CARGO</th>
                <th scope="col">ABONO</th>
                <th scope="col">SALDO ACTUAL</th>
              </tr>
            </thead>
            <ListadoGenerico
              listado={props.data}
              cargandoUI={
                <>
                  <tbody className="size_type_font tabla_directorio_body">
                    <tr>
                      <td colSpan={8}>Esperando respuesta.</td>
                    </tr>
                  </tbody>
                </>
              }
              listadoVacioUI={
                <>
                  <tbody className="datosFichaPersona size_type_font">
                    <tr>
                      <td colSpan={8}>Sin datos del Estado de Cuenta</td>
                    </tr>
                  </tbody>
                </>
              }
            >
              <tbody>
                {props.data?.map((x, index) => (
                  <tr key={index}>
                    <td>{moment(x.fechaCreacion).format("DD[/]MM[/]YYYY")}</td>
                    <td>{x.tipoTransaccionId}</td>
                    <td>{x.concepto}</td>
                    <td>{Currency(parseFloat(x.cargo))}</td>
                    <td>{Currency(parseFloat(x.abono))}</td>
                    <td>{Currency(parseFloat(x.saldoActual))}</td>
                  </tr>
                ))}
              </tbody>
            </ListadoGenerico>
          </table>
        </div>
      </div>
    </>
  );
};

interface tableEstadoCuentaInterface {
  data: any[];
  handleCloseModal?(): void;
  nit?: string;
  tipoPrestamo?: string;
  nombrePrestamo?: string;
}
