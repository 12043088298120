import { Table, Pagination } from "rsuite";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faClipboard, faPlay } from "@fortawesome/free-solid-svg-icons";
import { useState } from "react";
const { Column, HeaderCell, Cell } = Table;

export const TableHome = (props: tableHomePropsInterface) => {
  let styleVaraibles = { background: "var(--naranja)", color: "var(--blanco)" };

  const [limit, setLimit] = useState(10);
  const [page, setPage] = useState(1);

  const handleChangeLimit = (dataKey: any) => {
    setPage(1);
    setLimit(dataKey);
  };

  const data = props.data.filter((v, i) => {
    const start = limit * (page - 1);
    const end = start + limit;
    return i >= start && i < end;
  });

  return (
    <div>
      <Table
        // height={400}
        autoHeight
        data={data}
        className="mx-auto tabla-home font-16"
        rowClassName={"row-table"}
        cellBordered
        loading={props.loading}
        onRowClick={props.onClick}
        // onRowClick={(rowData: any) => {
        //   history.push(`/prestamo/${rowData.credito}`);
        // }}
      >
        <Column flexGrow={1} align="center" fixed minWidth={90}>
          <HeaderCell
            className="font-16 font-weight-bold"
            style={styleVaraibles}
          >
            # Crédito
          </HeaderCell>
          <Cell dataKey="credito" />
        </Column>
        <Column flexGrow={1} minWidth={100}>
          <HeaderCell
            className="font-16 font-weight-bold"
            style={styleVaraibles}
          >
            No. Referencia
          </HeaderCell>
          <Cell dataKey="referenciaMigracion" />
        </Column>
        <Column flexGrow={1} minWidth={100}>
          <HeaderCell
            className="font-16 font-weight-bold"
            style={styleVaraibles}
          >
            Estatus
          </HeaderCell>
          <Cell dataKey="estatus" />
        </Column>
        <Column flexGrow={1} minWidth={100}>
          <HeaderCell
            className="font-16 font-weight-bold"
            style={styleVaraibles}
          >
            DPI
          </HeaderCell>
          <Cell dataKey="dpi" />
        </Column>
        <Column flexGrow={2} minWidth={100}>
          <HeaderCell
            className="font-16 font-weight-bold"
            style={styleVaraibles}
          >
            Nombre
          </HeaderCell>
          <Cell dataKey="nombre" />
        </Column>
        <Column flexGrow={1} minWidth={100}>
          <HeaderCell
            className="font-16 font-weight-bold"
            style={styleVaraibles}
          >
            Saldo Inicial
          </HeaderCell>
          <Cell dataKey="saldoInicial" />
        </Column>
        <Column flexGrow={1} minWidth={100}>
          <HeaderCell
            className="font-16 font-weight-bold"
            style={styleVaraibles}
          >
            Saldo Actual
          </HeaderCell>
          <Cell dataKey="saldoActual" />
        </Column>
        <Column flexGrow={1} minWidth={100}>
          <HeaderCell
            className="font-16 font-weight-bold"
            style={styleVaraibles}
          >
            Asesor
          </HeaderCell>
          <Cell dataKey="asesor"></Cell>
        </Column>
        <Column flexGrow={1} minWidth={100}>
          <HeaderCell
            className="font-16 font-weight-bold"
            style={styleVaraibles}
          >
            {" "}
          </HeaderCell>
          <Cell>
            {(rowData) => (
              <span>
                {rowData.estatus === "Activo" || rowData.estatus === "Planillero X" ? (
                  <>
                    {" "}
                    <FontAwesomeIcon
                      className="mouse-pointer orange-icon"
                      icon={faClipboard}
                      // onClick={() => alert(`id:${rowData.credito}`)}
                    ></FontAwesomeIcon>
                  </>
                ) : (
                  <>
                    {" "}
                    <FontAwesomeIcon
                      className="mouse-pointer orange-icon"
                      icon={faPlay}
                      // onClick={() => alert(`id:${rowData.credito}`)}
                    ></FontAwesomeIcon>
                  </>
                )}
              </span>
            )}
          </Cell>
        </Column>
      </Table>
      <div style={{ padding: 20 }}>
        <Pagination
          prev
          next
          first
          last
          ellipsis
          boundaryLinks
          maxButtons={5}
          size="xs"
          layout={["total", "-", "limit", "|", "pager", "skip"]}
          total={props.data.length}
          limitOptions={[10, 30, 50]}
          limit={limit}
          activePage={page}
          onChangePage={setPage}
          onChangeLimit={handleChangeLimit}
        />
      </div>
    </div>
  );
};

interface tableHomePropsInterface {
  data: any[];
  loading: boolean;
  onClick(valores: any): void;
}
