import { faSearch } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Form, Formik } from "formik";

export const FormInputSearch = (props: FormInputSearchProps) => {
  return (
    <Formik initialValues={{ name: "" }} onSubmit={props.onSubmit}>
      {(formikProps) => (
        <Form>
          <input
            type="text"
            name="name"
            className="form-control inputSearch"
            onChange={(e) => {
              formikProps.values["name"] = e.target.value;
            }}
          />
          <FontAwesomeIcon
            icon={faSearch}
            className="iconoSearch"
          ></FontAwesomeIcon>
        </Form>
      )}
    </Formik>
  );
};

interface FormInputSearchProps {
  onSubmit: any;
}
