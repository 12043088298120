import React, { useRef, useState } from "react";
import { Currency } from "../Utils/ConvertValueToLocalValue";
import ListadoGenerico from "../Utils/ListadoGenerico";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faClose, faMoneyCheck } from "@fortawesome/free-solid-svg-icons";
import moment from "moment";

export const TableSaldoPagoAplicado = (props: tablePlanPagoInterface) => {
  const tableRef = useRef(null);

  return (
    <>
      <div className="container-fluid">
        <div className="row">
          <div className="col-12 justify-content-between d-flex">
            <h3>Saldo Plan de Pago </h3>
            <FontAwesomeIcon
              className="mouse-pointer orange-icon ml-4"
              icon={faClose}
              onClick={() => props.handleCloseModal!()}
              style={{ height: "24px" }}
            ></FontAwesomeIcon>
          </div>
        </div>
        <hr />
        <div className="table-responsive mt-3 container_head_sticky mb-4">
          <table
            className="table table-bordered text-center"
            rules="all"
            ref={tableRef}
          >
            <thead className="header_sticky">
              <tr>
                <th scope="col">MES</th>
                <th scope="col">SALDO CAPITAL</th>
                <th scope="col">SALDO INTERESES</th>
                <th scope="col">SALDO IVA INTERESES</th>
                <th scope="col">SALDO MORA</th>
                <th scope="col">SALDO IVA MORA</th>
                <th scope="col">SALDO CUOTA</th>
                <th scope="col">FECHA PAGO</th>
                <th scope="col">APLICADO</th>
                {/* <th scope="col">APLICAR AJUSTE</th> */}
              </tr>
            </thead>
            <ListadoGenerico
              listado={props.data}
              cargandoUI={
                <>
                  <tbody className="size_type_font tabla_directorio_body">
                    <tr>
                      <td colSpan={8}>Esperando respuesta.</td>
                    </tr>
                  </tbody>
                </>
              }
              listadoVacioUI={
                <>
                  <tbody className="datosFichaPersona size_type_font">
                    <tr>
                      <td colSpan={8}>Sin datos de plan de pago</td>
                    </tr>
                  </tbody>
                </>
              }
            >
              <tbody>
                {props.data?.map((x, index) => (
                  <tr key={index}>
                    <td>{x.periodo}</td>
                    <td>{Currency(parseFloat(x.saldoCapital))}</td>
                    <td>{Currency(parseFloat(x.saldoIntereses))}</td>
                    <td>{Currency(parseFloat(x.saldoIvaIntereses))}</td>
                    <td>{Currency(parseFloat(x.saldoMora))}</td>
                    <td>{Currency(parseFloat(x.saldoIvaMora))}</td>
                    <td>{Currency(parseFloat(x.saldoTotal))}</td>
                    <td>{moment(x.fechaPago).format("DD[/]MM[/]YYYY")}</td>
                    <td>
                      {x.aplicado && <p className="orange-icon">&#10004;</p>}
                    </td>
                    {/* <td>
                      {new Date(x.fechaPago) <= new Date() && (
                        <FontAwesomeIcon
                          icon={faMoneyCheck}
                          style={{ cursor: "pointer" }}
                          className="pl-2 pr-2 orange-icon  font-24"
                          onClick={() => props.handleOpenModalAjuste!(x)}
                        />
                      )}
                    </td> */}
                  </tr>
                ))}
              </tbody>
            </ListadoGenerico>
          </table>
        </div>
      </div>
    </>
  );
};

interface tablePlanPagoInterface {
  data: any[];
  handleCloseModal?(): void;
  handleOpenModalAjuste?(valores: any): void;
  nombre?: string;
  infoPerson: any;
  openModalAjuste?: boolean;
}
