export function OnlyLetters(e: any) {
  let key = e.keyCode || e.which;
  if (
    !(
      (key >= 65 && key <= 90) ||
      (key >= 97 && key <= 122) ||
      key === 241 ||
      key === 209 ||
      key === 32 ||
      key === 225 ||
      key === 233 ||
      key === 237 ||
      key === 243 ||
      key === 250 ||
      key === 237
    )
  ) {
    e.preventDefault();
  }
}

export function OnlyNumber(e: any) {
  let key = e.keyCode || e.which;
  if (!(key >= 48 && key <= 57)) {
    e.preventDefault();
  }
}

export function OnlyNumberFloat(e: any) {
  let key = e.keyCode || e.which;
  if (!((key >= 48 && key <= 57) || key === 46)) {
    e.preventDefault();
  }
}

export function ValidateNit(nit: string) {
  let re = /^[0-9]+([0-9kK])?$/;
  if (!re.exec(nit)) {
    return 0;
  } else return 1;
}

export function ValidateEmail(email: string) {
  let re = /^([a-zA-Z0-9_\.\-])+\@(([a-zA-Z0-9\-])+\.)+([a-zA-Z0-9]{2,4})+$/;
  if (!re.exec(email)) {
    return 0;
  } else return 1;
}
