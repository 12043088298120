import React, { useEffect, useState } from "react";
import Navbar from "../../Components/Navbar";
import { FormPersonalData } from "./FormPersonalData";
import * as Yup from "yup";
import { PrestamoDetalle } from "./PrestamoDetalle";
import ModalFullScreenForm from "../../Components/ModalFullScreen";
import { FormRegistroPago } from "../Pagos/FormRegistroPago";
import { TableConsultaBitacora } from "../../Components/TableConsultaBitacora";
import { useHistory, useParams } from "react-router-dom";
import axios, { AxiosResponse } from "axios";
import { persona } from "../../Interfaces/persona.model";
import { urlLending } from "../../Utils/endpoints";
import Swal from "sweetalert2";
import { TableEstadoCuenta } from "../../Components/TableEstadoCuenta";
import Button from "../../Utils/Button";
import { TableConsultaEstadoCuenta } from "../../Components/TableConsultaEstadoCuenta";
import { TablePlanPagoAplicado } from "../../Components/TablePlanPagoAplicado";
import { TableSaldoPagoAplicado } from "../../Components/TablaSaldoPlanPago";
import { FormAjustePago } from "../Pagos/FormAjustePago";
import {
  auxResponseDataLendingPerson,
  auxResponseModeloPago,
  auxResponseModeloPrestamoDetalle,
  auxResponseSaldosPrestamo,
  createAjustePagoPayload,
} from "../../Utils/CreateObjects";
import {
  getHistorialPagoPetition,
  getPetition,
  getPlanPagoPetition,
  postPagoModal,
  postPetition,
} from "../../Utils/ServicesPetitions";
import moment from "moment";

export const PrestamoDetailActivated = () => {
  const [loading, setLoading] = useState(true);
  const [loading2, setLoading2] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [openModalBitacora, setOpenModalBitacora] = useState(false);
  const [openModalPlanPago, setOpenModalPlanPago] = useState(false);
  const [openModalSaldoPlanPago, setOpenModalSaldPlanPago] = useState(false);
  const [openModalAjustePago, setOpenModalAjustePago] = useState(false);
  const [openModalEstadoCuenta, setOpenModalEstadoCuenta] = useState(false);
  const { creditId }: any = useParams();
  const [datosPersona, setdatosPersona] = useState<persona>();
  const [datosDetallePrestamo, setDatosDetallePrestamo] = useState({});
  const [dataPlanPago, setDataPlanPago] = useState([]);
  const [dataSaldoPlanPago, setDataSaldoPlanPago] = useState([]);
  const [dataEstadoCuenta, setEstadoCuenta] = useState([]);
  const [infoPerson, setInfoPerson] = useState<any>();
  const history = useHistory();
  const [tipoPrestamoNombre, setTipoPrestamoNombre] = useState("");
  const [nit, setNit] = useState("");
  const [nombre, setNombre] = useState("");
  const [referenciaPrestamo, setReferenciaPrestamo] = useState("");
  const [historialPagoData, setHistorialPagoData] = useState([]);
  const [diasMora, setDiasMora] = useState(0);

  let planPagoId = 0;
  const [rowData, setRowData] = useState(planPagoId);

  const schema = Yup.object({
    phoneNumber: Yup.string(),
    name: Yup.string(),
    dpi: Yup.string(),
    estado: Yup.string(),
    address: Yup.string(),
    estatus: Yup.string(),
    gestor: Yup.string(),
    nit: Yup.string(),
    empresaPlanilla: Yup.string(),
  });

  const modeloPrestamoDetalle = {
    plazo: "0",
    interes: "0",
    mora: "0",
    gastosAdministracion: "0",
    iva: "12",
    tipoPlan: "1",
    fecha: '',
    fechaPago: '',
  };
  const [modeloPrestamoDetalleData, setModeloPrestamoDetalleData] = useState(
    modeloPrestamoDetalle
  );

  let modeloAjustePago = {
    montoTotal: "",
    fecha: new Date(),
    banco: -1,
    observaciones: "",
  };

  const schemaAjustePago = Yup.object({
    montoTotal: Yup.string().required("Campo Requerido"),
    fecha: Yup.date().required("Campo Requerido"),
    banco: Yup.number().required("Campo Requerido"),
    observaciones: Yup.string(),
  });

  const schemaPrestamo = Yup.object({
    plazo: Yup.number(),
    fecha: Yup.string(),
    interes: Yup.number(),
    mora: Yup.number(),
    gastosAdministracion: Yup.number(),
    iva: Yup.number(),
    tipoPlan: Yup.string(),
  });

  const schemaPago = Yup.object({
    montoTotal: Yup.number(),
    fecha: Yup.date(),
    formaPago: Yup.number(),
    banco: Yup.number(),
    noBoleta: Yup.number(),
    observaciones: Yup.string(),
  });

  let modeloPago = {
    montoTotal: "",
    fecha: moment(new Date()).format(("YYYY-MM-DD")),
    formaPago: -1,
    banco: -1,
    noBoleta: "",
    observaciones: "",
    totalPagar: "",
    pagoCapital: "",
    pagoCapitalAnticipado: "",
    pagoInteres: "",
    pagoIva: "",
    pagoMora: "",
    pagoIvaMora: "",
    totalGastos: "",
    saldoTotalPagar: "",
    fechaProximoPago: "",
    diasMoraInteres: "",
    estadoCreditoFecha: "",
    cuotasAdelantadasEstePago: "",
    cuotasVencidasCubiertasPago: "",
    aux: "",
  };
  const [modeloPagoFormulario, setModeloPagoFormulario] = useState(modeloPago);

  useEffect(() => {
    getDataLending();

    return () => {
      localStorage.removeItem("monto");
    };
  }, []);

  const handleOpenModal = () => {
    setOpenModal(true);
  };

  const handleCloseModal = () => {
    setOpenModal(false);
    setModeloPagoFormulario({
      montoTotal: "",
      fecha: moment(new Date()).format(("YYYY-MM-DD")),
      formaPago: 2,
      banco: -1,
      noBoleta: "",
      observaciones: "",
      totalPagar: "",
      pagoCapital: "",
      pagoCapitalAnticipado: "",
      pagoInteres: "",
      pagoIva: "",
      pagoMora: "",
      pagoIvaMora: "",
      totalGastos: "",
      saldoTotalPagar: "",
      fechaProximoPago: "",
      diasMoraInteres: "",
      estadoCreditoFecha: "",
      cuotasAdelantadasEstePago: "",
      cuotasVencidasCubiertasPago: "",
      aux: "",
    });

    setClaseBoton("d-block");
    localStorage.removeItem("monto");
    modeloPago = {
      montoTotal: "",
      fecha: moment(new Date()).format(("YYYY-MM-DD")),
      formaPago: 2,
      banco: -1,
      noBoleta: "",
      observaciones: "",
      totalPagar: "",
      pagoCapital: "",
      pagoCapitalAnticipado: "",
      pagoInteres: "",
      pagoIva: "",
      pagoMora: "",
      pagoIvaMora: "",
      totalGastos: "",
      saldoTotalPagar: "",
      fechaProximoPago: "",
      diasMoraInteres: "",
      estadoCreditoFecha: "",
      cuotasAdelantadasEstePago: "",
      cuotasVencidasCubiertasPago: "",
      aux: "",
    };
  };

  const handleOpenModalBitacora = () => {
    setOpenModalBitacora(true);
  };

  const handleCloseModalBitacora = () => {
    setOpenModalBitacora(false);
  };
  const handleOpenModalPlanPago = () => {
    setOpenModalPlanPago(true);
  };

  const handleCloseModalPlanPago = () => {
    setOpenModalPlanPago(false);
  };

  const handleOpenModalSaldoPlanPago = () => {
    setOpenModalSaldPlanPago(true);
  };

  const handleCloseModalSaldoPlanPago = () => {
    setOpenModalSaldPlanPago(false);
  };

  const handleOpenModalAjustePago = (valores: any) => {
    handleCloseModalSaldoPlanPago();
    planPagoId = valores.planPagoId;
    setRowData(planPagoId);
    setOpenModalAjustePago(true);
  };

  const handleCloseModalAjustePago = () => {
    setOpenModalAjustePago(false);

    modeloAjustePago = {
      montoTotal: "",
      fecha: new Date(),
      banco: -1,
      observaciones: "",
    };
  };

  const handleOpenModalEstadoCuenta = () => {
    setOpenModalEstadoCuenta(true);
  };

  const handleCloseModalEstadoCuenta = () => {
    setOpenModalEstadoCuenta(false);
  };
  
  const onSubmitModal = async (valores: any) => {
    setLoading2(true);
    const data = await postPagoModal(valores, creditId, fechaPagoAux);
    setLoading2(false);
    
    switch (data) {
      case 100:
        Swal.fire({
          title: `Error`,
          text: "El detalle de pago no existe",
          icon: "warning",
        });
        break;
        case 200:
          Swal.fire({
            title: `Error`,
            text: "El monto aplicar no puede estar vacío",
            icon: "warning",
          });
          break;
          case 300:
            Swal.fire({
          title: `Error`,
          text: "Debe seleccionar una forma de pago",
          icon: "warning",
        });
        break;
        case 400:
          Swal.fire({
            title: `Error`,
            text: "Debe de ingresar el número de documento",
            icon: "warning",
          });
          break;
          case 500:
            Swal.fire({
              title: `Error`,
              text: "No se pudo realizar el pago.",
              icon: "warning",
            });
            break;
            case 600:
              Swal.fire({
                title:'Error',
                text:'No se pudo realizar el pago. La fecha de pago no puede ser mayor a la fecha actual.',
            icon:'warning'
          })
          break;
          case 700:
            Swal.fire({
              title:'Error',
              text:'El monto a aplicar no puede ser mayor al Saldo crédito al día de hoy',
              icon:'warning'
          })
          break;
          case -1:
            Swal.fire({
              title: `Error`,
              text: "El detalle de pago no existe",
              icon: "warning",
            });
            break;
            
            default:
              Swal.fire({
                title: `Guardado`,
                text: `Pago Realizado.`,
                icon: "success",
              });
              
              localStorage.removeItem("monto");
              window.location.reload();
              break;
            }
          };
          
          const onSubmit = (valores: any) => {
            history.replace("/home");
  };

  const getDataLending = async () => {
    await axios
      .get(`${urlLending}/${creditId}`)
      .then((response: AxiosResponse) => {
        let auxPersona = auxResponseDataLendingPerson(response);
        setdatosPersona(auxPersona);

        setNombre(auxPersona.name);
        setNit(auxPersona.nit);
        setInfoPerson({
          id: creditId,
          name: auxPersona.name,
        });

        let modeloPrestamoDetalleAux =
          auxResponseModeloPrestamoDetalle(response);
        setDiasMora(response.data.lendingP[0].diasMora);
        setTipoPrestamoNombre(response.data.lendingP[0].tipoPrestamo);
        setReferenciaPrestamo(response.data.lendingP[0].prestamoReferencia);
        setModeloPrestamoDetalleData(modeloPrestamoDetalleAux);
      });

    const data = await getPetition(`${urlLending}/saldos/${creditId}`);
    setValorTemporal(data.totalSaldo);
    let modeloPrestamo = auxResponseSaldosPrestamo(data);
    setDatosDetallePrestamo(modeloPrestamo);

    getHistorialPago();
    getLendinSaldoPlanPago();
    getLendinEstadoCuenta();
    getLendinPlanPago();
    setLoading(false);
  };

  const getHistorialPago = async () => {
    setHistorialPagoData([]);
    const aux = await getHistorialPagoPetition(creditId);
    if (aux === -1) return setHistorialPagoData([]);
    setHistorialPagoData(aux);
  };

  const getLendinPlanPago = async () => {
    const auxPlanpago = await getPlanPagoPetition(creditId);
    if (auxPlanpago === -1) return setDataPlanPago([]);
    setDataPlanPago(auxPlanpago);
  };

  const getLendinEstadoCuenta = async () => {
    const data = await getPetition(`${urlLending}/estado_cuenta/${creditId}`);
    if (data === -1) return setEstadoCuenta([]);
    setEstadoCuenta(data);
  };

  const getLendinSaldoPlanPago = async () => {
    const data = await getPetition(
      `${urlLending}/saldos_plan_pago/${creditId}`
    );
    if (data === -1) return setDataSaldoPlanPago([]);
    setDataSaldoPlanPago(data);
  };

  // Temporal
  const [valorTemporal, setValorTemporal] = useState("");
  const changeValorTemporal = (event: any) => {
    setValorTemporal(event.target.value);
    // console.log(valorTemporal);
  };

  // Click Enter
  let montoTotalPago = "";
  let fechaPago = '';
  const [fechaPagoAux, setFechaPagoAux] = useState('');
  const [montoPagoConstante, setMontoPagoConstante] = useState('')
  const enterClick = (e: any) => {
    montoTotalPago = e.target.value;
    setMontoPagoConstante(e.target.value)
    if (e.charCode === 13 || e.which === 13) {
      searchDataEnter();
      e.preventDefault();
    }
  };

  const [first, setFirst] = useState(-1)
  const [second, setSeCond] = useState(-1)
  const changeFirst = (e:any) => {}
  const changeSecond = (e:any) => {}
  const searchDataEnter = async () => {

    if (new Date(modeloPagoFormulario.fecha) > new Date()) {
      return  Swal.fire({
        title:'Error',
        text:'No se pudo distribuir el pago. La fecha de pago no puede ser mayor a la fecha actual.',
        icon:'warning'
      })
    }
    fechaPago = modeloPagoFormulario.fecha;
    setFechaPagoAux(modeloPagoFormulario.fecha);
    const data = await getPetition(
      `${urlLending}/distribuye_pago/${creditId}?montoPago=${montoPagoConstante}&fechaPago=${moment(
        modeloPagoFormulario.fecha
      ).format()}&aplicaMora=true`
    );
    if (data === -1)
      return Swal.fire({
        title: `Error`,
        text: `No se pudo obtener el detalle de pago.`,
        icon: "warning",
      });
    let modeloPago = auxResponseModeloPago(data, modeloPagoFormulario.fecha, modeloPagoFormulario);
    localStorage.setItem("monto", montoPagoConstante);
    setModeloPagoFormulario(modeloPago);
  };

  //mismo metodo solo que para cancelar la mora
  const [claseBoton, setClaseBoton] = useState("d-block");
  const cancelMora = async () => {
    const data = await getPetition(
      `${urlLending}/distribuye_pago/${creditId}?montoPago=${localStorage.getItem(
        "monto"
      )}&aplicaMora=false`
    );
    if (data === -1)
      return Swal.fire({
        title: `Error`,
        text: `No se pudo obtener el detalle de pago.`,
        icon: "warning",
      });
    let modeloPago = auxResponseModeloPago(data, modeloPagoFormulario.fecha, modeloPagoFormulario);
    setClaseBoton("d-none");
    setModeloPagoFormulario(modeloPago);
  };

  // Escribir total gastos
  const changeValueGastos = (e: any) => {
    let auxGastos = e.target.value;
    if (auxGastos > modeloPagoFormulario.aux) {
      e.target.value = 0;
      modeloPagoFormulario.pagoCapital = modeloPagoFormulario.aux;
      auxGastos = 0;
      modeloPagoFormulario.montoTotal = modeloPagoFormulario.aux;
      return;
    }

    if (e.target.value === "") {
      auxGastos = 0;
      modeloPagoFormulario.montoTotal = modeloPagoFormulario.aux;
    }

    let total = (
      parseFloat(modeloPagoFormulario.aux) - parseFloat(auxGastos)
    ).toFixed(2);
    modeloPago.fecha = modeloPagoFormulario.fecha;
    modeloPago.formaPago = modeloPagoFormulario.formaPago;
    modeloPago.banco = modeloPagoFormulario.banco;
    modeloPago.noBoleta = modeloPagoFormulario.noBoleta;
    modeloPago.observaciones = modeloPagoFormulario.observaciones;
    modeloPago.montoTotal = modeloPagoFormulario.aux;

    modeloPago.totalPagar = modeloPagoFormulario.totalPagar;
    modeloPago.diasMoraInteres = modeloPagoFormulario.diasMoraInteres;
    modeloPago.estadoCreditoFecha = modeloPagoFormulario.estadoCreditoFecha;
    modeloPago.fechaProximoPago = modeloPagoFormulario.fechaProximoPago;
    modeloPago.pagoCapitalAnticipado =
      modeloPagoFormulario.pagoCapitalAnticipado;
    modeloPago.pagoInteres = modeloPagoFormulario.pagoInteres;
    modeloPago.pagoIva = modeloPagoFormulario.pagoIva;
    modeloPago.pagoIvaMora = modeloPagoFormulario.pagoIvaMora;
    modeloPago.pagoMora = modeloPagoFormulario.pagoMora;
    modeloPago.saldoTotalPagar = modeloPagoFormulario.saldoTotalPagar;
    modeloPago.diasMoraInteres = modeloPagoFormulario.diasMoraInteres;
    modeloPago.totalGastos = modeloPagoFormulario.totalGastos;
    modeloPago.aux = modeloPagoFormulario.aux;
    modeloPago.pagoCapital = total.toString();
    modeloPago.totalGastos = auxGastos;

    setModeloPagoFormulario(modeloPago);
  };

  // Ajuste pago submit

  const onSubmitAjustePago = async (valores: any) => {
    let payload = createAjustePagoPayload(valores, creditId, rowData);

    if (valores.banco === -1 || valores.banco === "-1") {
      Swal.fire({
        title: `Error`,
        text: "Debe de seleccionar el tipo de ajuste",
        icon: "warning",
      });
    } else {
      const data = await postPetition(`${urlLending}/pago-ajustes`, payload);
      if (data === -1)
        return Swal.fire({
          title: `Error`,
          text: `No se pudo realizar el ajuste.`,
          icon: "warning",
        });
      Swal.fire({
        title: `Guardado`,
        text: `Ajuste Realizado.`,
        icon: "success",
      });

      window.location.reload();
    }
  };
  return (
    <>
      <Navbar show={false}></Navbar>
      {!loading ? (
        <>
          <div className="container-fluid pr-sm-5">
            <FormPersonalData
              modelo={datosPersona}
              onSubmit={onSubmit}
              validationSchema={schema}
              readonly={true}
              consultaBitacora={handleOpenModalBitacora}
              new={true}
              ocultar={true}
              route={false}
              destinoPrestamo={[]}
              estadoCivil={[]}
              gestores={[]}
              tipoPrestamo={[]}
              prestamoTipoNombre={tipoPrestamoNombre}
              referenciaPrestamo={referenciaPrestamo}
            ></FormPersonalData>
          </div>
          <>
            <div className="container-fluid pl-sm-5">
              <PrestamoDetalle
                modelo={datosDetallePrestamo}
                modeloTasas={modeloPrestamoDetalleData}
                readonly={true}
                validationSchema={schemaPrestamo}
                valor={diasMora}
                changeValor={changeValorTemporal}
              ></PrestamoDetalle>
            </div>
          </>
          {/* Historial de Pago */}
          <div className="container-fluid row mt-3 mb-3">
            <h4 className="col-12 col-sm-4 mb-2">Historial de Pago</h4>
            <div className="col-12 col-md-2 mb-2">
              <Button
                className="orange-dark-button"
                onClick={() => handleOpenModalSaldoPlanPago()}
              >
                Saldos Plan de Pago
              </Button>
            </div>
            <div className="col-12 col-md-2 mb-2">
              <Button
                className="orange-dark-button"
                onClick={() => handleOpenModalPlanPago()}
              >
                Ver Plan de Pago
              </Button>
            </div>
            <div className="col-12 col-md-2 mb-2">
              <Button
                className="orange-dark-button"
                onClick={() => handleOpenModalEstadoCuenta()}
              >
                Ver Estado de Cuenta
              </Button>
            </div>
            <div className="col-12 col-md-2 mb-2">
              <Button
                className="orange-dark-button"
                onClick={() => handleOpenModal()}
              >
                Registrar Pago
              </Button>
            </div>
          </div>
          {/* Tablas */}
          <div className="container-fluid pl-4 pr-4 pb-3">
            <TableEstadoCuenta
              data={historialPagoData}
              loading={false}
            ></TableEstadoCuenta>
          </div>
          {loading2 && (
              <div className="loginLoading">
                <div className="row justify-content-center mt-5">
                  <div className="d-flex align-items-center">
                    <strong>Espere un momento por favor...</strong>
                    <div
                      className="spinner-border ml-auto"
                      role="status"
                      aria-hidden="true"
                    ></div>
                  </div>
                </div>
              </div>
            )}
        </>
      ) : (
        <div className="text-center w-100 h-100">
          <div
            className="spinner-border"
            style={{ width: "3rem", height: "3rem" }}
            role="status"
          ></div>
          <span className="d-block color_blue size_type_font">
            Cargando datos del Prestamo.
          </span>
        </div>
      )}
      {/* Modal Registro Pago*/}
      <ModalFullScreenForm
        childrenBody={
          <FormRegistroPago
            handleClose={handleCloseModal}
            modelo={modeloPagoFormulario}
            onSubmit={onSubmitModal}
            keyEnter={enterClick}
            keyEnterGastos={changeValueGastos}
            searchDataEnter={searchDataEnter}
            infoPerson={infoPerson}
            validationSchema={schemaPago}
            cancelMora={cancelMora}
            claseBoton={claseBoton}
            first={changeFirst}
            second={changeSecond}
          ></FormRegistroPago>
        }
        handleClose={handleCloseModal}
        open={openModal}
        size="full"
      ></ModalFullScreenForm>
      {/* Modal Consulta Bitacora */}
      <ModalFullScreenForm
        childrenBody={
          <TableConsultaBitacora
            data={[]}
            loading={false}
            handleCloseModal={handleCloseModalBitacora}
          ></TableConsultaBitacora>
        }
        handleClose={handleCloseModalBitacora}
        open={openModalBitacora}
        size="md"
      ></ModalFullScreenForm>
      {/* Modal Saldo Plan de pago */}
      <ModalFullScreenForm
        childrenBody={
          <TableSaldoPagoAplicado
            data={dataSaldoPlanPago!}
            handleCloseModal={handleCloseModalSaldoPlanPago}
            nombre={nombre}
            infoPerson={infoPerson}
            handleOpenModalAjuste={handleOpenModalAjustePago}
          ></TableSaldoPagoAplicado>
        }
        handleClose={handleCloseModalSaldoPlanPago}
        open={openModalSaldoPlanPago}
        size="full"
      ></ModalFullScreenForm>
      {/* Modal Ajuste Pago*/}
      <ModalFullScreenForm
        childrenBody={
          <FormAjustePago
            infoPerson={infoPerson}
            handleClose={handleCloseModalAjustePago}
            modelo={modeloAjustePago}
            validationSchema={schemaAjustePago}
            onSubmit={onSubmitAjustePago}
          ></FormAjustePago>
        }
        handleClose={handleCloseModalAjustePago}
        open={openModalAjustePago}
        size="lg"
      ></ModalFullScreenForm>
      {/* Modal Plan pago */}
      <ModalFullScreenForm
        childrenBody={
          <TablePlanPagoAplicado
            data={dataPlanPago!}
            handleCloseModal={handleCloseModalPlanPago}
            nombre={nombre}
          ></TablePlanPagoAplicado>
        }
        handleClose={handleCloseModalPlanPago}
        open={openModalPlanPago}
        size="full"
      ></ModalFullScreenForm>
      {/* Modal Estado de Cuenta*/}
      <ModalFullScreenForm
        childrenBody={
          <TableConsultaEstadoCuenta
            data={dataEstadoCuenta!}
            handleCloseModal={handleCloseModalEstadoCuenta}
            nombrePrestamo={nombre}
            tipoPrestamo={tipoPrestamoNombre}
            nit={nit}
          ></TableConsultaEstadoCuenta>
        }
        handleClose={handleCloseModalEstadoCuenta}
        open={openModalEstadoCuenta}
        size="lg"
      ></ModalFullScreenForm>
    </>
  );
};
