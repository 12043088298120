import {
  faCheckCircle,
  faCircleArrowLeft,
  faPrint,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Formik, FormikHelpers, Form } from "formik";
import { useEffect, useState } from "react";
import { useHistory, useLocation, useParams } from "react-router-dom";
import { string } from "yup";
import { useAxiosSelectData } from "../../Hooks/useAxiosSelectData";
import Button from "../../Utils/Button";
import { urlCatalogues } from "../../Utils/endpoints";
import FormGroupSelect from "../../Utils/FormGroupSelect";
import FormGroupSelect2 from "../../Utils/FormGroupSelect2";
import FormGroupText from "../../Utils/FormGroupText";
import FormGroupText2 from "../../Utils/FormGroupText2";
import { OnlyNumber, OnlyNumberFloat } from "../../Utils/Validaciones";

export const FormPersonalData = (props: formPersonalDataInterface) => {
  const history = useHistory();
  const { creditId }: any = useParams();

  const [ruta, setRuta] = useState(false);
  const [rutaPrestamoActivo, setRutaPrestamoActivo] = useState(false);
  const [rutaEditButton, setRutaEditButton] = useState(false);

  const rutaActiva = useLocation();

  useEffect(() => {
    rutaActiva.pathname === "/newPrestamo" ? setRuta(true) : setRuta(false);
    rutaActiva.pathname.split("/")[1] === "prestamoDetail"
      ? setRutaPrestamoActivo(true)
      : setRutaPrestamoActivo(false);
  }, []);

  return (
    <>
      <Formik
        initialValues={props.modelo}
        onSubmit={props.onSubmit}
        validationSchema={props.validationSchema}
      >
        {(formikProps) => (
          <Form>
            <div className="row">
              <div className="col-12 col-md-2 justify-content-center align-items-center d-flex mb-2 mb-sm-none">
                <div className="person-icon">
                  <svg
                    id="Layer_1"
                    data-name="Layer 1"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 122.88 116.02"
                  >
                    <defs>
                      <style></style>
                    </defs>
                    <title>person</title>
                    <path
                      className="cls-1"
                      d="M45.41,69.63c.35-2.91-8.3-14.06-9.88-19.4-3.39-5.38-4.59-13.94-.9-19.63,1.47-2.26.84-4.22.84-7.35,0-31,54.27-31,54.27,0,0,3.92-.89,4.84,1.22,7.93,3.55,5.15,1.73,14.29-1.27,19.07-1.93,5.61-11,16.22-10.37,19.4.57,15.89-34,15.38-33.91,0v0Zm19.78,27.5H67.1A3.13,3.13,0,0,0,70.22,94V89a3.12,3.12,0,0,0-3.12-3.12H55.79A3.13,3.13,0,0,0,52.67,89V94a3.14,3.14,0,0,0,3.12,3.12h1.94L54,116H68.76L65.19,97.13ZM0,116C1.47,97-2.26,97.79,13.65,91.84A122.7,122.7,0,0,0,36,80.54L49.6,116Zm87.54-37.1a91.08,91.08,0,0,0,20.61,10.29C123,94.15,123,94.84,122.86,116H73.45l14.09-37.1Z"
                    />
                  </svg>
                </div>
              </div>
              <div className="col-12 col-md-10">
                <div className="row">
                  <div className="col-12 col-md-6">
                    <div className="row mb-2">
                      <FormGroupText2
                        campo="name"
                        clase="col-10"
                        claseLabelParent="col-2"
                        label="Nombre"
                        type="text"
                        readonly={props.readonly}
                      ></FormGroupText2>
                    </div>
                    <div className="row mb-2">
                      <FormGroupText2
                        campo="dpi"
                        clase="col-4"
                        claseLabelParent="col-2"
                        label="DPI"
                        type="text"
                        readonly={props.readonly}
                      ></FormGroupText2>
                      {!props.route ? (
                        <>
                          <FormGroupText2
                            campo="estado"
                            clase="col-4"
                            claseLabelParent="col-2"
                            label="Estado Civil"
                            type="text"
                            readonly={props.readonly}
                          ></FormGroupText2>
                        </>
                      ) : (
                        <>
                          <div className="col-12 col-sm-6">
                            <FormGroupSelect
                              campo="estado"
                              opciones={props.estadoCivil}
                              label="Estado Civil"
                              className="d-flex"
                              claseInput="form-control"
                              claseLabel="font-16"
                            ></FormGroupSelect>
                          </div>
                        </>
                      )}
                    </div>
                    <div className="row mb-2">
                      <FormGroupText2
                        campo="address"
                        clase="col-10"
                        claseLabelParent="col-2"
                        label="Dirección"
                        type="text"
                        readonly={props.readonly}
                      ></FormGroupText2>
                    </div>
                    <div className="row mb-2">
                      <FormGroupText2
                        campo="phoneNumber"
                        clase="col-10 col-sm-4"
                        claseLabelParent="col-2"
                        label="Celular"
                        readonly={props.readonly}
                        type="text"
                        changeValue={OnlyNumber}
                      ></FormGroupText2>
                    </div>
                  </div>
                  <div className="col-12 col-md-3 d-flex flex-column justify-content-centermb-2 mb-sm-none">
                    {!props.route && (
                      <div className="row mb-2">
                        <FormGroupText2
                          campo="estatus"
                          clase="col-9"
                          claseLabelParent="col-3"
                          label="Estatus"
                          type="text"
                          readonly={props.readonly}
                        ></FormGroupText2>
                      </div>
                    )}
                    <div className="row mb-2">
                      {props.route ? (
                        <>
                          <div className="col-12 col-sm-12">
                            <FormGroupSelect2
                              campo="gestor"
                              opciones={props.gestores}
                              label="Gestor"
                              className="row justify-content-center"
                              claseInput="form-control col-9"
                              claseLabel="font-16 col-3"
                            ></FormGroupSelect2>
                          </div>
                        </>
                      ) : (
                        <>
                          <FormGroupText2
                            campo="gestor"
                            clase="col-9"
                            claseLabelParent="col-3"
                            label="Gestor"
                            type="text"
                            readonly={props.readonly}
                          ></FormGroupText2>
                        </>
                      )}
                    </div>
                    <div className="row mb-2">
                      <FormGroupText2
                        campo="nit"
                        clase="col-9"
                        claseLabelParent="col-3"
                        label="Nit"
                        type="text"
                        readonly={props.readonly}
                      ></FormGroupText2>
                    </div>
                    <div className="row mb-2">
                      {props.route ? (
                        <>
                          <div className="col-12 col-sm-12">
                            <FormGroupSelect2
                              campo="empresaPlanilla"
                              // opciones={empresa}
                              opciones={props.empresaPlanilla}
                              label="Empresa Planilla"
                              className="row justify-content-center"
                              claseInput="form-control col-9"
                              claseLabel="font-16 col-3"
                            ></FormGroupSelect2>
                          </div>
                        </>
                      ) : (
                        <>
                          <FormGroupText2
                            campo="empresaPlanilla"
                            clase="col-9"
                            claseLabelParent="col-3"
                            label="Empresa Planilla"
                            type="text"
                            readonly={props.readonly}
                          ></FormGroupText2>
                        </>
                      )}
                    </div>
                  </div>
                  <div className="col-12 col-md-3 d-flex justify-content-center align-items-start">
                    {rutaPrestamoActivo && (
                      <div className="row ">
                        <Button
                          className="orange-dark-button col-12 mb-2"
                          onClick={() =>
                            history.push(`/EditPerson/${creditId}`)
                          }
                        >
                          Editar Ficha
                        </Button>
                        {/* <Button
                          className="orange-dark-button col-6 mb-2"
                          onClick={() => props.consultaBitacora!()}
                        >
                          Ver Bitacora
                        </Button>
                        <Button className="orange-dark-button col-6 mb-2">
                          Cambiar de Estatus
                        </Button>
                        <Button className="orange-dark-button col-6 mb-2">
                          Agregar Bitacora
                        </Button> */}
                      </div>
                    )}
                  </div>
                </div>
              </div>
              {/*  */}
              <hr className="hr-2 col-12" />

              <div className="d-flex justify-content-between w-100">
                {ruta ? (
                  <h4 className="pl-5">Nuevo Préstamo</h4>
                ) : (
                  <>
                    <span className="d-block d-md-flex align-items-center">
                      <h4 className="pl-md-5 pr-md-5  mr-md-5">
                        Préstamo #{creditId}
                      </h4>
                      {props.referenciaPrestamo && (
                        <h5 className="pl-md-5 pr-md-5 mr-md-5">
                          Referencia No. - {props.referenciaPrestamo}
                        </h5>
                      )}
                      {props.prestamoTipoNombre && (
                        <h5 className="pl-md-5 ml-md-5  ">
                          {props.prestamoTipoNombre}
                        </h5>
                      )}
                    </span>
                  </>
                )}

                {rutaPrestamoActivo === false && (
                  <Button type="submit" className="button-transparent">
                    <FontAwesomeIcon
                      className="mouse-pointer orange-icon ml-4"
                      icon={faCheckCircle}
                      style={{ height: "2rem" }}
                    ></FontAwesomeIcon>
                  </Button>
                )}
              </div>
              <hr className="hr-2 col-12" />
              {props.new && !props.ocultar && (
                <>
                  <div className="ml-sm-5 mb-4 col-12 col-md-6">
                    <div className="row">
                      <div className="col-12 col-sm-6">
                        <FormGroupSelect
                          campo="destinoPrestamoId"
                          opciones={props.destinoPrestamo}
                          label="Destino Prestamo"
                          claseInput="form-control"
                          claseLabel="font-weight-bold font-16"
                        ></FormGroupSelect>
                      </div>
                      <div className="col-12 col-sm-6">
                        <FormGroupSelect
                          campo="tipoPrestamoId"
                          opciones={props.tipoPrestamo}
                          label="Tipo Prestamo"
                          claseInput="form-control"
                          claseLabel="font-weight-bold font-16"
                        ></FormGroupSelect>
                      </div>
                    </div>
                    <div className="row">
                      <FormGroupText
                        campo="montoCapital"
                        clase="col-12 col-sm-6"
                        claseLabel="font-weight-bold font-16"
                        label="Monto Préstamo"
                        readonly={props.readonly}
                        type="text"
                        changeValue={OnlyNumberFloat}
                      ></FormGroupText>
                    </div>
                  </div>
                  {/* <div className="col-2 mt-4 ml-5">
                    <Button className="orange-dark-button">Plan de Pago</Button>
                  </div> */}
                </>
              )}
              {/*  */}
            </div>
          </Form>
        )}
      </Formik>
    </>
  );
};

interface formPersonalDataInterface {
  modelo: any;
  onSubmit(valores: any, acciones: FormikHelpers<any>): void;
  error?: string[];
  validationSchema: any;
  consultaBitacora?(): void;
  readonly: boolean;
  new: boolean;
  ocultar?: boolean;
  route: boolean;
  estadoCivil?: any[];
  destinoPrestamo?: any[];
  gestores?: any[];
  tipoPrestamo?: any[];
  empresaPlanilla?: any[];
  prestamoTipoNombre?: string;
  referenciaPrestamo?: string;
}
