import {
  faArrowRightArrowLeft,
  faBan,
  faCheckCircle,
  faClose,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Form, Formik, FormikHelpers } from "formik";
import React, { useEffect } from "react";
import { useAxiosSelectData } from "../../Hooks/useAxiosSelectData";
import { urlCatalogues } from "../../Utils/endpoints";
import FormGroupFechas from "../../Utils/FormGroupFechas";
import FormGroupSelect from "../../Utils/FormGroupSelect";
import FormGroupText2 from "../../Utils/FormGroupText2";
import FormGroupText3 from "../../Utils/FormGroupText3";
import { TagTwin } from "../../Utils/TagTwin";
import { Tooltip, Whisper } from "rsuite";
import Button from "../../Utils/Button";
import { OnlyNumber, OnlyNumberFloat } from "../../Utils/Validaciones";
import FormGroupText4 from "../../Utils/FormGroupText4";
import FormGroupSelectAnidado from "../../Utils/FormGroupSelectAnidado";

const tooltip = <Tooltip>Distribuir Pago</Tooltip>;
const tooltip2 = <Tooltip>Exonerar Mora</Tooltip>;

export const FormRegistroPago = (props: formRegistroPagoPropsInterface) => {
  const { data: bancoData, loading: bancoReady } = useAxiosSelectData(
    `${urlCatalogues}/bancos`
  );
  const { data: cajaData, loading: cajaReady } = useAxiosSelectData(
    `${urlCatalogues}/cajas`
  );
  const { data: formaPagoData, loading: formaPagoReady } = useAxiosSelectData(
    `${urlCatalogues}/forma_pago`
  );

  useEffect(() => {}, [props.modelo]);

  return (
    <>
      <Formik
        initialValues={props.modelo}
        onSubmit={props.onSubmit}
        validationSchema={props.validationSchema}
        enableReinitialize
        
      >
        {(formikProps) => (
          <div className="container-fluid">
            {!bancoReady && !cajaReady && !formaPagoReady ? (
              <>
                <Form>
                  <div className="row">
                    <div className="col-10">
                      <TagTwin
                        col="col-12"
                        descriptionClass=" font-16"
                        label="Número de Crédito:"
                        labelResult={props.infoPerson.id}
                        titleClass="mr-4 font-weight-bold font-16"
                      ></TagTwin>
                      <TagTwin
                        col="col-12"
                        descriptionClass="font-16"
                        label="Nombre Cliente:"
                        labelResult={props.infoPerson.name}
                        titleClass="mr-4 font-weight-bold font-16"
                      ></TagTwin>
                      <TagTwin
                        col="col-12"
                        descriptionClass="font-16"
                        label="Caja: "
                        labelResult="General"
                        titleClass="mr-4 font-weight-bold font-16"
                      ></TagTwin>
                    </div>
                    <div className="col-2 justify-content-end align-items-center d-flex">
                      <Button type="submit" className="button-transparent">
                        <FontAwesomeIcon
                          className="mouse-pointer orange-icon ml-4"
                          icon={faCheckCircle}
                          style={{ height: "24px" }}
                        ></FontAwesomeIcon>
                      </Button>
                      <FontAwesomeIcon
                        className="mouse-pointer orange-icon ml-4"
                        icon={faClose}
                        onClick={() => props.handleClose()}
                        style={{ height: "24px" }}
                      ></FontAwesomeIcon>
                    </div>
                  </div>
                  <hr />
                  {/*  */}
                  <div className="row">
                    <div className="col-12 ">
                      <div className="row">
                        <div className="col-6">
                          <FormGroupText3
                            campo="montoTotal"
                            clase="col-12"
                            claseLabelParent="col-12 font-weight-bold font-16"
                            label="Monto Aplicar"
                            keyEnter={props.keyEnter}
                            changeValue={OnlyNumberFloat}
                          ></FormGroupText3>
                          <Whisper
                            placement="top"
                            controlId="control-id-hover"
                            trigger="hover"
                            speaker={tooltip}
                          >
                            <FontAwesomeIcon
                              icon={faArrowRightArrowLeft}
                              className="ml-2 mr-2 white-icon"
                              style={{
                                float: "right",
                                position: "relative",
                                top: "-30px",
                                right: "-10px",
                                cursor: "pointer",
                              }}
                              onClick={props.searchDataEnter}
                            />
                          </Whisper>
                        </div>
                        <div className="col-6">
                          <FormGroupFechas
                            campo="fecha"
                            clase=""
                            claseLabel="font-weight-bold font-16"
                            label="Fecha Real Pago"
                          ></FormGroupFechas>
                        </div>
                      </div>
                      <div className="row">
                        {/* <div className="col-12 col-md-3">
                          <FormGroupSelect
                            campo="caja"
                            opciones={cajaData}
                            label="Caja"
                            claseInput="form-control"
                            claseLabel="font-16"
                          ></FormGroupSelect>
                        </div> */}
                        <div className="col-12 col-md-3">
                          {/* <FormGroupSelect
                            campo="formaPago"
                            opciones={formaPagoData}
                            label="Forma de Pago"
                            claseInput="form-control"
                            claseLabel="font-16"
                          ></FormGroupSelect> */}
                            <FormGroupSelectAnidado
                              classDivPadre={"ml-3 mr-3"}
                              classLabel={"font-16 font-weight-bold"}
                              classInput={"form-control"}
                              campo={"formaPago"}
                              labelText={"Forma de Pago"}
                              onChange={(e: any) => props.second!(e)}
                              information={formaPagoData}
                            ></FormGroupSelectAnidado>
                        </div>
                        <div className="col-12 col-md-3">
                           {/* <FormGroupSelect
                            campo="banco"
                            opciones={bancoData}
                            label="Banco"
                            claseInput="form-control"
                            claseLabel="font-16"
                          ></FormGroupSelect> */}
                        <FormGroupSelectAnidado
                            classDivPadre={"ml-3 mr-3"}
                            classLabel={"font-16 font-weight-bold"}
                            classInput={"form-control"}
                            campo={"banco"}
                            labelText={"Banco"}
                            onChange={(e: any) => props.first!(e)}
                            information={bancoData}
                          ></FormGroupSelectAnidado>
                        </div>
                        <div className="col-12 col-md-3">
                          <FormGroupText2
                            campo="noBoleta"
                            clase="col-12"
                            claseLabelParent="col-12 font-weight-bold font-16"
                            label="Número de Documento"
                            changeValue={OnlyNumber}
                          ></FormGroupText2>
                        </div>
                        <div className="col-12 col-md-3">
                          <FormGroupText2
                            campo="saldoTotalPagar"
                            clase="col-12 "
                            claseLabelParent="col-12 font-weight-bold font-16 text-color-orange"
                            label="Saldo crédito al día de hoy"
                            claseInput="form-control"
                            readonly={true}
                          ></FormGroupText2>
                        </div>
                      </div>

                      <div className="row">
                        <div className="col-12">
                          <FormGroupText2
                            campo="observaciones"
                            clase="col-12"
                            claseLabelParent="col-12 font-weight-bold font-16"
                            label="Observaciones"
                          ></FormGroupText2>
                        </div>
                      </div>
                    </div>
                    <div
                      className="col-12 col-md-12 mt-3"
                      style={{ borderTop: "1px solid var(--naranja)" }}
                    >
                      <h4 className="text-center">Detalle de Pago</h4>
                      <div className="row">
                        <div className="col-12 col-md-3">
                          <FormGroupText4
                            campo="totalPagar"
                            clase="col-12"
                            claseLabelParent="col-12 font-weight-bold font-16"
                            label="Total Cuota"
                            readonly={true}
                            value={props.modelo.totalPagar}
                          ></FormGroupText4>
                        </div>
                        <div className="col-12 col-md-3">
                          <FormGroupText2
                            campo="pagoCapital"
                            clase="col-12"
                            claseLabelParent="col-12 font-weight-bold font-16"
                            label="Pago a Capital"
                            readonly={true}
                          ></FormGroupText2>
                        </div>
                        {/* <div className="col-12 col-md-3">
                          <FormGroupText2
                            campo="pagoCapitalAnticipado"
                            clase="col-12"
                            claseLabelParent="col-12 font-weight-bold font-16"
                            label="Pago de Capital Anticipado"
                            readonly={true}
                          ></FormGroupText2>
                        </div> */}
                        <div className="col-12 col-md-3">
                          <FormGroupText2
                            campo="pagoInteres"
                            clase="col-12"
                            claseLabelParent="col-12 font-weight-bold font-16"
                            label="Pago a Interéses"
                            readonly={true}
                          ></FormGroupText2>
                        </div>
                        <div className="col-12 col-md-3">
                          <FormGroupText2
                            campo="pagoIva"
                            clase="col-12"
                            claseLabelParent="col-12 font-weight-bold font-16"
                            label="Pago Iva"
                            readonly={true}
                          ></FormGroupText2>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-12 col-md-3">
                          <FormGroupText2
                            campo="pagoMora"
                            clase="col-12"
                            claseLabelParent="col-12 font-weight-bold font-16"
                            label="Pago de Mora"
                            readonly={true}
                          ></FormGroupText2>
                          {/* <div className={props.claseBoton}>
                            <Whisper
                              placement="top"
                              controlId="control-id-hover"
                              trigger="hover"
                              speaker={tooltip2}
                            >
                              <FontAwesomeIcon
                                icon={faBan}
                                className="ml-2 mr-2 white-icon"
                                style={{
                                  float: "right",
                                  position: "relative",
                                  top: "-30px",
                                  right: "-10px",
                                  cursor: "pointer",
                                }}
                                onClick={props.cancelMora}
                              />
                            </Whisper>
                          </div> */}
                        </div>
                        <div className="col-12 col-md-3">
                          <FormGroupText2
                            campo="pagoIvaMora"
                            clase="col-12"
                            claseLabelParent="col-12 font-weight-bold font-16"
                            label="Pago Iva Mora"
                            readonly={true}
                          ></FormGroupText2>
                        </div>
                        {/* <div className="col-12 col-md-3">
                          <FormGroupText3
                            campo="totalGastos"
                            clase="col-12"
                            claseLabelParent="col-12 font-weight-bold font-16"
                            label="Total Gastos"
                            readonly={false}
                            keyEnter={props.keyEnterGastos}
                            changeValue={OnlyNumberFloat}
                          ></FormGroupText3>
                        </div> */}

                        <div className="col-12 col-md-3">
                          <FormGroupText2
                            campo="fechaProximoPago"
                            clase="col-12"
                            claseLabelParent="col-12 font-weight-bold font-16"
                            label="Fecha Próximo Pago"
                            readonly={true}
                          ></FormGroupText2>
                        </div>
                        <div className="col-12 col-md-3">
                          <FormGroupText2
                            campo="diasMoraInteres"
                            clase="col-12"
                            claseLabelParent="col-12 font-weight-bold font-16"
                            label="Días Mora Interés"
                            readonly={true}
                          ></FormGroupText2>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-12 col-md-3">
                          <FormGroupText2
                            campo="estadoCreditoFecha"
                            clase="col-12"
                            claseLabelParent="col-12 font-weight-bold font-16"
                            label="Estado del Crédito a la Fecha"
                            readonly={true}
                          ></FormGroupText2>
                        </div>
                      </div>
                      {/* <div className="row">
                        <div className="col-12 col-md-3">
                          <FormGroupText2
                            campo="cuotasAdelantadasEstePago"
                            clase="col-12"
                            claseLabelParent="col-12 font-weight-bold font-16"
                            label="Cuotas Adelantadas a Este Pago"
                            readonly={true}
                          ></FormGroupText2>
                        </div>
                        <div className="col-12 col-md-6">
                          <FormGroupText2
                            campo="cuotasVencidasCubiertasPago"
                            clase="col-12"
                            claseLabelParent="col-12 font-weight-bold font-16"
                            label="Cuotas Vencidas cubiertas por este Pago"
                            readonly={true}
                          ></FormGroupText2>
                        </div>
                      </div> */}
                    </div>
                  </div>
                  {/* <div className="row ">
                <div className="col-4">
                  <FormSelectSuite
                    label="Transacción"
                    campo="transaccion"
                    classLabel="font-weight-bold font-16"
                    classParent=""
                    data={[]}
                    newValue={(id) => {}}
                    value={1}
                  ></FormSelectSuite>
                </div>
                <div className="col-4">
                  <FormGroupText2
                    campo="montoTotal"
                    clase="col-12 "
                    claseLabelParent="col-12 font-weight-bold font-16"
                    label="Monto Total"
                  ></FormGroupText2>
                </div>
                <div className="col-4">
                  <FormGroupText2
                    campo="pendienteIngresar"
                    clase="col-12 "
                    claseLabelParent="col-12 font-weight-bold font-16"
                    label="Pendiente de Ingresar"
                    readonly
                  ></FormGroupText2>
                </div>
              </div> */}
                  {/*  */}
                  {/* <div className="row">
                <div className="col-4">
                  <FormGroupText2
                    campo="formaPago"
                    clase="col-12 "
                    claseLabelParent="col-12 font-weight-bold font-16"
                    label="Forma de Pago"
                  ></FormGroupText2>
                </div>
              </div> */}
                  {/*  */}
                  {/*
              <div className="row">
                <div className="col-4">
                  <FormGroupText2
                    campo="capital"
                    clase="col-12 "
                    claseLabelParent="col-12 font-weight-bold font-16"
                    label="Capital"
                  ></FormGroupText2>
                </div>
                <div className="col-4">
                  <FormGroupText2
                    campo="noDocumento"
                    clase="col-12 "
                    claseLabelParent="col-12 font-weight-bold font-16"
                    label="# Documento"
                  ></FormGroupText2>
                </div>
                <div className="col-4 d-flex align-items-center">
                  <Button className="orange-dark-button">
                    Seleccionar Archivo
                  </Button>
                </div>
              </div> */}
                  {/* <div className="row">
                <div className="col-4">
                  <FormGroupText2
                    campo="intereses"
                    clase="col-12 "
                    claseLabelParent="col-12 font-weight-bold font-16"
                    label="Intereses"
                  ></FormGroupText2>
                </div>
                <div className="col-4">
                  <FormSelectSuite
                    label="Banco"
                    campo="banco"
                    classLabel=" font-weight-bold font-16"
                    classParent=""
                    data={[]}
                    newValue={(id) => {}}
                    value={1}
                  ></FormSelectSuite>
                </div>
                <div className="col-4">
                  <FormGroupFechas
                    campo="fecha"
                    clase=""
                    claseLabel="font-weight-bold font-16"
                    label="Fecha del Déposito"
                  ></FormGroupFechas>
                </div>
              </div> */}
                  {/* <div className="row">
                <div className="col-4">
                  {" "}
                  <FormGroupText2
                    campo="mora"
                    clase="col-12 "
                    claseLabelParent="col-12 font-weight-bold font-16"
                    label="Mora"
                  ></FormGroupText2>
                </div>
                <div className="col-4">
                  {" "}
                  <FormGroupText2
                    campo="iva"
                    clase="col-12 "
                    claseLabelParent="col-12 font-weight-bold font-16"
                    label="IVA"
                  ></FormGroupText2>
                </div>
              </div> */}
                  {/* <div className="row">
                <div className="col-12">
                  <FormGroupTextArea
                    campo="comentario"
                    classDivPadre="d-flex flex-column ml-3"
                    classInputText="form-control"
                    classLabel="font-weight-bold font-16"
                    labelText="Comentarios"
                  ></FormGroupTextArea>
                </div>
              </div> */}
                </Form>
              </>
            ) : (
              <>
                <div className="text-center w-100 h-100">
                  <div
                    className="spinner-border"
                    style={{ width: "3rem", height: "3rem" }}
                    role="status"
                  ></div>
                  <span className="d-block color_blue size_type_font">
                    Cargando Formulario.
                  </span>
                </div>
              </>
            )}
          </div>
        )}
      </Formik>
    </>
  );
};

interface formRegistroPagoPropsInterface {
  modelo: any;
  handleClose(): void;
  onSubmit(valores: any, formikHelpers: FormikHelpers<any>): void;
  keyEnter?(e: any): void;
  keyEnterGastos?(e: any): void;
  searchDataEnter?(): void;
  cancelMora?(): void;
  first?(e:any): void;
  second?(e:any): void;
  claseBoton?: string;
  validationSchema?: any;
  infoPerson: any;
}
