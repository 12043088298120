import React from "react";
import "./App.css";
import {} from "react-router";
import { BrowserRouter, HashRouter, Route, Switch } from "react-router-dom";
import rutas from "./Routers/route-config";
import configurarInterceptor from "./Auth/Interceptores";

configurarInterceptor();

function App() {
  return (
    <>
      <HashRouter>
        <div className="">
          <Switch>
            {rutas.map((ruta) => (
              <Route key={ruta.path} path={ruta.path} exact={ruta.exact}>
                <ruta.componente></ruta.componente>
              </Route>
            ))}
          </Switch>
        </div>
      </HashRouter>
    </>
  );
}

export default App;
