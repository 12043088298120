import { useEffect, useState } from "react";
import Navbar from "../../Components/Navbar";
import { FormPersonalData } from "./FormPersonalData";
import * as Yup from "yup";
import { useHistory } from "react-router-dom";
import Swal from "sweetalert2";
import { urlCatalogues, urlLending } from "../../Utils/endpoints";
import { useAxiosSelectData } from "../../Hooks/useAxiosSelectData";
import { createLendingPayload } from "../../Utils/CreateObjects";
import { postPetition } from "../../Utils/ServicesPetitions";
import { FormPersonalData2 } from "./FormPersonalData2";
import axios, { AxiosResponse } from "axios";

export const NewPrestamo = () => {
  const modelo = {
    phoneNumber: "",
    phoneNumberEnterprise: "",
    numero: "",
    email: "",
    name: "",
    secondname: "",
    apellido: "",
    segundoapellido: "",
    othername: "",
    fechanacimiento: new Date(),
    dpi: "",
    estado: "",
    address: "",
    colonia: "",
    estatus: "",
    gestor: "",
    nit: "",
    comentarios: "",
    pais: "GTM",
    genero: 1,
    empresaPlanilla : 0,
    estadoPrestamoId: 1,
    departamentoId: 1,
    municipioId: "101",
    tipoViviendaId: 1,
    ocupacionId: "",
    destinoPrestamoId: 1,
    tipoPrestamoId: 1,
    empresa: 1,
    montoCapital: 0,
    saldoCapital: 0,
    plazo: 0,
    direccionlaboral: "",
  };

  const schema = Yup.object({
    phoneNumber: Yup.string()
      .min(8, "El número mínimo de caracteres permitidos es 8.")
      .max(8, "El número máximo de caracteres permitidos es 8."),
    phoneNumberEnterprise: Yup.string()
      .min(8, "El número mínimo de caracteres permitidos es 8.")
      .max(8, "El número máximo de caracteres permitidos es 8."),
    numero: Yup.string()
      .required("Campo requerido")
      .min(8, "El número mínimo de caracteres permitidos es 8.")
      .max(8, "El número máximo de caracteres permitidos es 8."),
    email: Yup.string()
      .required("Campo requerido")
      .email("Correo no válido (Por ejemplo: someone@example.com)"),
    name: Yup.string().required("Campo requerido"),
    secondname: Yup.string(),
    apellido: Yup.string().required("Campo requerido"),
    segundoapellido: Yup.string(),
    fechanacimiento: Yup.date().required("Campo Requerido"),
    dpi: Yup.string()
      .required("Campo requerido")
      .min(13, "El número mínimo de caracteres permitidos es 13.")
      .max(13, "El número máximo de caracteres permitidos es 13."),
    estado: Yup.string().required("Campo requerido"),
    address: Yup.string().required("Campo requerido"),
    colonia: Yup.string(),
    estatus: Yup.string(),
    gestor: Yup.number().required("Campo Requerido"),
    nit: Yup.string()
      .matches(/^[0-9]+([0-9kK])?$/, "NIT no válido.")
      .nullable()
      .typeError("Campo requerido")
      .required("Campo requerido"),
    estadoPrestamoId: Yup.number().required("Campo requerido"),
    empresa: Yup.number(),
    comentarios: Yup.string(),
    pais: Yup.string(),
    direccionlaboral: Yup.string(),
    genero: Yup.number(),
    departamentoId: Yup.number(),
    municipioId: Yup.string(),
    tipoViviendaId: Yup.number(),
    ocupacionId: Yup.number().required("Campo requerido"),
    destinoPrestamoId: Yup.number().required("Campo requerido"),
    tipoPrestamoId: Yup.number().required("Campo requerido"),
    montoCapital: Yup.number().required("Campo requerido"),
    saldoCapital: Yup.number(),
    plazo: Yup.number(),
  });

  const { data: estadoCivilData, loading: estadoCivilReady } =
    useAxiosSelectData(`${urlCatalogues}/estado-civil`);
  const { data: tipoPrestamoData, loading: tipoPrestamoReady } =
    useAxiosSelectData(`${urlCatalogues}/tipo-prestamo`);
  const { data: destinoPrestamoData, loading: destinoPrestamoReady } =
    useAxiosSelectData(`${urlCatalogues}/destino-prestamo`);
  const { data: gestoresData, loading: gestoresReady } = useAxiosSelectData(
    `${urlCatalogues}/gestores`
  );
  const { data: empresaPlanillaData, loading: empresaPlanillaReady } =
    useAxiosSelectData(`${urlCatalogues}/empresaPlanilla`);
  const { data: paisData, loading: paisReady } = useAxiosSelectData(
    `${urlCatalogues}/paises`
  );
  const { data: deptoData, loading: deptoReady } = useAxiosSelectData(
    `${urlCatalogues}/departmentos`
  );
  const { data: ocupacionData, loading: ocupacionReady } = useAxiosSelectData(
    `${urlCatalogues}/ocupaciones`
  );
  const { data: tipoVData, loading: tipoVReady } = useAxiosSelectData(
    `${urlCatalogues}/tipos_vivienda`
  );

  useEffect(() => {
    axios
      .get(`${urlCatalogues}/municipios/1`)
      .then((response: AxiosResponse) => {
        setMunicipio(response.data);
      });
  }, []);

  const [municipio, setMunicipio] = useState<any>([]);
  const handleChangeDep = (e: any) => {
    axios
      .get(`${urlCatalogues}/municipios/${e.target.value}`)
      .then((response: AxiosResponse) => {
        setMunicipio(response.data);
      });
  };

  const onSubmit = async (valores: any) => {
    if (
      valores.montoCapital === 0 ||
      valores.montoCapital === "0" ||
      valores.montoCapital === ""
    ) {
      Swal.fire({
        title: `Error`,
        text: `El monto capital no puede ser 0.`,
        icon: "warning",
      });
      return;
    }
    let payload = createLendingPayload(valores);
    setLoading(true);

    // return console.log(payload);
    const data = await postPetition(urlLending, payload);

    setLoading(false);
    if (data === -1)
      return Swal.fire({
        title: `Error`,
        text: `No se pudo guardar préstamo.`,
        icon: "warning",
      });

    Swal.fire({
      title: `Guardado`,
      text: `Préstamo creado correctamente.`,
      icon: "success",
    });
    history.replace("/home");
  };

  const history = useHistory();
  const [loading, setLoading] = useState(false);

  return (
    <>
      <Navbar show={false}></Navbar>
      {!estadoCivilReady &&
      !destinoPrestamoReady &&
      !gestoresReady &&
      !tipoPrestamoReady &&
      !empresaPlanillaReady &&
      !deptoReady &&
      !ocupacionReady &&
      !tipoVReady &&
      !paisReady ? (
        <>
          <div className="container-fluid pr-sm-5">
            <FormPersonalData2
              modelo={modelo}
              onSubmit={onSubmit}
              validationSchema={schema}
              readonly={false}
              new={true}
              route={true}
              destinoPrestamo={destinoPrestamoData}
              estadoCivil={estadoCivilData}
              gestores={gestoresData}
              tipoPrestamo={tipoPrestamoData}
              empresaPlanilla={empresaPlanillaData}
              depto={deptoData}
              pais={paisData}
              muni={municipio}
              ocupacion={ocupacionData}
              tiposV={tipoVData}
              handleChangeDep={handleChangeDep}
            ></FormPersonalData2>
          </div>
        </>
      ) : (
        <>
          <div className="text-center w-100 h-100">
            <div
              className="spinner-border"
              style={{ width: "3rem", height: "3rem" }}
              role="status"
            ></div>
            <span className="d-block color_blue size_type_font">
              Cargando Formulario.
            </span>
          </div>
        </>
      )}
      {loading && (
        <>
          <div className="loagin_container">
            <span className="loader"></span>
          </div>
        </>
      )}
    </>
  );
};
