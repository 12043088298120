import { useFormikContext } from "formik";
import MostrarErrorCampo from "./MostrarErrorCampo";

export default function FormGroupText(props: FormGroupTextProps) {
  const { values, validateForm, touched, errors } = useFormikContext<any>();

  return (
    <div className={props.clase}>
      {props.label && (
        <label htmlFor={props.campo} className={props.claseLabel}>
          {props.label}
        </label>
      )}
      <input
        type={props.type}
        name={props.campo}
        className={props.claseInput}
        placeholder={props.placeHolder}
        disabled={props.disable}
        defaultValue={values[props.campo]}
        readOnly={props.readonly}
        onChange={(e) => {
          values[props.campo] = e.target.value;
          validateForm();
        }}
        onKeyPress={(e) => {
          props.changeValue && props.changeValue(e);
        }}
        step="any"
        min={"0"}
      />
      {touched[props.campo] && errors[props.campo] ? (
        <MostrarErrorCampo mensaje={errors[props.campo]?.toString()!} />
      ) : null}
    </div>
  );
}

interface FormGroupTextProps {
  disable: boolean;
  clase: string;
  claseLabel: string;
  claseInput: string;
  campo: string;
  label?: string;
  type: "text" | "password" | "email" | "textarea" | "number";
  placeHolder?: string;
  changeValue?: any;
  max?: number;
  min?: number;
  requerido?: boolean;
  readonly?: boolean;
}

FormGroupText.defaultProps = {
  type: "text",
  class: "form-group",
  claseLabel: "mb-2",
  claseInput: "form-control",
  disable: false,
  requerido: false,
};
