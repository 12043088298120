import React, { ReactElement } from "react";

export const PrintComponent = (props: printComponentPropsInterface) => {
  return (
    <>
      <div className="print-source" ref={props.printReference}>
        <div className="d-flex flex-column justify-content-center print-header">
          <h4>AMIGOPAQ</h4>
        </div>
        {props.nombre !== "" && (
          <div className="d-flex print-info">
            <h5 className="font-weight-bold">Nombre: {props.nombre}</h5> <br />
          </div>
        )}

        {props.dpi !== "" && (
          <div className="d-flex print-info">
            <h5 className="font-weight-bold">DPI: {props.dpi}</h5>
          </div>
        )}
        {props.table}
      </div>
    </>
  );
};

interface printComponentPropsInterface {
  printReference: any;
  nombre: string;
  dpi?: string;
  table: ReactElement;
}

PrintComponent.defaultProps = {
  dpi: "",
  nombre: "",
};
