import Login from "../Auth/Login";
import { ReciboPrint } from "../Components/ReciboPrint";
import Home from "../Pages/Home/Home";
import { EditPerson } from "../Pages/Prestamos/EditPerson";
import { NewPrestamo } from "../Pages/Prestamos/NewPrestamo";
import { NewPrestamoCompany } from "../Pages/Prestamos/NewPrestamoCompany";
import { PrestamoDetail } from "../Pages/Prestamos/Prestamo";
import { PrestamoCompany } from "../Pages/Prestamos/PrestamoCompany";
import { PrestamoCompanyDetailActivated } from "../Pages/Prestamos/PrestamoCompanyDetailActivated";
import { PrestamoDetailActivated } from "../Pages/Prestamos/PrestamoDetailActivated";
import { PrestamoDetalle } from "../Pages/Prestamos/PrestamoDetalle";
import RedirecTo from "./RedirectTo";

const rutas = [
  {
    path: "/home",
    componente: Home,
    exact: true,
    permisos: true,
    principal: true,
  },
  {
    path: "/prestamo/:creditId(\\d+)",
    componente: PrestamoDetail,
    expect: true,
    permisos: true,
  },
  {
    path: "/prestamoCompany/:creditId(\\d+)",
    componente: PrestamoCompany,
    expect: true,
    permisos: true,
  },
  {
    path: "/prestamoDetail/:creditId(\\d+)",
    componente: PrestamoDetailActivated,
    expect: true,
    permisos: true,
  },
  {
    path: "/prestamoCompanyDetail/:creditId(\\d+)",
    componente: PrestamoCompanyDetailActivated,
    expect: true,
    permisos: true,
  },
  {
    path: "/newPrestamo",
    componente: NewPrestamo,
    expect: true,
    permisos: true,
  },
  {
    path: "/newCompanyPrestamo",
    componente: NewPrestamoCompany,
    expect: true,
    permisos: true,
  },
  {
    path: "/EditPerson/:creditId(\\d+)",
    componente: EditPerson,
    expect: true,
    permisos: true,
  },
  { path: "/login", componente: Login, exact: true, permisos: false },
  { path: "*", componente: RedirecTo, exact: true, permisos: false },
];

export default rutas;
