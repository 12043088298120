import { useState } from "react";
import Navbar from "../../Components/Navbar";
import * as Yup from "yup";
import { useHistory } from "react-router-dom";
import axios, { AxiosError, AxiosResponse } from "axios";
import Swal from "sweetalert2";
import { urlCatalogues, urlLending } from "../../Utils/endpoints";
import { FormCompanyData } from "./FormCompanyData";
import { useAxiosSelectData } from "../../Hooks/useAxiosSelectData";
import { createLendingCompanyPayload } from "../../Utils/CreateObjects";
import { postPetition } from "../../Utils/ServicesPetitions";

export const NewPrestamoCompany = () => {
  const modelo = {
    phoneNumber: "",
    name: "",
    dpi: "",
    estado: "",
    address: "",
    estatus: "",
    gestor: "",
    nit: "",
    estadoPrestamoId: 1,
    destinoPrestamoId: 1,
    tipoPrestamoId: 1,
    montoCapital: 0,
    saldoCapital: 0,
    plazo: 0,
  };

  const schema = Yup.object({
    phoneNumber: Yup.string().required("Campo requerido"),
    name: Yup.string().required("Campo requerido"),
    address: Yup.string().required("Campo requerido"),
    estatus: Yup.string(),
    gestor: Yup.number().required("Campo requerido"),
    nit: Yup.string().required("Campo requerido"),
    destinoPrestamoId: Yup.number().required("Campo requerido"),
    tipoPrestamoId: Yup.number().required("Campo requerido"),
    montoCapital: Yup.number().required("Campo requerido"),
  });

  const { data: tipoPrestamoData, loading: tipoPrestamoReady } =
    useAxiosSelectData(`${urlCatalogues}/tipo-prestamo`);
  const { data: destinoPrestamoData, loading: destinoPrestamoReady } =
    useAxiosSelectData(`${urlCatalogues}/destino-prestamo`);
  const { data: gestoresData, loading: gestoresReady } = useAxiosSelectData(
    `${urlCatalogues}/gestores`
  );

  const onSubmit = async (valores: any) => {
    if (
      valores.montoCapital === 0 ||
      valores.montoCapital === "0" ||
      valores.montoCapital === ""
    ) {
      Swal.fire({
        title: `Error`,
        text: `El monto capital no puede ser 0.`,
        icon: "warning",
      });
      return;
    }
    let payload = createLendingCompanyPayload(valores);
    setLoading(true);

    const data = await postPetition(urlLending, payload);

    setLoading(false);
    if (data === -1)
      return Swal.fire({
        title: `Error`,
        text: `No se pudo guardar préstamo.`,
        icon: "warning",
      });

    Swal.fire({
      title: `Guardado`,
      text: `Préstamo creado correctamente.`,
      icon: "success",
    });
    history.replace("/home");
  };
  const history = useHistory();
  const [loading, setLoading] = useState(false);

  return (
    <>
      <Navbar show={false}></Navbar>
      {!destinoPrestamoReady && !gestoresReady && !tipoPrestamoReady ? (
        <>
          <div className="container-fluid pr-sm-5">
            <FormCompanyData
              modelo={modelo}
              onSubmit={onSubmit}
              validationSchema={schema}
              readonly={false}
              new={true}
              ocultar={false}
              route={true}
              destinoPrestamo={destinoPrestamoData}
              gestores={gestoresData}
              tipoPrestamo={tipoPrestamoData}
            ></FormCompanyData>
          </div>
        </>
      ) : (
        <>
          <div className="text-center w-100 h-100">
            <div
              className="spinner-border"
              style={{ width: "3rem", height: "3rem" }}
              role="status"
            ></div>
            <span className="d-block color_blue size_type_font">
              Cargando Formulario.
            </span>
          </div>
        </>
      )}

      {loading && (
        <>
          <div className="loagin_container">
            <span className="loader"></span>
          </div>
        </>
      )}
    </>
  );
};
