import { Table } from "rsuite";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faClose } from "@fortawesome/free-solid-svg-icons";
import { useHistory } from "react-router-dom";
const { Column, HeaderCell, Cell } = Table;

export const TableConsultaBitacora = (
  props: tableConsultaBitacoraInterface
) => {
  let styleVaraibles = { background: "var(--naranja)", color: "var(--blanco)" };

  return (
    <>
      {" "}
      <div className="row">
        <div className="col-12 justify-content-end d-flex">
          <FontAwesomeIcon
            className="mouse-pointer orange-icon ml-4"
            icon={faClose}
            onClick={() => props.handleCloseModal!()}
            style={{ height: "24px" }}
          ></FontAwesomeIcon>
        </div>
      </div>
      <hr />
      <div>
        <Table
          height={400}
          data={props.data}
          className="mx-auto font-16 w-100"
          rowClassName={"row-table"}
          cellBordered
          loading={props.loading}
        >
          <Column flexGrow={1} align="center" fixed minWidth={90}>
            <HeaderCell
              className="font-16 font-weight-bold"
              style={styleVaraibles}
            >
              Fecha
            </HeaderCell>
            <Cell dataKey="fecha" />
          </Column>
          <Column flexGrow={1} minWidth={100}>
            <HeaderCell
              className="font-16 font-weight-bold"
              style={styleVaraibles}
            >
              Préstamo
            </HeaderCell>
            <Cell dataKey="prestamo" />
          </Column>
          <Column flexGrow={1} minWidth={100}>
            <HeaderCell
              className="font-16 font-weight-bold"
              style={styleVaraibles}
            >
              Convenio
            </HeaderCell>
            <Cell dataKey="convenio" />
          </Column>
          <Column flexGrow={1} minWidth={100}>
            <HeaderCell
              className="font-16 font-weight-bold"
              style={styleVaraibles}
            >
              Usuario
            </HeaderCell>
            <Cell dataKey="usuario" />
          </Column>
          <Column flexGrow={1} minWidth={100}>
            <HeaderCell
              className="font-16 font-weight-bold"
              style={styleVaraibles}
            >
              Medio de Comunicación
            </HeaderCell>
            <Cell dataKey="medioComunicacion" />
          </Column>
          <Column flexGrow={1} minWidth={100}>
            <HeaderCell
              className="font-16 font-weight-bold"
              style={styleVaraibles}
            >
              Comentario
            </HeaderCell>
            <Cell dataKey="comentario" />
          </Column>
        </Table>
      </div>
    </>
  );
};

interface tableConsultaBitacoraInterface {
  data: any[];
  loading: boolean;
  handleCloseModal?(): void;
}
