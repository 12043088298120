import { faCheckCircle, faClose, faL } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ReportBts } from "./ReportBts";
import { Form, Formik, FormikHelpers } from "formik";
import FormGroupFechas from "../Utils/FormGroupFechas";
import Button from "../Utils/Button";
import FormGroupText2 from "../Utils/FormGroupText2";
import { OnlyNumber } from "../Utils/Validaciones";

export const ModalNoFactura = (props: reportModalPropsInterface) => {
  return (
    <>
      <div>
        <div className="container-fluid">
          <div className="row">
            <div className="col-12 justify-content-between d-flex">
              <h3>Número de factura</h3>
              <FontAwesomeIcon
                className="mouse-pointer orange-icon ml-4"
                icon={faClose}
                onClick={() => props.handleCloseModal!()}
                style={{ height: "24px" }}
              ></FontAwesomeIcon>
            </div>
          </div>
          <hr />
          <div className="row">
            <div>
              <Formik
                initialValues={props.modelo}
                onSubmit={props.onSubmit}
                validationSchema={props.validationSchema}
                enableReinitialize
              >
                {(formikProps) => (
                  <div className="container-fluid w-100 pt-1 pb-1">
                    <>
                      <Form>
                        <div className="row justify-content-center ">
                          <div className="col-12 mb-2">
                            <FormGroupText2
                              campo="numero"
                              clase="col-12"
                              claseLabelParent=""
                              label=""
                              type="text"
                              placeHolder="Número de factura"
                              changeValue={OnlyNumber}
                            ></FormGroupText2>
                          </div>
                          <div className="col-12 row">
                              <Button
                                type="submit"
                                className="button orange-button col-5"
                              >
                              Aceptar
                              </Button>
                              <div className="col-1"></div>
                              <Button
                                type="button"
                                className="button orange-dark-button red-button col-5"
                                onClick={props.handleCloseModal}
                              >
                                Cancelar
                              </Button>
                          </div>
                        </div>
                      </Form>
                    </>
                  </div>
                )}
              </Formik>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

interface reportModalPropsInterface {
  handleCloseModal?(): void;
  modelo?: any;
  onSubmit(valores: any, formikHelpers: FormikHelpers<any>): void;
  validationSchema?: any;
}
