export const ReciboPrint = (props: reciboPrintInterfaceProps) => {
  return (
    <div className="print-source" ref={props.printReference}>
      <div className="print-recibo-header">
        <div>
          <span>
            <b>Guatemala, {props.data.fechaRecibo}</b>
          </span>
          <br />
          <span>
            <b>Nombre deudor:</b> {props.data.nombreDeudor}
          </span>
          <br />
          <span>
            <b>Recibo no.:</b> {props.data.reciboNo}
          </span>
          <br />
          <span>
            <b>Forma de Pago:</b> {props.data.formaPago}
          </span>
          <br />
          {/* <span>
            <b>Número de Factura:</b> {props.factura}
          </span> */}
        </div>
        <img src={require("../Assets/AmigoPaq-Logo.png")} alt="" />
      </div>
      <div className="print-recibo-detail">
        <table>
          <thead>
            <tr>
              <td>Tipo Transacción</td>
              <td>Descripción</td>
              <td>Monto</td>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>{props.data.tipoTranCapital}</td>
              <td>Capital</td>
              <td>{props.data.capital}</td>
            </tr>
            <tr>
              <td>{props.data.tipoTranIntereses}</td>
              <td>Intereses</td>
              <td>{props.data.intereses}</td>
            </tr>
            <tr>
              <td>{props.data.tipoTranIvaIntereses}</td>
              <td>IVA de Intereses</td>
              <td>{props.data.iva}</td>
            </tr>
            <tr>
              <td>{props.data.tipoTransaccionMora}</td>
              <td>Mora</td>
              <td>{props.data.mora}</td>
            </tr>
            <tr>
              <td>{props.data.tipoTransaccionIvaMora}</td>
              <td>Iva de Mora</td>
              <td>{props.data.ivaMora}</td>
            </tr>
            <tr>
              <td>{props.data.tipoTransaccionGastos}</td>
              <td>Gastos Administrativos</td>
              <td>{props.data.gastos}</td>
            </tr>
            <tr>
              <td>{props.data.tipoTransaccionIvaGastos}</td>
              <td>Iva de Gastos Administrativos</td>
              <td>{props.data.ivaGastos}</td>
            </tr>
            <tr>
              <td></td>
              <td></td>
              <td>____________________</td>
            </tr>
            <tr>
              <td></td>
              <td></td>
              <td>{props.data.montoPago}</td>
            </tr>
          </tbody>
        </table>
      </div>
      <div className="print-recibo-footer">
        <span>
          <b>Caja: </b>
          {props.data.caja}
        </span>
        <br />
        <span>
          <b>Usuario: </b>
          {props.data.nombreUsuario}
        </span>
        <br />
        <span>
          <b>Gestor: </b>
          {props.data.nombreGestor}
        </span>
      </div>
      <div className="print-recibo-firma container">
        <span>
          <b>Firma Encargado</b>
        </span>
        <span>
          <b>Firma Deudor</b>
        </span>
      </div>
    </div>
  );
};

interface reciboPrintInterfaceProps {
  printReference?: any;
  data?: any;
  factura?:any;
}
