import React, { ReactElement } from 'react'

export default function ListadoGenerico(props: ListadoGenericoProps) {

    if(!props.listado)
    {
        if(props.cargandoUI)
        {
            return props.cargandoUI;
        }
        return <>No se encontraron elementos</>
    }else if(props.listado.length === 0)
    {
        if(props.listadoVacioUI)
        {
            return props.listadoVacioUI;
        }
        return <>0 registros</>
    }else
    {
        return props.children;
    }

}

interface ListadoGenericoProps{
    listado: any; 
    children: ReactElement;
    cargandoUI ?: ReactElement; 
    listadoVacioUI ?: ReactElement;
}
