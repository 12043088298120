import moment from "moment";
import { obtenerUserData } from "../Auth/manejadorJWT";
import { company } from "../Interfaces/company.model";
import {
  CreateLendingDTO,
  LendingPost,
  PersonDTO2,
  PersonDTOPut,
} from "../Interfaces/lending.model";
import { persona } from "../Interfaces/persona.model";
import { Currency } from "./ConvertValueToLocalValue";

export const createLendingPayload = (valores: any) => {
  let auxPayload: CreateLendingDTO = {
    appUserId: obtenerUserData(4, localStorage.getItem("token")!)[0],
    tipoEntidad: 1,
    gestorPrestamoId: parseInt(valores.gestor),
    destinoPrestamoId: parseInt(valores.destinoPrestamoId),
    tipoPrestamoId: parseInt(valores.tipoPrestamoId),
    montoOtorgado: parseFloat(valores.montoCapital),
    createPersonDto: {
      apellidoCasada: "",
      direccion: valores.address.trim(),
      estadoCivilId: parseInt(valores.estado),
      nit: valores.nit,
      numeroDocumento: valores.dpi.trim(),
      numeroTelefono: valores.numero.trim(),
      email: valores.email.trim(),
      // generoId: 1,
      generoId: valores.genero,
      // ocupacionId: valores.ocupacionId,
      tipoViviendaId: valores.tipoViviendaId,
      paisNacimientoId: valores.pais,
      departamentoId: valores.departamentoId,
      municipioId: valores.municipioId,
      primerNombre: valores.name.toUpperCase().trim(),
      segundoNombre: valores.secondname.toUpperCase().trim(),
      primerApellido: valores.apellido.toUpperCase().trim(),
      segundoApellido: valores.segundoapellido.toUpperCase().trim(),
      fechaNacimiento: valores.fechanacimiento,
      direccionLaboral: valores.direccionlaboral,
      colonia: valores.colonia.trim(),
      numeroCelular: valores.phoneNumber,
      numeroTelefonoLaboral: valores.phoneNumberEnterprise,
      comentarios: valores.comentarios.trim(),
    },
  };

  if (parseInt(valores.empresaPlanilla) > 0) {
    auxPayload.empresaPrestamoId = parseInt(valores.empresaPlanilla);
  }

  if (parseInt(valores.ocupacionId) > 0) {
    auxPayload.createPersonDto!.ocupacionId = parseInt(valores.ocupacionId);
  }

  return auxPayload;
};

export const createLendingCompanyPayload = (valores: any) => {
  let auxPayload: CreateLendingDTO = {
    appUserId: obtenerUserData(4, localStorage.getItem("token")!)[0],
    tipoEntidad: 2,
    gestorPrestamoId: parseInt(valores.gestor),
    destinoPrestamoId: parseInt(valores.destinoPrestamoId),
    tipoPrestamoId: parseInt(valores.tipoPrestamoId),
    montoOtorgado: parseFloat(valores.montoCapital),
    createCompanyDto: {
      nombre: valores.name.trim(),
      direccion: valores.address.trim(),
      nit: valores.nit,
      telefono: valores.phoneNumber.trim(),
      email: "",
    },
  };

  return auxPayload;
};

export const auxResponseDataLendingPerson = (response: any) => {
  const auxDataLending: persona = {
    phoneNumber: "",
    name: "",
    dpi: "",
    estado: "",
    address: "",
    estatus: "",
    gestor: "",
    nit: "",
    empresaPlanilla: "",
  };

  auxDataLending.address =
    response.data.lendingP[0].direccion === null
      ? ""
      : response.data.lendingP[0].direccion;
  auxDataLending.name =
    response.data.lendingP[0].nombres === null
      ? ""
      : `${response.data.lendingP[0].nombres} ${response.data.lendingP[0].apellidos}`;
  auxDataLending.nit =
    response.data.lendingP[0].nit === null ? "" : response.data.lendingP[0].nit;
  auxDataLending.phoneNumber =
    response.data.lendingP[0].numeroTelefono === null
      ? ""
      : response.data.lendingP[0].numeroTelefono;
  auxDataLending.dpi =
    response.data.lendingP[0].dpi === null ? "" : response.data.lendingP[0].dpi;
  auxDataLending.estado = response.data.lendingP[0].estadoCivil;
  auxDataLending.empresaPlanilla =
    response.data.empresaPlanilla === null
      ? ""
      : response.data.empresaPlanilla.nombre;
  auxDataLending.gestor =
    response.data.agenteP.nombre === null ? "" : response.data.agenteP.nombre;
  auxDataLending.estatus = response.data.lendingP[0].estadoPrestamo;

  return auxDataLending;
};

export const auxResponseDataLendingPrestamo = (response: any) => {
  const auxModeloPrestamo = {
    montoTotal: "",
    saldoCapital: "",
    saldoAdministrativo: "",
    saldoIntereses: "",
    saldoIva: "",
  };

  auxModeloPrestamo.montoTotal = Currency(
    parseFloat(response.data.lendingP[0].montoTotal)
  );

  auxModeloPrestamo.saldoCapital = Currency(
    parseFloat(response.data.saldoCapital)
  );
  auxModeloPrestamo.saldoAdministrativo = Currency(
    parseFloat(response.data.saldoGastosAdministrativos)
  );
  auxModeloPrestamo.saldoIntereses = Currency(
    parseFloat(response.data.saldoIntereses)
  );
  auxModeloPrestamo.saldoIva = Currency(parseFloat(response.data.saldoIva));

  return auxModeloPrestamo;
};

export const createPLanPagoPayload = (valores: any, valorTemporal: string) => {
  let auxPayload: LendingPost = {
    principalAmount: parseFloat(valorTemporal),
    administrativeExpesesRate: parseFloat(valores.gastosAdministracion) > 1 ? parseFloat(valores.gastosAdministracion)/100 : parseFloat(valores.gastosAdministracion),
    interestRate: parseFloat(valores.interes) > 1 ? parseFloat(valores.interes)/100 : parseFloat(valores.interes),
    startDate: moment(valores.fecha).format(),
    payDate: moment(valores.fechaPago).format(),
    taxRate: parseFloat(valores.iva) > 1 ? parseFloat(valores.iva)/100 : parseFloat(valores.iva),
    term: parseInt(valores.plazo),
  };

  return auxPayload;
};

export const auxResponseDataLendingCompany = (response: any) => {
  const auxDataLending: company = {
    phoneNumber: "",
    name: "",
    address: "",
    gestor: "",
    nit: "",
  };

  auxDataLending.address =
    response.data.lendingE[0].direccion === null
      ? ""
      : response.data.lendingE[0].direccion;
  auxDataLending.name =
    response.data.lendingE[0].nombre === null
      ? ""
      : `${response.data.lendingE[0].nombre}`;
  auxDataLending.nit =
    response.data.lendingE[0].nit === null ? "" : response.data.lendingE[0].nit;
  auxDataLending.phoneNumber =
    response.data.lendingE[0].telefono === null
      ? ""
      : response.data.lendingE[0].telefono;
  auxDataLending.gestor =
    response.data.agenteE.nombre === null ? "" : response.data.agenteE.nombre;

  return auxDataLending;
};

export const auxResponseDataLendingPrestamoCompany = (response: any) => {
  const auxModeloPrestamo = {
    montoTotal: "",
    saldoCapital: "",
    saldoAdministrativo: "",
    saldoIntereses: "",
    saldoIva: "",
  };

  auxModeloPrestamo.montoTotal = Currency(
    parseFloat(response.data.lendingE[0].montoTotal)
  );
  auxModeloPrestamo.saldoCapital = Currency(
    parseFloat(response.data.saldoCapital)
  );
  auxModeloPrestamo.saldoAdministrativo = Currency(
    parseFloat(response.data.saldoGastosAdministrativos)
  );
  auxModeloPrestamo.saldoIntereses = Currency(
    parseFloat(response.data.saldoIntereses)
  );
  auxModeloPrestamo.saldoIva = Currency(parseFloat(response.data.saldoIva));

  return auxModeloPrestamo;
};

export const createPostPlanPagoPayload = (
  dataPlanPago: any,
  creditId: number,
  planPagoPost: LendingPost | undefined,
  mora: any
) => {
  let newAray: any = [];
  dataPlanPago.forEach((x: any) => {
    newAray.push({
      cuotaCapital: x.cuotaCapital,
      cuotaIntereses: x.cuotaIntereses,
      cuotaIvaIntereses: x.cuotaIvaIntereses,
      fechaPago: x.fechaPago,
      periodo: x.mes,
      saldo: x.saldoCapital,
      totalCuota: x.totalCuota,
      aplicado: false,
    });
  });
  let payload = {
    appUserId: obtenerUserData(4, localStorage.getItem("token")!)[0],
    prestamoId: creditId,
    fechaPlan: planPagoPost?.startDate,
    plazo: planPagoPost?.term,
    tasaInteres: planPagoPost?.interestRate,
    tasaIva: planPagoPost?.taxRate,
    tasaGastos: planPagoPost?.administrativeExpesesRate,
    tasaMora: parseFloat(mora),
    planPagos: newAray,
  };

  return payload;
};

export const auxResponseSaldosPrestamo = (response: any) => {
  const modeloPrestamo = {
    montoTotal: "",
    saldoCapital: "",
    saldoAdministrativo: "",
    saldoIntereses: "",
    saldoIva: "",
    saldoIvaMora: "",
  };

  modeloPrestamo.montoTotal = Currency(parseFloat(response.totalSaldo));

  modeloPrestamo.saldoCapital = Currency(parseFloat(response.saldoCapital));
  modeloPrestamo.saldoAdministrativo = Currency(parseFloat(response.saldoMora));
  modeloPrestamo.saldoIntereses = Currency(parseFloat(response.saldoIntereses));
  modeloPrestamo.saldoIva = Currency(parseFloat(response.saldoIvaInteres));

  modeloPrestamo.saldoIvaMora = Currency(parseFloat(response.saldoIvaMora));

  return modeloPrestamo;
};

export const auxResponseModeloPrestamoDetalle = (response: any) => {
  const modeloPrestamoDetalle = {
    plazo: "0",
    interes: "0",
    mora: "0",
    gastosAdministracion: "0",
    iva: "12",
    tipoPlan: "1",
    fecha: '',
    fechaPago: '',
  };

  modeloPrestamoDetalle.plazo = response.data.lendingP[0].plazo;
  modeloPrestamoDetalle.interes = response.data.lendingP[0].tasaInteres;
  modeloPrestamoDetalle.iva = response.data.lendingP[0].tasaIva;
  modeloPrestamoDetalle.mora = response.data.lendingP[0].tasaMora;

  modeloPrestamoDetalle.fecha =
    response.data.lendingP[0].fechaAprobacion === null
    ? moment(new Date().toString()).format().split('T')[0]
    : response.data.lendingP[0].fechaAprobacion.split('T')[0];

  modeloPrestamoDetalle.fechaPago =
    response.data.lendingP[0].fechaPlan === null
    ?  moment(new Date().toString()).format().split('T')[0]
    : response.data.lendingP[0].fechaPlan.split('T')[0];

  return modeloPrestamoDetalle;
};

export const auxResponseModeloPrestamoDetalleCompnay = (response: any) => {
  const modeloPrestamoDetalle = {
    plazo: "0",
    interes: "0",
    mora: "0",
    gastosAdministracion: "0",
    iva: "12",
    tipoPlan: "1",
    fecha: '',
    fechaPago: '',
  };

  modeloPrestamoDetalle.plazo = response.data.lendingE[0].plazo;
  modeloPrestamoDetalle.interes = response.data.lendingE[0].tasaInteres;
  modeloPrestamoDetalle.iva = response.data.lendingE[0].tasaIva;
  modeloPrestamoDetalle.mora = response.data.lendingE[0].tasaMora;

  modeloPrestamoDetalle.fecha =
    response.data.lendingE[0].fechaAprobacion === null
    ? moment(new Date().toString()).format().split('T')[0]
    : response.data.lendingE[0].fechaAprobacion.split('T')[0];

  modeloPrestamoDetalle.fechaPago =
    response.data.lendingE[0].fechaPlan === null
    ? moment(new Date().toString()).format().split('T')[0]
    : response.data.lendingE[0].fechaPlan.split('T')[0];

  return modeloPrestamoDetalle;
};

export const createAjustePagoPayload = (
  valores: any,
  creditId: any,
  rowData: any
) => {
  let payload = {
    appUserId: obtenerUserData(4, localStorage.getItem("token")!)[0],
    prestamoId: creditId,
    TipotransaccionId: parseInt(valores.banco),
    planPagoId: rowData,
    fechaTransaccion: valores.fecha,
    montoPago: parseFloat(valores.montoTotal),
    observaciones: valores.observaciones,
    motivoAnulacion: "",
  };

  return payload;
};

export const auxResponseModeloPago = (response: any, fecha:any, modelo2:any) => {
  let modeloPago = {
    montoTotal: "",
    fecha: fecha,
    formaPago: 2,
    banco: -1,
    noBoleta: "",
    observaciones: "",
    totalPagar: "",
    pagoCapital: "",
    pagoCapitalAnticipado: "",
    pagoInteres: "",
    pagoIva: "",
    pagoMora: "",
    pagoIvaMora: "",
    totalGastos: "",
    saldoTotalPagar: "",
    fechaProximoPago: "",
    diasMoraInteres: "",
    estadoCreditoFecha: "",
    cuotasAdelantadasEstePago: "",
    cuotasVencidasCubiertasPago: "",
    aux: "",
  };
  
  modeloPago.formaPago = modelo2.formaPago;
  modeloPago.banco = modelo2.banco;
  modeloPago.noBoleta = modelo2.noBoleta;
  modeloPago.observaciones = modelo2.observaciones;
  modeloPago.totalPagar = parseFloat(response.montoPago).toFixed(2);
  modeloPago.aux = parseFloat(response.pagoCapital).toFixed(2);
  modeloPago.diasMoraInteres = response.diasMoraInteres;
  modeloPago.estadoCreditoFecha = response.estadoCredito;
  modeloPago.fechaProximoPago = response.fechaProximoPago.split("T")[0];
  modeloPago.pagoCapital = parseFloat(response.pagoCapital).toFixed(2);
  modeloPago.pagoCapitalAnticipado = parseFloat(
    response.pagoCapitalAnticipado
  ).toFixed(2);
  modeloPago.pagoInteres = parseFloat(response.pagoIntereses).toFixed(2);
  modeloPago.pagoIva = parseFloat(response.pagoIvaIntereses).toFixed(2);
  modeloPago.pagoIvaMora = parseFloat(response.pagoIvaMora).toFixed(2);
  modeloPago.pagoMora = parseFloat(response.pagoMora).toFixed(2);
  modeloPago.saldoTotalPagar = parseFloat(response.saldoTotalPagar).toFixed(2);
  modeloPago.diasMoraInteres = response.diasMora;
  modeloPago.totalGastos = parseFloat(response.totalGastos).toFixed(2);
  modeloPago.montoTotal = parseFloat(response.montoPago).toFixed(2).toString();

  return modeloPago;
};

export const auxResponsePersona = (response: any) => {
  let persona: PersonDTO2 = {
    personaId: 0,
    primerNombre: "",
    segundoNombre: "",
    primerApellido: "",
    segundoApellido: "",
    apellidoCasada: "",
    dpi: "",
    direccion: "",
    colonia: "",
    numeroTelefono: "",
    numeroCelular: "",
    numeroTelefonoLaboral: "",
    nit: "",
    paisNacimientoId: "",
    departamentoId: 0,
    municipioId: "",
    estadoCivilId: 0,
    estadoPrestamo: "",
    asesorId: 0,
    empresaPlanillaId: 0,
    direccionLaboral: "",
    ocupacionId: 0,
    comentarios: "",
    tipoPrestamo: 0,
    email: "",
    fechaNacimiento: '',
    generoId: 0,
  };

  persona.primerNombre =
    response[0].primerNombre === null ? "" : response[0].primerNombre;
  persona.segundoNombre =
    response[0].segundoNombre === null ? "" : response[0].segundoNombre;
  persona.primerApellido =
    response[0].primerApellido === null ? "" : response[0].primerApellido;
  persona.segundoApellido =
    response[0].segundoApellido === null ? "" : response[0].segundoApellido;
  persona.colonia = response[0].colonia === null ? "" : response[0].colonia;
  persona.numeroCelular =
    response[0].numeroCelular === null ? "" : response[0].numeroCelular;
  persona.numeroTelefonoLaboral =
    response[0].numeroTelefonoLaboral === null
      ? ""
      : response[0].numeroTelefonoLaboral;
  persona.paisNacimientoId =
    response[0].paisNacimientoId === null
      ? ""
      : response[0].paisNacimientoId;
  persona.departamentoId =
    response[0].departamentoId === null ? "" : response[0].departamentoId;
  persona.municipioId =
    response[0].municipioId === null ? "" : response[0].municipioId;
  persona.comentarios =
    response[0].comentarios === null ? "" : response[0].comentarios;
  persona.ocupacionId =
    response[0].ocupacionId === null ? "" : response[0].ocupacionId;
  persona.personaId =
    response[0].personaId === null ? 0 : response[0].personaId;
  persona.direccion =
    response[0].direccion === null ? "" : response[0].direccion;
  persona.direccionLaboral =
    response[0].direccionLaboral === null ? "" : response[0].direccionLaboral;
  persona.dpi = response[0].dpi === null ? "" : response[0].dpi.trim();
  // persona.dpi = response[0].dpi === null ? "" : response[0].dpi.trim();
  persona.estadoPrestamo =
    response[0].estadoPrestamo === null ? "" : response[0].estadoPrestamo;
  persona.nit = response[0].nit === null ? "" : response[0].nit;
  persona.numeroTelefono =
    response[0].numeroTelefono === null ? "" : response[0].numeroTelefono;
  persona.email = !!!response[0].email ? "" : response[0].email;
  persona.fechaNacimiento = response[0].fechaNacimiento === null ? "":response[0].fechaNacimiento.split('T')[0];
  persona.estadoCivilId =
    response[0].estadoCivilId === null ? "" : response[0].estadoCivilId;
  persona.generoId = response[0].generoId;
  persona.empresaPlanillaId =
    response[0].empresaPlanillaId === null ? 0 : response[0].empresaPlanillaId;
  persona.asesorId = response[0].asesorId === null ? 0 : response[0].asesorId;
  persona.tipoPrestamo =
    response[0].tipoPrestamo === null ? 0 : response[0].tipoPrestamo;

  return persona;
};

export const putPersonaPayload = (valores: any) => {
  let aux: PersonDTOPut = {
    personId: valores.personaId,
    primerNombre: valores.primerNombre.toUpperCase().trim(),
    segundoNombre: valores.segundoNombre.toUpperCase().trim(),
    primerApellido: valores.primerApellido.toUpperCase().trim(),
    segundoApellido: valores.segundoApellido.toUpperCase().trim(),
    apellidoCasada: "",
    colonia: valores.colonia,
    comentarios: valores.comentarios,
    direccionLaboral: valores.direccionLaboral,
    fechaNacimiento: valores.fechaNacimiento,
    email: valores.email,
    municipioId: valores.municipioId,
    numeroCelular: valores.numeroCelular,
    numeroTelefonoLaboral: valores.numeroTelefonoLaboral,
    ocupacionId: parseInt(valores.ocupacionId),
    paisNacimientoId: valores.paisNacimientoId,
    numeroDocumento: valores.dpi,
    direccion: valores.direccion.toUpperCase().trim(),
    numeroTelefono: valores.numeroTelefono,
    generoId: parseInt(valores.generoId),
    nit: valores.nit,
    estadoCivilId: valores.estadoCivilId,
    tipoPrestamoId: valores.tipoPrestamo,
    tipoViviendaId: 1,
  };

  if (
    valores.empresaPlanillaId !== 0 &&
    valores.empresaPlanillaId !== -1 &&
    valores.empresaPlanillaId !== "-1"
  ) {
    aux.empresaPlanillaId = valores.empresaPlanillaId;
  }
  if (valores.asesorId !== 0) {
    aux.asesorId = valores.asesorId;
  }
  if (
    valores.departamentoId !== 0 &&
    valores.departamentoId !== null &&
    valores.departamentoId !== ""
  ) {
    aux.departamentoId = valores.departamentoId;
  } else {
    aux.departamentoId = 1;
    aux.municipioId = "101";
  }
  if (
    valores.ocupacionId !== 0 &&
    valores.ocupacionId !== null &&
    valores.ocupacionId !== ""
  ) {
    aux.ocupacionId = valores.ocupacionId;
  } else {
    aux.ocupacionId = 1;
  }
  return aux;
};

// Fechas reportes
export const cabeceraBatchReportObject = (object: any[]) => {
  let aux: any = [];
  object.forEach((element: any) => {
    aux.push({
      identificadorDeRelacion: element.identificadorDeRelacion,
      codigoMoneda: element.codigoMoneda,
      fechaHoraEmision: moment(element.fechaHoraEmision).format(
        "DD[/]MM[/]YYYY"
      ),
      exp: element.exp,
      numeroAcceso: element.numeroAcceso,
      tipo: element.tipo,
      afiliacionIVA: element.afiliacionIVA,
      codigoEstablecimiento: element.codigoEstablecimiento,
      correoEmisor: element.correoEmisor,
      nitEmisor: element.nitEmisor,
      nombreComercial: element.nombreComercial,
      nombreEmisor: element.nombreEmisor,
      emisorDireccion: element.emisorDireccion,
      emisorCodigoPostal: element.emisorCodigoPostal,
      emisorMunicipio: element.emisorMunicipio,
      emisorDepartamento: element.emisorDepartamento,
      emisorPais: element.emisorPais,
      idReceptor: element.idReceptor,
      nombreReceptor: element.nombreReceptor,
      correoReceptor: element.correoReceptor,
      tipoEspecial: element.tipoEspecial,
      receptorDireccion: element.receptorDireccion,
      receptorCodigoPostal: element.receptorCodigoPostal,
      receptorMunicipio: element.receptorMunicipio,
      receptorDepartamento: element.receptorDepartamento,
      receptorPais: element.receptorPais,
      granTotal: element.granTotal,
      identificador: element.identificador,
      tipoPersoneria: element.tipoPersoneria,
    });
  });

  return aux;
};

export const reporteContabilidad = (object: any[]) => {
  let aux: any = [];

  object.forEach((element: any) => {
    aux.push({
      creditoId: element.creditoId,
      referencia: element.referencia,
      tipoCredito: element.tipoCredito,
      nit: element.nit,
      nombre: element.nombre,
      // [ JLT ] se comentaron estos campos por solicitud de [ SF ]
      // motivoTransaccion: element.motivoTransaccion,
      // plazo: element.plazo,
      // [ JLT ] se agregaron los campos, banco, Fecha Boleta, # Documento por solicitud de [ SF ]
      banco: !!element.banco ? element.banco : "",
      fechaBoleta:  !!element.fechaBoleta ? moment(element.fechaBoleta).format(
        "DD[/]MM[/]YYYY"
      ) : "",
      "Ref Banco (Número de Boleta)": !!element.numeroDocumento ? element.numeroDocumento : "",
      fechaTransaccion: moment(element.fechaTransaccion).format(
        "DD[/]MM[/]YYYY"
      ),
      ejecutivo: element.ejecutivo,
      totalTransaccion: element.totalTransaccion,
      capital: element.capital,
      intereses: element.intereses,
      ivaIntereses: element.ivaIntereses,
      mora: element.mora,
      ivaMora: element.ivaMora,
      archivoBatch: element.archivoBatch,
    });
  });

  return aux;
};

export const dataReporteTransUnion = (object: any[]) => {
  let aux: any = [];

  object.forEach((element: any) => {
    aux.push({
      Tipo_Registro: element.tipo_Registro,
      Correlativo: element.correlativo,
      Tipo_Sujeto: element.tipo_Sujeto,
      Nacionalidad: element.nacionalidad,
      Nombre_Completo: element.nombre_Completo,
      Primer_Apellido: element.primerApellido,
      Segundo_Apellido: element.segundoApellido,
      Apellido_De_Casada: element.apellidoDeCasada,
      Primer_Nombre: element.primerNombre,
      Identificacion1: element.identificacion1,
      Identificacion2: element.identificacion2,
      Identificacion3: element.identificacion3,
      Identificacion4: element.identificacion4,
      Identificacion5: element.identificacion5,
      Fecha_Nacimiento: moment(element.fecha_Nacimiento).format(
        "DD[/]MM[/]YYYY"
      ),
      Sexo: element.sexo,
      DirResDivGeo1: element.dirResDivGeo1,
      DirResDivGeo2: element.dirResDivGeo2,
      Direccion_Laboral: element.direccion_Laboral,
      DirLabDivGeo1: element.dirLabDivGeo1,
      DirLabDivGeo2: element.dirLabDivGeo2,
      Direccion_Email: element.direccion_Email,
      Telefono_Residencial: element.telefono_Residencial,
      Telefono_Laboral: element.telefono_Laboral,
      Telefono_Celular: element.telefono_Celular,
      Tipo_Obligacion: element.tipo_Obligacion,
      Moneda: element.moneda,
      Identificador_TipoCuenta: element.identificador_TipoCuenta,
      Numero_Obligacion: element.numero_Obligacion,
      Fecha_De_Apertura: moment(element.fecha_De_Apertura).format(
        "DD[/]MM[/]YYYY"
      ),
      Fecha_De_Vencimiento: moment(element.fecha_De_Apertura).format(
        "DD[/]MM[/]YYYY"
      ),
      Periodo_Pago: element.periodo_Pago,
      Estado: element.estado,
      Sub_Estado: element.sub_Estado,
      Calificacion: element.calificacion,
      Dias_Mora: element.dias_Mora,
      Valor_Limite: element.valor_Limite,
      Valor_Saldo_Total: element.valor_Saldo_Total,
      Valor_Saldo_Mora: element.valor_Saldo_Mora,
      Valor_Cuota: element.valor_Cuota,
      Tipo_Deudor: element.tipo_Deudor,
      Tipo_Garantia: element.tipo_Garantia,
      Numero_Garantia: element.numero_Garantia,
      Valor_Garantia: element.valor_Garantia,
      Descripcion: element.descripcion,
      Tasa_Cambio: element.tasa_Cambio,
      Monto_Vencido: element.monto_Vencido,
      Monto_Pagado: element.monto_Pagado,
      Nuevo_Limite: element.nuevo_Limite,
      Fecha_De_Castigo: element.fecha_De_Castigo,
    });
  });
  return aux;
};
