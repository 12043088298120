import {
  faArrowRightArrowLeft,
  faCheckCircle,
  faClose,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Form, Formik, FormikHelpers } from "formik";
import React, { useEffect } from "react";
import {
  useAxiosSelectData,
  useAxiosSelectDataDescription,
} from "../../Hooks/useAxiosSelectData";
import { urlCatalogues } from "../../Utils/endpoints";
import FormGroupFechas from "../../Utils/FormGroupFechas";
import FormGroupSelect from "../../Utils/FormGroupSelect";
import FormGroupText2 from "../../Utils/FormGroupText2";
import FormGroupText3 from "../../Utils/FormGroupText3";
import { TagTwin } from "../../Utils/TagTwin";
import { Tooltip, Whisper } from "rsuite";
import Button from "../../Utils/Button";
import { OnlyNumber, OnlyNumberFloat } from "../../Utils/Validaciones";
import FormGroupText4 from "../../Utils/FormGroupText4";

const tooltip = <Tooltip>Distribuir Pago</Tooltip>;

export const FormAjustePago = (props: formRegistroPagoPropsInterface) => {
  const { data: bancoData, loading: bancoReady } =
    useAxiosSelectDataDescription(`${urlCatalogues}/ajuste_plan-pago`);

  useEffect(() => {}, [props.modelo]);

  return (
    <>
      <Formik
        initialValues={props.modelo}
        onSubmit={props.onSubmit}
        validationSchema={props.validationSchema}
        enableReinitialize
      >
        {(formikProps) => (
          <div className="container-fluid">
            {!bancoReady ? (
              <>
                <Form>
                  <div className="row">
                    <div className="col-10">
                      <TagTwin
                        col="col-12"
                        descriptionClass=" font-16"
                        label="Número de Crédito:"
                        labelResult={props.infoPerson.id}
                        titleClass="mr-4 font-weight-bold font-16"
                      ></TagTwin>
                      <TagTwin
                        col="col-12"
                        descriptionClass="font-16"
                        label="Nombre Cliente:"
                        labelResult={props.infoPerson.name}
                        titleClass="mr-4 font-weight-bold font-16"
                      ></TagTwin>
                      <TagTwin
                        col="col-12"
                        descriptionClass="font-16"
                        label="Caja: "
                        labelResult="General"
                        titleClass="mr-4 font-weight-bold font-16"
                      ></TagTwin>
                    </div>
                    <div className="col-2 justify-content-end align-items-center d-flex">
                      <Button type="submit" className="button-transparent">
                        <FontAwesomeIcon
                          className="mouse-pointer orange-icon ml-4"
                          icon={faCheckCircle}
                          style={{ height: "24px" }}
                        ></FontAwesomeIcon>
                      </Button>
                      <FontAwesomeIcon
                        className="mouse-pointer orange-icon ml-4"
                        icon={faClose}
                        onClick={() => props.handleClose()}
                        style={{ height: "24px" }}
                      ></FontAwesomeIcon>
                    </div>
                  </div>
                  <hr />
                  {/*  */}
                  <div className="row">
                    <div className="col-12 ">
                      <div className="row">
                        <div className="col-6">
                          <FormGroupSelect
                            campo="banco"
                            opciones={bancoData}
                            label="Tipo de Ajuste"
                            claseInput="form-control"
                            claseLabel="font-16"
                          ></FormGroupSelect>
                        </div>
                        <div className="col-6">
                          <FormGroupText3
                            campo="montoTotal"
                            clase="col-12"
                            claseLabelParent="col-12 font-weight-bold font-16"
                            label="Monto Aplicar"
                            changeValue={OnlyNumberFloat}
                          ></FormGroupText3>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-12 col-md-3">
                          <FormGroupFechas
                            campo="fecha"
                            clase=""
                            claseLabel="font-weight-bold font-16"
                            label="Fecha Ajuste"
                          ></FormGroupFechas>
                        </div>
                        <div className="col-12 col-md-9">
                          <FormGroupText2
                            campo="observaciones"
                            clase="col-12"
                            claseLabelParent="col-12 font-weight-bold font-16"
                            label="Observaciones"
                          ></FormGroupText2>
                        </div>
                      </div>
                    </div>
                  </div>
                </Form>
              </>
            ) : (
              <>
                <div className="text-center w-100 h-100">
                  <div
                    className="spinner-border"
                    style={{ width: "3rem", height: "3rem" }}
                    role="status"
                  ></div>
                  <span className="d-block color_blue size_type_font">
                    Cargando Formulario.
                  </span>
                </div>
              </>
            )}
          </div>
        )}
      </Formik>
    </>
  );
};

interface formRegistroPagoPropsInterface {
  modelo: any;
  handleClose(): void;
  onSubmit(valores: any, formikHelpers: FormikHelpers<any>): void;
  validationSchema?: any;
  infoPerson: any;
}
