import { faCheckCircle, faClose, faL } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ReportBts } from "./ReportBts";
import { Form, Formik, FormikHelpers } from "formik";
import FormGroupFechas from "../Utils/FormGroupFechas";
import Button from "../Utils/Button";

export const ReportModal = (props: reportModalPropsInterface) => {
  return (
    <>
      <div>
        <div className="container-fluid">
          <div className="row">
            <div className="col-12 justify-content-between d-flex">
              <h3>Reportes</h3>
              <FontAwesomeIcon
                className="mouse-pointer orange-icon ml-4"
                icon={faClose}
                onClick={() => props.handleCloseModal!()}
                style={{ height: "24px" }}
              ></FontAwesomeIcon>
            </div>
          </div>
          <hr />
          <div className="row">
            <button
              className="col-3 orange-dark-button"
              onClick={props.exportReporteBTS}
            >
              Reporte por Mora
            </button>
            <div className="col-1"></div>
            <button
              className="col-3 orange-dark-button"
              onClick={props.exportReporteGeneral}
            >
              General Cartera
            </button>
            <div className="col-1"></div>
            <button
              className="col-3 orange-dark-button"
              onClick={props.exportDataReport}
            >
              Archivo Batch
            </button>
          </div>
          <div className="row mt-3 pt-3 pb-3">
            <button
              className="col-3 orange-dark-button mb-2"
              onClick={props.changeShow}
            >
              Aplicación de Pagos
            </button>
            <div className={props.show}>
              <Formik
                initialValues={props.modelo}
                onSubmit={props.onSubmit}
                validationSchema={props.validationSchema}
                enableReinitialize
              >
                {(formikProps) => (
                  <div className="container-fluid border pt-1 pb-1">
                    <>
                      <Form>
                        <div className="d-flex">
                          <FormGroupFechas
                            campo="fechaInicio"
                            clase=""
                            claseLabel="font-weight-bold font-16"
                            label="Fecha Inicio"
                          ></FormGroupFechas>
                          <FormGroupFechas
                            campo="fechaFinal"
                            clase="ml-2 "
                            claseLabel="font-weight-bold font-16"
                            label="Fecha Final"
                          ></FormGroupFechas>
                          <Button
                            type="submit"
                            className="button-transparent d-flex align-items-end"
                          >
                            <FontAwesomeIcon
                              className="mouse-pointer orange-icon ml-4"
                              icon={faCheckCircle}
                              style={{ height: "24px" }}
                            ></FontAwesomeIcon>
                          </Button>
                        </div>
                      </Form>
                    </>
                  </div>
                )}
              </Formik>
            </div>
            <div className={props.show2}></div>

            <button
              className="col-3 orange-dark-button"
              onClick={props.exportReportTransunion}
            >
              Reporte Transunion
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

interface reportModalPropsInterface {
  handleCloseModal?(): void;
  exportReporteGeneral?(): void;
  exportReporteBTS?(): void;
  exportDataReport?(): void;
  exportReportTransunion?(): void;
  changeShow?(): void;
  modelo?: any;
  onSubmit(valores: any, formikHelpers: FormikHelpers<any>): void;
  validationSchema?: any;
  show?: string;
  show2?: string;
}
