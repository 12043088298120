import React from "react";
import ListadoGenerico from "../Utils/ListadoGenerico";

export default function TablaBuscarCliente(props: TablaBuscarClienteProps) {
  return (
    <div className="container_head_sticky">
      <table className="table table-borderless table-search">
        <thead className="header_sticky">
          <tr className="color_blue text-center">
            <th scope="col">ID</th>
            <th scope="col">Nombre</th>
            <th scope="col">Nit</th>
            <th scope="col">Teléfono</th>
            <th scope="col">Dirección</th>
          </tr>
        </thead>
        <ListadoGenerico
          listado={props.customer}
          cargandoUI={
            <>
              <tbody className="datosFichaPersona size_type_font">
                <tr>
                  <td className="text-center topLeft esquinasIzquierdas"></td>
                  <td className="text-center enmedio"></td>
                  <td className="enmedio d-flex justify-content-center">
                    Esperando respuesta.
                  </td>
                  <td className="text-center enmedio"></td>
                  <td className="topRight esquinasDerechas text-center"></td>
                </tr>
              </tbody>
            </>
          }
          listadoVacioUI={
            <>
              <tbody className="datosFichaPersona size_type_font">
                <tr>
                  <td className="text-center topLeft esquinasIzquierdas"></td>
                  <td className="text-center enmedio"></td>
                  <td className="enmedio d-flex justify-content-center">
                    0 resultados de la busqueda.
                  </td>
                  <td className="text-center enmedio"></td>
                  <td className="topRight esquinasDerechas text-center"></td>
                </tr>
              </tbody>
            </>
          }
        >
          <tbody className="datosFichaPersona size_type_font">
            {props.customer?.map((informacion, index) => (
              <tr key={index}>
                <td className="text-center topLeft esquinasIzquierdas">
                  {informacion.contactId}
                </td>
                <td className="enmedio text-center">{`${informacion.name}`}</td>
                <td className="text-center enmedio">{informacion.nit}</td>
                <td className="text-center enmedio">
                  {informacion.phoneNumber}
                </td>
                <td className="text-center enmedio">{informacion.address}</td>
              </tr>
            ))}
          </tbody>
        </ListadoGenerico>
      </table>
    </div>
  );
}

interface TablaBuscarClienteProps {
  customer: any[];
  goToPage(creditId: number): any;
}
