import axios, { AxiosResponse } from "axios";
import { selectData } from "../Interfaces/opciones.model";

export const GetDataSelect = async (enpoint: string) => {
  let data: selectData[] = [];

  await axios.get(enpoint).then((response: AxiosResponse<selectData[]>) => {
    data = response.data;
  });

  return data;
};
