import { useFormikContext } from "formik";
import MostrarErrorCampo from "./MostrarErrorCampo";

export default function FormGroupText2(props: FormGroupTextProps) {
  const { values, validateForm, touched, errors } = useFormikContext<any>();

  return (
    <>
      {props.label && (
        <div className={props.claseLabelParent}>
          <label htmlFor={props.campo} className={props.claseLabel}>
            {props.label}
          </label>
        </div>
      )}
      <div className={props.clase}>
        <input
          className={props.claseInput}
          value={props.value}
          readOnly={true}
        />
        {touched[props.campo] && errors[props.campo] ? (
          <MostrarErrorCampo mensaje={errors[props.campo]?.toString()!} />
        ) : null}
      </div>
    </>
  );
}

interface FormGroupTextProps {
  disable: boolean;
  clase: string;
  claseLabel: string;
  claseInput: string;
  claseLabelParent?: string;
  campo: string;
  label?: string;
  type: "text" | "password" | "email" | "textarea" | "number";
  placeHolder?: string;
  changeValue?: any;
  max?: number;
  min?: number;
  requerido?: boolean;
  readonly?: boolean;
  value?: string;
}

FormGroupText2.defaultProps = {
  type: "text",
  class: "form-group",
  claseLabel: "mb-2",
  claseInput: "form-control",
  disable: false,
  requerido: false,
};
