import axios from "axios";
import moment from "moment";
import { obtenerUserData } from "../Auth/manejadorJWT";
import { Currency } from "./ConvertValueToLocalValue";
import { urlLending } from "./endpoints";

export const getLending = async (url: string) => {
  try {
    const { data: x } = await axios.get(url);
    let aux: any = [];

    x.forEach((y: any) => {
      aux.push({
        credito: y.id,
        estatus: y.estatus,
        referenciaMigracion: y.referenciaMigracion,
        dpi: y.dpi,
        nombre: `${y.nombre}`,
        saldoInicial: Currency(parseFloat(y.saldoInicial)),
        saldoActual: Currency(parseFloat(y.saldoActual)),
        asesor: y.asesor,
      });
    });

    return aux;
  } catch (error: any) {
    return -1;
  }
};

export const postLending = async (payload: any) => {
  try {
    const { data: x } = await axios.post(urlLending, payload);

    return x;
  } catch (error: any) {
    return -1;
  }
};

export const postPetition = async (url: string, payload: any) => {
  try {
    const { data: x } = await axios.post(url, payload);

    return x;
  } catch (error: any) {
    return -1;
  }
};

export const putPetition = async (url: string, payload: any) => {
  try {
    const { data: x } = await axios.put(url, payload);

    return x;
  } catch (error: any) {
    return -1;
  }
};

export const getPetition = async (url: string) => {
  try {
    const { data: x } = await axios.get(url);

    return x;
  } catch (error: any) {
    return -1;
  }
};

export const getPlanPagoPetition = async (creditId: any) => {
  try {
    const { data: x } = await axios.get(`${urlLending}/plan_pago/${creditId}`);
    let auxPlanpago: any = [];
    x.forEach((element: any) => {
      auxPlanpago.push({
        aplicado: element.aplicado,
        cuotaCapital: element.cuotaCapital,
        cuotaGastosAdministrativos: element.cuotaGastos,
        cuotaIntereses: element.cuotaIntereses,
        cuotaIva: element.cuotaIvaIntereses,
        fechaPago: element.fechaPago,
        mes: element.periodo,
        prestamoId: element.prestamoId,
        saldo: element.saldoTotal,
        totalCuota: element.totalCuota,
      });
    });

    return auxPlanpago;
  } catch (error: any) {
    return -1;
  }
};

export const getHistorialPagoPetition = async (creditId: any) => {
  try {
    const { data: x } = await axios.get(
      `${urlLending}/historial_pago/${creditId}?appUserId=${
        obtenerUserData(4, localStorage.getItem("token")!)[0]
      }`
    );

    let aux: any = [];

    x.forEach((x: any) => {
      aux.push({
        capital: Currency(parseFloat(x.capital)),
        fechaTransaccion: moment(x.fechaTransaccion).format("DD[/]MM[/]YYYY"),
        formaPago: x.formaPago,
        id: x.id,
        intereses: Currency(parseFloat(x.intereses)),
        iva: Currency(parseFloat(x.iva)),
        gastos: Currency(parseFloat(x.gastos)),
        ivaGastos: Currency(parseFloat(x.ivaGastos)),
        mora: Currency(parseFloat(x.mora)),
        ivaMora: Currency(parseFloat(x.ivaMora)),
        montoPago: Currency(parseFloat(x.montoPago)),
        nombreBanco: x.nombreBanco,
        caja: x.caja,
        fechaRecibo: moment(x.fechaRecibo).format("DD[/]MM[/]YYYY"),
        nombreDeudor: x.nombreDeudor,
        nombreGestor: x.nombreGestor,
        nombreUsuario: x.nombreUsuario,
        reciboNo: x.reciboNo,
        tipoTranCapital: x.tipoTranCapital,
        tipoTranIntereses: x.tipoTranIntereses,
        tipoTranIvaIntereses: x.tipoTranIvaIntereses,
        tipoTransaccionGastos: x.tipoTransaccionGastos,
        tipoTransaccionIvaGastos:x.tipoTransaccionIvaGastos,
        tipoTransaccionIvaMora:x.tipoTransaccionIvaMora,
        tipoTransaccionMora:x.tipoTransaccionMora,

      });
    });

    return aux;
  } catch (error: any) {
    return -1;
  }
};

export const postPagoModal = async (
  valores: any,
  creditId: any,
  fechaPago: any
) => {
  try {
    if (valores.montoPago === "" || valores.montoPago === null) {
      return 100;
    } else if (
      valores.montoTotal === 0 ||
      valores.montoTotal === null ||
      valores.montoTotal === ""
    ) {
      return 200;
    } else if (
      valores.formaPago === "-1" ||
      valores.formaPago === -1 ||
      valores.formaPago === null ||
      valores.formaPago === undefined
    ) {
      return 300;
    } else if (
      valores.formaPago !== 2 &&
      (valores.noBoleta === "" || valores.noBoleta === null)
    ) {
      return 400;
    } else if (
      valores.formaPago !== 2 &&
      (valores.banco === "-1" ||
        valores.banco === -1 ||
        valores.banco === null ||
        valores.banco === undefined)
    ) {
      return 500;

    } else if(
      new Date(valores.fecha) > new Date()
    ){      
      return 600;
    }else if(parseFloat(valores.montoTotal) > parseFloat(valores.saldoTotalPagar)){
      return 700;
    }else{
      let payload = {
        appUserId: obtenerUserData(4, localStorage.getItem("token")!)[0],
        bancoId: valores.banco,
        cajaId: 1,
        formaPagoId: valores.formaPago,
        prestamoId: parseInt(creditId),
        fechaTransaccion: new Date(moment().format()),
        fechaPago: valores.fecha,
        numeroDocumento: valores.noBoleta,
        diasMora: valores.diasMoraInteres,
        montoPago: parseFloat(valores.totalPagar),
        montoCapital: valores.pagoCapital,
        montoInteres: valores.pagoInteres,
        montoIvaIntereses: valores.pagoIva,
        montoMora: valores.pagoMora,
        montoIvaMora: valores.pagoIvaMora,
        montoGastos: parseFloat(valores.totalGastos),
        montoIvaGastos: 0,
        montoCapitalAnticipado: valores.pagoCapitalAnticipado,
        cuotasAdelantadas: 0,
        cuotasVencidas: 0,
        totalCuotasVencidas: 0,
        observaciones: valores.observaciones,
        motivoAnulacion: "",
      };

      if (valores.formaPago === 2) {
        payload.bancoId = 4;
      }
      // console.log(payload);
      const { data: x } = await axios.post(
        `${urlLending}/registro-pago`,
        payload
      );

      return x;
    }
  } catch (error: any) {
    return -1;
  }
};
