import { useFormikContext } from "formik";
import React from "react";
import MostrarErrorCampo from "./MostrarErrorCampo";

export default function FormGroupSelectAnidado(
  props: FormGroupSelectAnidadoProps
) {
  const { values, validateForm, touched, errors } = useFormikContext<any>();

  return (
    <>
      <div className={props.classDivPadre}>
        <label htmlFor={props.campo} className={props.classLabel}>
          {props.labelText}
        </label>
        <select
          className={props.classInput}
          onChange={(e) => {
            props.onChange(e);
            values[props.campo] = parseInt(e.target.value);
            validateForm();
          }}
          value={values[props.campo]}
        >
          <option value={-1}>Seleccione...</option>
          {props.information.map((info) => (
            <option value={info.id} key={info.id}>
              {info.nombre}
            </option>
          ))}
        </select>
        {touched[props.campo] && errors[props.campo] ? (
          <MostrarErrorCampo mensaje={errors[props.campo]?.toString()!} />
        ) : null}
      </div>
    </>
  );
}

interface FormGroupSelectAnidadoProps {
  classDivPadre: string;
  classLabel: string;
  classInput: string;
  campo: string;
  labelText: string;
  onChange(e: any): void;
  information: any[];
}
