import React, { useRef } from "react";
import { Currency } from "../Utils/ConvertValueToLocalValue";
import ListadoGenerico from "../Utils/ListadoGenerico";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faClose } from "@fortawesome/free-solid-svg-icons";
import { useDownloadExcel } from "react-export-table-to-excel";
import moment from "moment";

export const TablePlanPagoAplicado = (props: tablePlanPagoInterface) => {
  const tableRef = useRef(null);

  const { onDownload } = useDownloadExcel({
    currentTableRef: tableRef.current,
    filename: `Plan de Pago ${props.nombre}`,
    sheet: "plan",
  });

  return (
    <>
      <div className="container-fluid">
        <div className="row">
          <div className="col-12 justify-content-between d-flex">
            <h3>
              Plan de Pago{" "}
              <button
                onClick={onDownload}
                className="ml-3 btn only-orange-button"
              >
                {" "}
                Exportar a Excel{" "}
              </button>
            </h3>
            <FontAwesomeIcon
              className="mouse-pointer orange-icon ml-4"
              icon={faClose}
              onClick={() => props.handleCloseModal!()}
              style={{ height: "24px" }}
            ></FontAwesomeIcon>
          </div>
        </div>
        <hr />
        <div className="table-responsive mt-3 container_head_sticky mb-4">
          <table
            className="table table-bordered text-center"
            rules="all"
            ref={tableRef}
          >
            <thead className="header_sticky">
              <tr>
                <th scope="col">MES</th>
                <th scope="col">CUOTA CAPITAL</th>
                <th scope="col">CUOTA INTERES</th>
                <th scope="col">CUOTA GASTOS ADMINISTRATIVOS</th>
                <th scope="col">CUOTA IVA</th>
                <th scope="col">TOTAL CUOTA FIJA</th>
                <th scope="col">SALDO</th>
                <th scope="col">FECHA PAGO</th>
                <th scope="col">APLICADO</th>
              </tr>
            </thead>
            <ListadoGenerico
              listado={props.data}
              cargandoUI={
                <>
                  <tbody className="size_type_font tabla_directorio_body">
                    <tr>
                      <td colSpan={8}>Esperando respuesta.</td>
                    </tr>
                  </tbody>
                </>
              }
              listadoVacioUI={
                <>
                  <tbody className="datosFichaPersona size_type_font">
                    <tr>
                      <td colSpan={8}>Sin datos de plan de pago</td>
                    </tr>
                  </tbody>
                </>
              }
            >
              <tbody>
                {props.data?.map((x, index) => (
                  <tr key={index}>
                    <td>{x.mes}</td>
                    <td>{Currency(parseFloat(x.cuotaCapital))}</td>
                    <td>{Currency(parseFloat(x.cuotaIntereses))}</td>
                    <td>
                      {Currency(parseFloat(x.cuotaGastosAdministrativos))}
                    </td>
                    <td>{Currency(parseFloat(x.cuotaIva))}</td>
                    <td>{Currency(parseFloat(x.totalCuota))}</td>
                    <td>{Currency(parseFloat(x.saldo))}</td>
                    <td>{moment(x.fechaPago).format("DD[/]MM[/]YYYY")}</td>
                    <td>
                      {x.aplicado && (
                        // <FontAwesomeIcon
                        //   icon={faCheck}
                        //   className="pl-2 pr-2 orange-icon  font-24"
                        // />
                        <p className="orange-icon">&#10004;</p>
                      )}
                    </td>
                  </tr>
                ))}
              </tbody>
            </ListadoGenerico>
          </table>
        </div>
      </div>
    </>
  );
};

interface tablePlanPagoInterface {
  data: any[];
  handleCloseModal?(): void;
  nombre?: string;
}
