import axios, { AxiosError, AxiosResponse } from "axios";
import { FormikHelpers } from "formik";
import React, { useEffect, useState } from "react";
import { useLocation, useParams } from "react-router-dom";
import Swal from "sweetalert2";
import Button from "../../Utils/Button";
import { urlLending } from "../../Utils/endpoints";
import { TagTwin } from "../../Utils/TagTwin";
import { TasasModificacion } from "./TasasModificacion";

export const PrestamoDetalle = (props: prestamoDetallePropsInterface) => {
  const location = useLocation();
  const [show, setShow] = useState(false);

  useEffect(() => {
    location.pathname.split("/")[1] === "prestamo" ||
    location.pathname.split("/")[1] === "prestamoCompany"
      ? setShow(true)
      : setShow(false);
  }, []);

  const { creditId }: any = useParams();

  const liquidar = async() => {

    const response = await Swal.fire(
      {
          title: `¿Está segur@ de realizar la siguiente acción?`,
          showDenyButton: true,
          confirmButtonText: 'Aceptar',
          denyButtonText: `Cancelar`,
      })

  if(!response.isConfirmed) return;

    axios
      .get(`${urlLending}/liquidar_credito/${creditId}`)
      .then((response: AxiosResponse) => {
        Swal.fire({
          title: `Acción Realizada`,
          text: `Acción Realizada.`,
          icon: "success",
        });
      })
      .catch((response: AxiosError) => {
        Swal.fire({
          title: `Error`,
          text: "No se pudo Realizar la acción",
          icon: "warning",
        });
      });
  };

  return (
    <>
      <div className="row">
        <div className="col-12 col-sm-3">
          <div className="row font-16">
            {show ? (
              <TagTwin
                col="col-12 mt-2 mb-2"
                descriptionClass="col-6"
                titleClass="col-4 font-weight-bold"
                label="Monto Solicitado"
                labelResult={`${props.modelo.montoTotal}`}
              ></TagTwin>
            ) : (
              <>
                <TagTwin
                  col="col-12 mt-2 mb-2"
                  descriptionClass="col-6"
                  titleClass="col-4 font-weight-bold"
                  label="Deuda Total"
                  labelResult={`${props.modelo.montoTotal}`}
                ></TagTwin>
                <TagTwin
                  col="col-12 mb-2"
                  descriptionClass="col-6"
                  titleClass=" col-4 font-weight-bold"
                  label="Saldo Capital"
                  labelResult={`${props.modelo.saldoCapital}`}
                ></TagTwin>
                <TagTwin
                  col="col-12 mb-2"
                  descriptionClass="col-6"
                  titleClass="col-4 font-weight-bold"
                  label="Saldo Intereses"
                  labelResult={`${props.modelo.saldoIntereses}`}
                ></TagTwin>
                <TagTwin
                  col="col-12 mb-2"
                  descriptionClass="col-6"
                  titleClass="col-4 font-weight-bold"
                  label="Saldo IVA Interes"
                  labelResult={`${props.modelo.saldoIva}`}
                ></TagTwin>
                <TagTwin
                  col="col-12 mb-2"
                  descriptionClass="col-6"
                  titleClass="col-4 font-weight-bold"
                  label="Saldo Mora"
                  labelResult={`${props.modelo.saldoAdministrativo}`}
                ></TagTwin>
                <TagTwin
                  col="col-12 mb-2"
                  descriptionClass="col-6"
                  titleClass="col-4 font-weight-bold"
                  label="Saldo IVA Mora"
                  labelResult={`${props.modelo.saldoIvaMora}`}
                ></TagTwin>
                <br />
                <br />
                <TagTwin
                  col="col-12 mb-2"
                  descriptionClass="col-6"
                  titleClass="col-4  font-weight-bold"
                  label="Días de mora"
                  labelResult={`${props.valor}`}
                ></TagTwin>
              </>
            )}
          </div>
        </div>
        <div className="col-12 col-sm-2 mt-2">
        {!show && 
            <Button className="orange-dark-button" onClick={() => liquidar()}>
              Liquidar Crédito por Ampliación
            </Button>          
          }
        </div>
        <div className="col-12 col-sm-7 pr-5">
          <TasasModificacion
            readonly={props.readonly}
            click={props.click!}
            modelo={props.modeloTasas}
            validationSchema={props.validationSchema}
          ></TasasModificacion>
        </div>
      </div>
    </>
  );
};

interface prestamoDetallePropsInterface {
  modelo: any;
  modeloTasas?: any;
  readonly?: boolean;
  validationSchema?: any;

  click?(valores: any, acciones: FormikHelpers<any>): void;
  valor?: any;
  changeValor?(valor: any): void;
}
