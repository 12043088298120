
export default function MostrarErrores(props: MostrarErroresProps) {
    const style = { color: "red" };
  
    return (
      <>
        {props.errores ? (
          <ul style={style}>
            {props.errores.map((errores, indice) => (
              <li key={indice}>{errores}</li>
            ))}
          </ul>
        ) : "Error desconocido."}
      </>
    );
  }
  
  interface MostrarErroresProps {
    errores?: string[];
  }
  