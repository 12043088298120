import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faClose,
  faPowerOff,
  faUserAlt,
} from "@fortawesome/free-solid-svg-icons";
import { useHistory, useLocation } from "react-router-dom";
import axios, { AxiosError, AxiosResponse } from "axios";
import { urlLogin } from "../Utils/endpoints";
import { logout, obtenerUserData } from "../Auth/manejadorJWT";
import AutentificacionContext from "../Auth/AutenticacionContext";
import { useContext, useEffect, useState } from "react";

export default function NavBar(props: navBarInterface) {
  const history = useHistory();
  const { actualizar, claims } = useContext(AutentificacionContext);
  const [userName, setUserName] = useState("");
  const rutaActiva = useLocation();

  const cerrarSesion = () => {
    logout();
    actualizar([]);
    history.push("/login");

    // axios
    //   .post(`${urlLogin}/logout`)
    //   .then((response: AxiosResponse) => {
    //     logout();
    //     actualizar([]);
    //     history.push("/login");
    //   })
    //   .catch((error: AxiosError) => {
    //     console.log(error.response);
    //     console.log(error);
    //   });
  };

  useEffect(() => {
    if (!localStorage.getItem("token")) {
      history.replace("/login");
    } else {
      let user = obtenerUserData(1, localStorage.getItem("token")!)[0].split(
        "@"
      )[0];
      setUserName(user);
    }
  }, []);

  const backLocation = () => {
    if (rutaActiva.pathname.split("/")[1] === "EditPerson") {
      history.goBack();
    } else {
      history.push(`/home`);
    }
  };

  return (
    <>
      <div className="d-flex justify-content-between align-items-center w-100 pl-4 pr-4 pt-4 mb-4">
        <img
          src={require("../Assets/AmigoPaq-Logo.png")}
          alt=""
          className="logoMenu mouse-pointer"
        />

        <div className="user">
          {props.show && (
            <>
              <FontAwesomeIcon
                icon={faUserAlt}
                className="pl-2 pr-2 orange-icon mouse-pointer font-24"
              />
              <span className="pl-2 pr-2 font-24">{userName}</span>
              <FontAwesomeIcon
                icon={faPowerOff}
                className="pl-2 pr-2 orange-icon mouse-pointer font-24"
                onClick={cerrarSesion}
              />
            </>
          )}

          {!props.show && (
            <>
              <FontAwesomeIcon
                icon={faClose}
                className="pl-2 pr-2 orange-icon mouse-pointer font-24"
                onClick={backLocation}
              ></FontAwesomeIcon>
            </>
          )}
        </div>
      </div>

      {!props.show && (
        <>
          <hr />
        </>
      )}
    </>
  );
}

interface navBarInterface {
  show: boolean;
}
