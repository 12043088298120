import {
  faBuilding,
  faPerson,
  faPrint,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import axios, { AxiosError, AxiosResponse } from "axios";
import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import { urlCatalogues } from "../Utils/endpoints";
import { FormSearchInList } from "../Utils/FormSearchInList";
import { ReturnResponseInList } from "../Utils/ReturnResponseSearchList";
import ModalFullScreenForm from "./ModalFullScreen";
import { ReportModal } from "./ReportModal";
import TablaBuscarCliente from "./TablaBuscarCliente";
import { utils, writeFileXLSX } from "xlsx";
import moment from "moment";
import {
  cabeceraBatchReportObject,
  dataReporteTransUnion,
  reporteContabilidad,
} from "../Utils/CreateObjects";
import * as Yup from "yup";
import Swal from 'sweetalert2';

export const LargeMenu = () => {
  const history = useHistory();
  const [customerData, setcustomerData] = useState<[]>();
  const [searchCustomer, setsearchCustomer] = useState<[]>();
  const [auxiliar, setAuxiliar] = useState<[]>();
  const [openSearchModal, setOpenSearchModal] = useState(false);
  const [openReportModal, setOpenReportModal] = useState(false);
  const [typeClick, setTypeClick] = useState(0);
  const [loading, setLoading] = useState(false);

  const handleOpenSearchModal = () => setOpenSearchModal(true);
  const handleCloseSearchModal = () => {
    setsearchCustomer(undefined);
    setAuxiliar(undefined);
    setOpenSearchModal(false);
  };

  const handleCloseReportModal = () => {
    setOpenReportModal(false);
    setShow("d-none col-9");
    setShow2("col-1");
    setModeloReporteConta({
      fechaInicio: new Date(),
      fechaFinal: new Date(),
    });
  };

  const redirectTo = () => {
    switch (typeClick) {
      case 1:
        history.push(`newPrestamo`);
        setTypeClick(0);
        break;
      case 2:
        history.push(`newCompanyPrestamo`);
        setTypeClick(0);
        break;
    }
  };

  const OpenSearchModal = async (url: string, type: number) => {
    setTypeClick(type);
    handleOpenSearchModal();
    // const response = await GetPeopleWhoAreNotEmployee(url);
    // if(response === -1){
    //     setsearchCustomer([])
    //     setAuxiliar([])
    //     return;
    // }
    setsearchCustomer([]);
    setAuxiliar([]);
  };

  const handleOpenReportModal = () => {
    setOpenReportModal(true);
  };

  const goToPage = (creditId: number) => {
    switch (typeClick) {
      case 1:
        break;
      case 2:
        break;
    }
  };

  const searchInObject = (list: any, filter: string, typePress: number) => {
    const listaDeDatos = list;
    if (listaDeDatos !== undefined) {
      switch (typePress) {
        case 1:
          if (filter.length > 0) {
            const response = ReturnResponseInList(listaDeDatos, filter);
            setcustomerData(response);
          } else {
            setcustomerData(listaDeDatos);
          }
          break;
        case 2:
          if (filter.length > 0) {
            const response = ReturnResponseInList(listaDeDatos, filter);
            setsearchCustomer(response);
          } else if (filter.length === 0) {
            setsearchCustomer(listaDeDatos);
          }
          break;
      }
    }
  };

  //
  const exportReporteGeneral = () => {
    setLoading(true);
    axios
      .get(`${urlCatalogues}/reporte_general`)
      .then((response: AxiosResponse) => {
        setLoading(false);
        const ws = utils.json_to_sheet(response.data);
        const wb = utils.book_new();
        utils.book_append_sheet(wb, ws, "Reporte General");
        writeFileXLSX(wb, "reporteGeneral.xlsx");
      })
      .catch((error: any) =>{
        setLoading(false);
        Swal.fire("Alerta","Comuníquese con soporte.","warning");
      });
  };

  const exportReporteBTS = () => {
    setLoading(true);
    axios
      .get(`${urlCatalogues}/reporte_CasosBTS`)
      .then((response: AxiosResponse) => {
        let aux: any = [];
        response.data.forEach((element: any) => {
          aux.push({
            idPrestamo: element.idPrestamo,
            referencia: element.referencia,
            nombre: element.nombre,
            gestor: element.gestor,
            montoOtorgado: element.montoOtorgado,
            tasaInteres: element.tasaInteres,
            plazo: element.plazo,
            deudaTotal: element.deudaTotal,
            interesProyectado: element.interesProyectado,
            ivaProyectado: element.ivaProyectado,
            diasMora: element.diasMora,
            saldoCapital: element.saldoCapital,
            saldoIntereses: element.saldoIntereses,
            saldoIvaIntereses: element.saldoIvaIntereses,
            saldoMora: element.saldoMora,
            saldoIvaMora: element.saldoIvaMora,
            estado: element.estado,
            cuotaCalculada: element.cuotaCalculada,
            fechaAprobacion: moment(element.fechaAprobacion).format(
              "DD[/]MM[/]YYYY"
            ),
            fechaDesembolso: moment(element.fechaDesembolso).format(
              "DD[/]MM[/]YYYY"
            ),
            proximoPago: moment(element.proximoPago).format("DD[/]MM[/]YYYY"),
            fechaPrimerPago: moment(element.fechaPrimerPago).format(
              "DD[/]MM[/]YYYY"
            ),
            fechaVencimiento: moment(element.fechaVencimiento).format(
              "DD[/]MM[/]YYYY"
            ),
            pagaduría: element.pagaduría,
            tipoPrestamo: element.tipoPrestamo,
          });
        });
        setLoading(false);

        const ws = utils.json_to_sheet(aux);
        const wb = utils.book_new();
        utils.book_append_sheet(wb, ws, "Reporte Casos BTS");
        writeFileXLSX(wb, "reporteBTS.xlsx");
      })
      .catch((error: any) =>{
        setLoading(false);
        Swal.fire("Alerta","Comuníquese con soporte.","warning");
      });
  };

  const exportDataReport = () => {
    setLoading(true);
    axios
      .get(`${urlCatalogues}/generacion_archivo_batch`)
      .then((response: AxiosResponse) => {
        let aux1: any,
          aux2: any,
          aux3: any,
          aux4: any = [];
        aux1 = cabeceraBatchReportObject(response.data.encabezado);
        setLoading(false);
        const ws = utils.json_to_sheet(aux1);
        const ws2 = utils.json_to_sheet(response.data.detalle);
        const ws3 = utils.json_to_sheet(response.data.totalImpuestos);
        const ws4 = utils.json_to_sheet(response.data.frases);
        const wb = utils.book_new();
        utils.book_append_sheet(wb, ws, "CABECERA");
        utils.book_append_sheet(wb, ws2, "DETALLE");
        utils.book_append_sheet(wb, ws3, "TOTAL IMPUESTOS");
        utils.book_append_sheet(wb, ws4, "FRASES");
        writeFileXLSX(wb, "archivoBatch.xlsx");
      }).catch((error: any) =>{
        setLoading(false);
        Swal.fire("Alerta","Comuníquese con soporte.","warning");
      });
  };

  const exportReportTransunion = () => {
    setLoading(true);
    axios
      .get(`${urlCatalogues}/generacion_reporte_transunion`)
      .then((response: AxiosResponse) => {
        let aux: any = [];
        aux = dataReporteTransUnion(response.data.reporte);
        const ws = utils.json_to_sheet(aux);
        const wb = utils.book_new();
        utils.book_append_sheet(wb, ws, "Reporte General");
        writeFileXLSX(wb, "ReporteTransUnion.xlsx");
        setLoading(false);
      })
      .catch((error: any) =>{
        setLoading(false);
        Swal.fire("Alerta","Comuníquese con soporte.","warning");
      });
  };

  // Reporte conta
  const [show, setShow] = useState("d-none col-9");
  const [show2, setShow2] = useState("col-1");
  let modelo = {
    fechaInicio: new Date(),
    fechaFinal: new Date(),
  };
  const [modeloReporteConta, setModeloReporteConta] = useState(modelo);

  const validationSchema = Yup.object({
    fechaInicio: Yup.date().required("Campo Requerido"),
    fechaFinal: Yup.date().required("Campo Requerido"),
  });

  const changeShow = () => {
    setShow("d-block col-9");
    setShow2("d-none");
  };

  const onSubmit = (valores: any) => {
    setLoading(true);
    let aux = [];
    axios
      .get(
        `${urlCatalogues}/reporte_contabilidad?fechaInicio=${moment(
          valores.fechaInicio
        ).format("YYYY[-]MM[-]DD")}&fechaFinal=${moment(
          valores.fechaFinal
        ).format("YYYY[-]MM[-]DD")}`
      )
      .then((response: AxiosResponse) => {
        aux = reporteContabilidad(response.data);
        setShow("d-none col-9");
        setShow2("col-1");
        setModeloReporteConta(modelo);
        setLoading(false);
        setModeloReporteConta({
          fechaInicio: valores.fechaInicio,
          fechaFinal: valores.fechaFinal,
        });
        const ws = utils.json_to_sheet(aux);
        const wb = utils.book_new();
        utils.book_append_sheet(wb, ws, "Reporte");
        writeFileXLSX(wb, "reporteContabilidad.xlsx");
      }).catch((error: any) =>{
        setLoading(false);
        Swal.fire("Alerta","Comuníquese con soporte.","warning");
      });
  };

  return (
    <div>
      <div className="d-flex w-100 largeMenu justify-content-sm-between flex-wrap">
        <div className="largeMenu-option d-flex align-items-center ml-2 mt-1 font-weight-bold  font-24  mouse-pointer ">
          Cartera
          <FontAwesomeIcon
            icon={faPerson}
            className="ml-2 mr-2 white-icon"
            onClick={() => history.push(`newPrestamo`)}
            // onClick={() => OpenSearchModal(``, 1)}
          />
          <FontAwesomeIcon
            icon={faBuilding}
            className="ml-2 mr-2 white-icon"
            onClick={() => history.push(`newCompanyPrestamo`)}
            // onClick={() => OpenSearchModal(``, 2)}
          />
        </div>
        <div
          className="largeMenu-option d-flex align-items-center ml-2 mt-1 font-weight-bold  font-24  mouse-pointer  white-icon"
          onClick={() => handleOpenReportModal()}
        >
          Reportes
          <FontAwesomeIcon icon={faPrint} className="ml-2 mr-2" />
        </div>
      </div>
      {/* Modal para busqueda */}
      <ModalFullScreenForm
        childrenBody={
          <FormSearchInList
            handleClose={handleCloseSearchModal}
            redirecToPage={() => redirectTo()}
            onSubmit={(x: any) => searchInObject(auxiliar, x.name.trim(), 2)}
            tabla={
              <TablaBuscarCliente
                customer={searchCustomer!}
                goToPage={goToPage}
              />
            }
          />
        }
        handleClose={handleCloseSearchModal}
        open={openSearchModal}
        size="lg"
      ></ModalFullScreenForm>
      {/* Modal Reportes */}
      <ModalFullScreenForm
        childrenBody={
          <ReportModal
            handleCloseModal={handleCloseReportModal}
            exportReporteBTS={exportReporteBTS}
            exportReporteGeneral={exportReporteGeneral}
            exportDataReport={exportDataReport}
            exportReportTransunion={exportReportTransunion}
            changeShow={changeShow}
            onSubmit={onSubmit}
            modelo={modeloReporteConta}
            validationSchema={validationSchema}
            show={show}
            show2={show2}
          ></ReportModal>
        }
        handleClose={handleCloseReportModal}
        open={openReportModal}
        size="md"
      ></ModalFullScreenForm>
      {loading && (
        <div className="loginLoading">
          <div className="row justify-content-center mt-5">
            <div className="d-flex align-items-center">
              <strong>Espere un momento por favor...</strong>
              <div
                className="spinner-border ml-auto"
                role="status"
                aria-hidden="true"
              ></div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};
