import React, { ReactElement } from "react";
import Button from "./Button";
import { Form, Formik } from "formik";
import FormGroupText from "./FormGroupText";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCircleXmark,
  faClose,
  faPlusCircle,
} from "@fortawesome/free-solid-svg-icons";

export const FormSearchInList = (props: FormSearchInListProps) => {
  return (
    <>
      <div className="container-fluid">
        <Formik initialValues={{ name: "" }} onSubmit={props.onSubmit}>
          {(formikProps) => (
            <Form className="animate__animated animate__fadeIn">
              <div className="row mb-3 justify-content-between">
                <span
                  className="color_blue font-weight-bold  font-24"
                  style={{ fontSize: "1.8rem" }}
                >
                  Buscar
                </span>
                <FontAwesomeIcon
                  icon={faCircleXmark}
                  className="mouse-pointer orange-icon"
                  style={{ height: "32px" }}
                  onClick={props.handleClose}
                />
              </div>
              <hr />
              <div className="row">
                <FormGroupText
                  label="Nombre / NIT / Teléfono"
                  campo="name"
                  clase="form-group col-lg-6 col-xl-9"
                  claseLabel="color_blue size_type_font"
                  claseInput="form-control input_fondo font-24"
                />
                <div className="d-flex justify-content-end align-content-center pt-4 pb-3 col-lg-12 col-xl-3 pt-1">
                  <Button type="submit" className="orange-dark-button">
                    Buscar
                  </Button>
                </div>
              </div>
              <div className="row mt-5 mb-2">
                <div className="col-6">
                  <span
                    className="color_blue size_type_font"
                    style={{ fontSize: "1.5rem" }}
                  >
                    Resultados
                  </span>
                </div>
                <div className="col-6 d-flex justify-content-end mt-2">
                  <FontAwesomeIcon
                    icon={faPlusCircle}
                    className="mouse-pointer orange-icon"
                    style={{ height: "32px" }}
                    onClick={props.redirecToPage}
                  />
                </div>
                <div className="col-12 mt-3">{props.tabla}</div>
              </div>
            </Form>
          )}
        </Formik>
      </div>
    </>
  );
};

interface FormSearchInListProps {
  handleClose: any;
  redirecToPage: any;
  onSubmit: any;
  tabla: ReactElement;
}
