import { Table } from "rsuite";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPrint } from "@fortawesome/free-solid-svg-icons";
import { useReactToPrint } from "react-to-print";
import { useEffect, useRef, useState } from "react";
import { ReciboPrint } from "./ReciboPrint";
import ModalFullScreenForm from "./ModalFullScreen";
import { ModalNoFactura } from "./ModalNoFactura";
const { Column, HeaderCell, Cell } = Table;

export const TableEstadoCuenta = (props: tableEstadoCuentaPropsInterface) => {
  let styleVaraibles = { background: "var(--naranja)", color: "var(--blanco)" };
  const componentRef = useRef(null);

  let rowDataPrint = {
    reciboNo: 0,
    nombreDeudor: "",
    nombreUsuario: "",
    nombreGestor: "",
    fechaRecibo: "",
    caja: "",
    fechaTransaccion: "",
    tipoTranCapital: 0,
    capital: 0,
    tipoTranIntereses: 0,
    intereses: 0,
    tipoTranIvaIntereses: 0,
    iva: 0,
    mora:0,
    tipoTransaccionMora:0,
    ivaMora:0,
    tipoTransaccionIvaMora:0,
    gastos:0,
    tipoTransaccionGastos:0,
    ivaGastos:0,
    tipoTransaccionIvaGastos:0,
    montoPago: 0,
    formaPago: "",
    nombreBanco: "",
    id: "",
    noFac:''
  };

  const modelo = { numero:''}

  const [dataPrint, setDataPrint] = useState<any>(rowDataPrint);
  const [openModal, setOpenModal] = useState(false);
  const handleCloseModal = () => {
    setOpenModal(false);
  }
  
  const obtainRow = (rowData: any) => {
    rowDataPrint = rowData;
    setDataPrint(rowDataPrint);
    // setOpenModal(true);
  };
  
  let noFac = ''
  const [factura, setFactura] = useState<any>(noFac)
  const submit =  (valores:any) =>{
    noFac = valores.numero;
    // rowDataPrint.noFac = valores.numero;
    // setDataPrint(rowDataPrint);
    setFactura(valores.numero)
    handleCloseModal()
    // handlePrint();
  }


  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
    documentTitle: "new Document",
    pageStyle: "print",
  });

  // useEffect(() => {
  //   if (factura !== '') {
  //     handlePrint()
  //   }
  
  // }, [factura])
  
   useEffect(() => {
    if (dataPrint.reciboNo !== 0) {
      handlePrint();
    }
  }, [dataPrint]);

  return (
    <div>
      <Table
        height={400}
        data={props.data}
        className="mx-auto tabla-home font-16"
        rowClassName={"row-table"}
        cellBordered
        loading={props.loading}
        //   onRowClick={(rowData: any) => {
        //     history.push(`/prestamo/${rowData.credito}`);
        //   }}
      >
        <Column flexGrow={1} align="center" fixed minWidth={90}>
          <HeaderCell
            className="font-16 font-weight-bold"
            style={styleVaraibles}
          >
            Fecha de Pago
          </HeaderCell>
          <Cell dataKey="fechaTransaccion" />
        </Column>
        <Column flexGrow={1} minWidth={100}>
          <HeaderCell
            className="font-16 font-weight-bold"
            style={styleVaraibles}
          >
            Capital
          </HeaderCell>
          <Cell dataKey="capital" />
        </Column>
        <Column flexGrow={1} minWidth={100}>
          <HeaderCell
            className="font-16 font-weight-bold"
            style={styleVaraibles}
          >
            Intereses
          </HeaderCell>
          <Cell dataKey="intereses" />
        </Column>
        <Column flexGrow={1} minWidth={100}>
          <HeaderCell
            className="font-16 font-weight-bold"
            style={styleVaraibles}
          >
            IVA
          </HeaderCell>
          <Cell dataKey="iva" />
        </Column>
        <Column flexGrow={1} minWidth={100}>
          <HeaderCell
            className="font-16 font-weight-bold"
            style={styleVaraibles}
          >
            Mora
          </HeaderCell>
          <Cell dataKey="mora" />
        </Column>
        <Column flexGrow={1} minWidth={100}>
          <HeaderCell
            className="font-16 font-weight-bold"
            style={styleVaraibles}
          >
            Iva Mora
          </HeaderCell>
          <Cell dataKey="ivaMora" />
        </Column>
        <Column flexGrow={1} minWidth={100}>
          <HeaderCell
            className="font-16 font-weight-bold"
            style={styleVaraibles}
          >
            Gastos Administrativos
          </HeaderCell>
          <Cell dataKey="gastos" />
        </Column>
        <Column flexGrow={1} minWidth={100}>
          <HeaderCell
            className="font-16 font-weight-bold"
            style={styleVaraibles}
          >
            Iva Gastos Administrativos
          </HeaderCell>
          <Cell dataKey="ivaGastos" />
        </Column>
        <Column flexGrow={1} minWidth={100}>
          <HeaderCell
            className="font-16 font-weight-bold"
            style={styleVaraibles}
          >
            Monto Pago
          </HeaderCell>
          <Cell dataKey="montoPago" />
        </Column>
        <Column flexGrow={1} minWidth={100}>
          <HeaderCell
            className="font-16 font-weight-bold"
            style={styleVaraibles}
          >
            Forma de Pago
          </HeaderCell>
          <Cell dataKey="formaPago" />
        </Column>
        <Column flexGrow={1} minWidth={100}>
          <HeaderCell
            className="font-16 font-weight-bold"
            style={styleVaraibles}
          >
            Banco
          </HeaderCell>
          <Cell dataKey="nombreBanco" />
        </Column>
        <Column flexGrow={1} minWidth={100}>
          <HeaderCell
            className="font-16 font-weight-bold"
            style={styleVaraibles}
          >
            Archivo
          </HeaderCell>
          <Cell>
            {(rowData) => (
              <span onClick={() => obtainRow(rowData)}>
                {/*  */}
                <FontAwesomeIcon
                  className="mouse-pointer orange-icon ml-4"
                  icon={faPrint}
                ></FontAwesomeIcon>
              </span>
            )}
          </Cell>
        </Column>
      </Table>
      <ReciboPrint
        printReference={componentRef}
        data={dataPrint}
        key={dataPrint.reciboNo}
        factura={factura}
      ></ReciboPrint>
    {/* MODAL NUMERO DE FACTURA */}
    <ModalFullScreenForm
        childrenBody={
          <ModalNoFactura onSubmit={submit} handleCloseModal={handleCloseModal} modelo={modelo} ></ModalNoFactura>
        }
        handleClose={handleCloseModal}
        open={openModal}
        size="sm"
      ></ModalFullScreenForm>
    </div>
  );
};

interface tableEstadoCuentaPropsInterface {
  data: any[];
  loading: boolean;
}

interface int {
  reciboNo: number;
  nombreDeudor: string;
  nombreUsuario: string;
  nombreGestor: string;
  fechaRecibo: string;
  caja: string;
  fechaTransaccion: string;
  tipoTranCapital: number;
  capital: number;
  tipoTranIntereses: number;
  intereses: number;
  tipoTranIvaIntereses: number;
  iva: number;
  montoPago: number;
  formaPago: string;
  nombreBanco: string;
  id: string;
}
