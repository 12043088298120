import axios, { AxiosResponse } from "axios";
import { useContext, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { loginInterface } from "../Interfaces/login.model";
import { urlLogin } from "../Utils/endpoints";
import AutentificacionContext from "./AutenticacionContext";
import { respuestaAutenticacion } from "./Auth.model";
import FormAuth from "./FormAuth";
import {
  guardarTokenLocalStorage,
  obtenerClaims,
  obtenerToken,
} from "./manejadorJWT";

export default function Login() {
  const [loading, setLoading] = useState(false);
  const history = useHistory();
  const { actualizar } = useContext(AutentificacionContext);
  const [errores, setErrores] = useState<string[]>([]);

  useEffect(() => {
    if (obtenerToken()) {
      history.replace("/home");
    }
  }, []);

  const modelo: loginInterface = {
    email: "",
    password: "",
  };

  const login = async (values: loginInterface) => {
    setLoading(true);
    await axios
      .post<respuestaAutenticacion>(`${urlLogin}/login`, values)
      .then((response: AxiosResponse) => {
        setLoading(false);
        guardarTokenLocalStorage(response.data);
        actualizar(obtenerClaims());
        history.push("/home");
      })
      .catch((error: AxiosResponse) => {
        setErrores(["Login Incorrecto"]);
        setLoading(false);
        console.log(error.request);
      });
  };

  return (
    <>
      <div className="d-flex align-items-center justify-content-center fondo">
        <FormAuth
          modelo={modelo}
          onSubmit={async (valores) => await login(valores)}
          error={errores}
        ></FormAuth>
        {loading && (
          <div className="loginLoading">
            <div className="row justify-content-center mt-5">
              <div className="d-flex align-items-center">
                <strong>Cargando ...</strong>
                <div
                  className="spinner-border ml-auto"
                  role="status"
                  aria-hidden="true"
                ></div>
              </div>
            </div>
          </div>
        )}
      </div>
    </>
  );
}
