import moment from "moment";
import React from "react";
import { Currency } from "../Utils/ConvertValueToLocalValue";
import ListadoGenerico from "../Utils/ListadoGenerico";

export const TableDetallePrestamo = (props: tableDetallePrestamoInterface) => {
  return (
    <>
      <div className="table-responsive mt-3 container_head_sticky mb-4">
        <table className="table table-bordered text-center" rules="all">
          <thead className="header_sticky">
            <tr>
              <th scope="col">MES</th>
              <th scope="col">CUOTA CAPITAL</th>
              <th scope="col">CUOTA INTERES</th>
              <th scope="col">CUOTA GASTOS ADMINISTRATIVOS</th>
              <th scope="col">CUOTA IVA</th>
              <th scope="col">TOTAL CUOTA FIJA</th>
              <th scope="col">SALDO</th>
              <th scope="col">FECHA PAGO</th>
            </tr>
          </thead>
          <ListadoGenerico
            listado={props.data}
            cargandoUI={
              <>
                <tbody className="size_type_font tabla_directorio_body">
                  <tr>
                    <td colSpan={8}>Esperando respuesta.</td>
                  </tr>
                </tbody>
              </>
            }
            listadoVacioUI={
              <>
                <tbody className="datosFichaPersona size_type_font">
                  <tr>
                    <td colSpan={8}>Sin datos de plan de pago</td>
                  </tr>
                </tbody>
              </>
            }
          >
            <tbody>
              {props.data?.map((x, index) => (
                <tr key={index}>
                  <td>{x.mes}</td>
                  <td>{Currency(parseFloat(x.cuotaCapital))}</td>
                  <td>{Currency(parseFloat(x.cuotaIntereses))}</td>
                  <td>{Currency(parseFloat(x.cuotaGastosAdministrativos))}</td>
                  <td>{Currency(parseFloat(x.cuotaIvaIntereses))}</td>
                  <td>{Currency(parseFloat(x.totalCuota))}</td>
                  <td>{Currency(parseFloat(x.saldoCapital))}</td>
                  <td>{moment(x.fechaPago).format("DD[/]MM[/]YYYY")}</td>
                </tr>
              ))}
            </tbody>
          </ListadoGenerico>
        </table>
      </div>
    </>
  );
};

interface tableDetallePrestamoInterface {
  data: any[];
}
