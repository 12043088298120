import { faCheckCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Form, Formik, FormikHelpers } from "formik";
import Button from "../../Utils/Button";
import FormGroupSelect from "../../Utils/FormGroupSelect";
import FormGroupSelect2 from "../../Utils/FormGroupSelect2";
import FormGroupText2 from "../../Utils/FormGroupText2";
import FormGroupFechas from "../../Utils/FormGroupFechas";
import FormSelectSuite from "../../Utils/FormSelectSuite";
import FormGroupSelectAnidado from "../../Utils/FormGroupSelectAnidado";
import {
  OnlyLetters,
  OnlyNumber,
  ValidateEmail,
} from "../../Utils/Validaciones";

export const FormEditPerson = (props: FormEditPersonPropsInterface) => {
  const genero = [
    { id: 1, nombre: "Masculino" },
    { id: 2, nombre: "Femenino" },
  ];

  return (
    <>
      <Formik
        initialValues={props.modelo}
        onSubmit={props.onSubmit}
        validationSchema={props.schema}
      >
        {(formikProps) => (
          <Form>
            <div className="container-fluid">
              <div className="row d-flex justify-content-between pl-5 pr-2">
                <h3>Editar Ficha</h3>
                <Button type="submit" className="button-transparent">
                  <FontAwesomeIcon
                    className="mouse-pointer orange-icon ml-4"
                    icon={faCheckCircle}
                    style={{ height: "2rem" }}
                  ></FontAwesomeIcon>
                </Button>
              </div>
              <div className="row mt-2 mb-2  pl-5 pr-2">
                <h4 className="font-weight-bold">
                  Código: {props.modelo.personaId}
                </h4>
              </div>
              <div className="row  pl-2 pr-2 mb-2 mt-2">
                <div className="col-3">
                  <FormGroupText2
                    campo="primerNombre"
                    clase="col-12"
                    claseLabelParent="col-12 font-weight-bold"
                    label="Primer Nombre"
                    type="text"
                    changeValue={OnlyLetters}
                  ></FormGroupText2>
                </div>
                <div className="col-3">
                  <FormGroupText2
                    campo="segundoNombre"
                    clase="col-12"
                    claseLabelParent="col-12 font-weight-bold"
                    label="Segundo Nombre"
                    type="text"
                    changeValue={OnlyLetters}
                  ></FormGroupText2>
                </div>
                <div className="col-3">
                  <FormGroupText2
                    campo="primerApellido"
                    clase="col-12"
                    claseLabelParent="col-12 font-weight-bold"
                    label="Primer Apellido"
                    type="text"
                    changeValue={OnlyLetters}
                  ></FormGroupText2>
                </div>
                <div className="col-3">
                  <FormGroupText2
                    campo="segundoApellido"
                    clase="col-12"
                    claseLabelParent="col-12 font-weight-bold"
                    label="Segundo Apellido"
                    type="text"
                    changeValue={OnlyLetters}
                  ></FormGroupText2>
                </div>
              </div>
              <div className="row  pl-2 pr-2 mb-2 mt-2">
                <div className="col-3">
                  <FormGroupText2
                    campo="dpi"
                    clase="col-12 "
                    claseLabelParent="col-2 font-weight-bold"
                    label="DPI"
                    type="text"
                    changeValue={OnlyNumber}
                  ></FormGroupText2>
                </div>
                <div className="col-3">
                  <FormGroupText2
                    campo="nit"
                    clase="col-12 "
                    claseLabelParent="col-12 font-weight-bold"
                    label="NIT"
                    type="text"
                  ></FormGroupText2>
                </div>
                <div className="col-3">
                  <FormGroupSelect
                    campo="paisNacimientoId"
                    opciones={props.pais}
                    label="Pais de Nacimiento"
                    className="ml-3 mr-3"
                    claseInput="form-control"
                    claseLabel="font-16 font-weight-bold"
                  ></FormGroupSelect>
                </div>
                <div className="col-12 col-sm-3">
                  <FormGroupSelect
                    campo="generoId"
                    opciones={genero}
                    label="Genero"
                    className="ml-3 mr-3"
                    claseInput="form-control"
                    claseLabel="font-16"
                  ></FormGroupSelect>
                </div>
                {/* <div className="col-3">
                  <FormGroupSelect2
                    campo="asesorId"
                    opciones={props.gestores}
                    label="Genero"
                    className="ml-3"
                    claseInput="form-control"
                    claseLabel="font-16 font-weight-bold"
                  ></FormGroupSelect2>
                </div> */}
              </div>
              <div className="row  pl-2 pr-2 mb-2 mt-2">
                <div className="col-3">
                  <FormGroupText2
                    campo="numeroTelefono"
                    clase="col-12 "
                    claseLabelParent="col-12 font-weight-bold"
                    label="Numero de Teléfono"
                    type="text"
                    changeValue={OnlyNumber}
                  ></FormGroupText2>
                </div>
                <div className="col-3">
                  <FormGroupText2
                    campo="numeroCelular"
                    clase="col-12 "
                    claseLabelParent="col-2 font-weight-bold"
                    label="Celular"
                    type="text"
                    changeValue={OnlyNumber}
                  ></FormGroupText2>
                </div>
                <div className="col-3">
                  <FormGroupText2
                    campo="numeroTelefonoLaboral"
                    clase="col-12 "
                    claseLabelParent="col-12 font-weight-bold"
                    label="Teléfono Laboral"
                    type="text"
                    changeValue={OnlyNumber}
                  ></FormGroupText2>
                </div>
                <div className="col-3">
                  <FormGroupText2
                    campo="email"
                    clase="col-12 "
                    claseLabelParent="col-12 font-weight-bold"
                    label="Correo Electronico"
                    type="email"
                    changeValue={ValidateEmail}
                  ></FormGroupText2>
                </div>
              </div>
              <div className="row  pl-2 pr-2 mb-2 mt-2">
                <div className="col-12 col-sm-3">
                  <FormGroupFechas
                    campo="fechaNacimiento"
                    clase="ml-3 mr-3"
                    claseInput="form-control text-center"
                    claseLabel="mr-4"
                    label="Fecha de Nacimiento"
                  ></FormGroupFechas>
                </div>
                <div className="col-3">
                  <FormGroupText2
                    campo="direccion"
                    clase="col-12"
                    claseLabelParent="col-2 font-weight-bold"
                    label="Dirección"
                    type="text"
                  ></FormGroupText2>
                </div>
                <div className="col-3">
                  <FormGroupText2
                    campo="colonia"
                    clase="col-12"
                    claseLabelParent="col-2 font-weight-bold"
                    label="Colonia"
                    type="text"
                  ></FormGroupText2>
                </div>
                <div className="col-3">
                  <FormGroupSelectAnidado
                    classDivPadre={"ml-3 mr-3"}
                    classLabel={"font-16 font-weight-bold"}
                    classInput={"form-control"}
                    campo={"departamentoId"}
                    labelText={"Departamento"}
                    onChange={(e: any) => props.handleChangeDep!(e)}
                    information={props.depto!}
                  ></FormGroupSelectAnidado>
                </div>
              </div>
              <div className="row  pl-2 pr-2 mb-2 mt-2">
                <div className="col-3">
                  <FormGroupSelect
                    campo="municipioId"
                    opciones={props.muni}
                    label="Municipio"
                    className="ml-3 mr-3"
                    claseInput="form-control"
                    claseLabel="font-16 font-weight-bold"
                  ></FormGroupSelect>
                </div>
                {/* <div className="col-3">
                  <FormGroupSelect2
                    campo="asesorId"
                    opciones={props.tipoV}
                    label="Tipo de Vivienda"
                    className="ml-3"
                    claseInput="form-control"
                    claseLabel="font-16 font-weight-bold"
                  ></FormGroupSelect2>
                </div> */}
                <div className="col-3">
                  <FormGroupSelect
                    campo="estadoCivilId"
                    opciones={props.estadoCivil}
                    label="Estado Civil"
                    className="ml-3 mr-3"
                    claseInput="form-control"
                    claseLabel="font-16 font-weight-bold"
                  ></FormGroupSelect>
                </div>
                <div className="col-3">
                  <FormGroupSelect
                    campo="ocupacionId"
                    opciones={props.ocupacion}
                    label="Ocupación"
                    className="ml-3 mr-3"
                    claseInput="form-control"
                    claseLabel="font-16 font-weight-bold"
                  ></FormGroupSelect>
                </div>
              </div>
              <div className="row  pl-2 pr-2 mb-2 mt-2">
                <div className="col-6">
                  <FormGroupText2
                    campo="comentarios"
                    clase="col-12"
                    claseLabelParent="col-2 font-weight-bold"
                    label="Comentarios"
                    type="text"
                  ></FormGroupText2>
                </div>
                <div className="col-6">
                  <FormGroupText2
                    campo="direccionLaboral"
                    clase="col-12"
                    claseLabelParent="col-12 font-weight-bold"
                    label="Dirección Laboral"
                    type="text"
                  ></FormGroupText2>
                </div>
              </div>

              <div className="row  pl-2 pr-2 mb-2 mt-2">
                <div className="col-4">
                  <FormGroupSelect2
                    campo="asesorId"
                    opciones={props.gestores}
                    label="Gestor"
                    className="ml-3"
                    claseInput="form-control"
                    claseLabel="font-16 font-weight-bold"
                  ></FormGroupSelect2>
                </div>
                <div className="col-4">
                  <FormGroupSelect2
                    campo="empresaPlanillaId"
                    opciones={props.emprsaPlanilla}
                    label="Empresa Planilla "
                    className="ml-3"
                    claseInput="form-control"
                    claseLabel="font-16 font-weight-bold"
                  ></FormGroupSelect2>
                </div>
                <div className="col-4">
                  <FormGroupSelect
                    campo="tipoPrestamo"
                    opciones={props.tipoPrestamo}
                    label="Tipo de préstamo"
                    className="ml-3"
                    claseInput="form-control"
                    claseLabel="font-16 font-weight-bold"
                  ></FormGroupSelect>
                </div>
              </div>
            </div>
          </Form>
        )}
      </Formik>
    </>
  );
};

interface FormEditPersonPropsInterface {
  modelo: any;
  schema: any;
  onSubmit(valores: any, acciones: FormikHelpers<any>): void;
  estadoCivil?: any[];
  gestores?: any[];
  emprsaPlanilla?: any[];
  tipoPrestamo?: any[];
  ocupacion?: any[];
  depto?: any[];
  muni?: any[];
  pais?: any[];
  tipoV?: any[];
  departmentIdSelected?: any;
  setDepartmentIdSelected?: any;
  handleChangeDep?(e: any): void;
}
