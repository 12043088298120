import { ErrorMessage, Field, useFormikContext } from "formik";
import MostrarErrorCampo from "./MostrarErrorCampo";

export default function FormGroupSelect2(props: FormGroupSelectProps) {
  const { values, validateForm, touched, errors } = useFormikContext<any>();

  return (
    <div className={props.className}>
      {props.label && (
        <label htmlFor={props.campo} className={props.claseLabel}>
          {props.label}
        </label>
      )}
      <Field
        as="select"
        name={props.campo}
        className={props.claseInput}
        disabled={props.disable}
      >
        <option value={-1}>Seleccione...</option>
        {props.opciones &&
          props.opciones.map((elemento) => (
            <option key={elemento.entidadId} value={elemento.entidadId}>
              {elemento.nombre}
            </option>
          ))}
      </Field>
      <ErrorMessage name={props.campo}>
        {(mensaje) => <MostrarErrorCampo mensaje={mensaje} />}
      </ErrorMessage>
    </div>
  );
}

interface FormGroupSelectProps {
  disable: boolean;
  label?: string;
  campo: string;
  className: string;
  claseLabel: string;
  claseInput: string;
  opciones?: any[];
}

FormGroupSelect2.defaultProps = {
  className: "form-group",
  claseInput: "form-control",
  claseLabel: "mb-2",
  disable: false,
};
