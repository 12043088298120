import React, { useRef } from "react";
import { Currency } from "../Utils/ConvertValueToLocalValue";
import ListadoGenerico from "../Utils/ListadoGenerico";
import moment from "moment";

export const TableConsultaEstadoCuentaPrint = (
  props: tableEstadoCuentaInterface
) => {
  return (
    <>
      <div className="print-source" ref={props.printReference}>
        <div className="print-recibo-header">
          <div>
            <h1>
              <b>Estado de Cuenta</b>
            </h1>
            <br />
            <span>
              <b>{props.tipoPrestamo}</b>
            </span>
            <br />
            <span>
              <b>Nombre:</b> {props.prestamoNombre}
            </span>
            <br />
            <span>
              <b>NIT: {props.nit}</b>
            </span>
          </div>
          <img src={require("../Assets/AmigoPaq-Logo.png")} alt="" />
        </div>
        <div className="container-fluid">
          <div className="mt-3 mb-4">
            <table className="table table-bordered text-center">
              <thead className="">
                <tr>
                  <th scope="col">FECHA</th>
                  <th scope="col">TRANSACCIÓN</th>
                  <th scope="col">CONCEPTO</th>
                  <th scope="col">CARGO</th>
                  <th scope="col">ABONO</th>
                  <th scope="col">SALDO ACTUAL</th>
                </tr>
              </thead>
              <ListadoGenerico
                listado={props.data}
                cargandoUI={
                  <>
                    <tbody className="size_type_font tabla_directorio_body">
                      <tr>
                        <td colSpan={8}>Esperando respuesta.</td>
                      </tr>
                    </tbody>
                  </>
                }
                listadoVacioUI={
                  <>
                    <tbody className="datosFichaPersona size_type_font">
                      <tr>
                        <td colSpan={8}>Sin datos del Estado de Cuenta</td>
                      </tr>
                    </tbody>
                  </>
                }
              >
                <tbody>
                  {props.data?.map((x, index) => (
                    <tr key={index}>
                      <td>
                        {moment(x.fechaCreacion).format("DD[/]MM[/]YYYY")}
                      </td>
                      <td>{x.tipoTransaccionId}</td>
                      <td>{x.concepto}</td>
                      <td>{Currency(parseFloat(x.cargo))}</td>
                      <td>{Currency(parseFloat(x.abono))}</td>
                      <td>{Currency(parseFloat(x.saldoActual))}</td>
                    </tr>
                  ))}
                </tbody>
              </ListadoGenerico>
            </table>
          </div>
        </div>
      </div>
    </>
  );
};

interface tableEstadoCuentaInterface {
  data: any[];
  printReference?: any;
  prestamoNombre?: string;
  tipoPrestamo?: string;
  nit?: string;
  handleCloseModal?(): void;
}
