import { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { LargeMenu } from "../../Components/LargeMenu";
import NavBar from "../../Components/Navbar";
import { TableHome } from "../../Components/TableHome";
import { urlLending } from "../../Utils/endpoints";
import { FormInputSearch } from "../../Utils/FormInputSearch";
import {
  ReturnResponseListThreeCampos,
} from "../../Utils/searchFilterTable";
import { getLending } from "../../Utils/ServicesPetitions";

export default function Home() {
  const [dataTable, setDataTable] = useState<any[]>([]);
  const [dataAux, setDataAux] = useState<any[]>([]);
  const [loading, setLoading] = useState(true);
  const history = useHistory();

  const lendingHome = async () => {
    const data = await getLending(urlLending);

    if (data === -1) return;
    // console.log(data);
    setDataTable(data);
    setDataAux(data);
    setLoading(false);
  };

  useEffect(() => {
    lendingHome();
  }, []);

  const search = (array: any, filter: string) => {
    const arrayList = array;
    if (arrayList !== undefined) {
      if (filter.length > 0) {
        // const respuesta = ReturnResponseInList(arrayList, filter);
        const respuesta = ReturnResponseListThreeCampos(arrayList, filter);
        setDataTable(respuesta);
      } else {
        setDataTable(arrayList);
      }
    }
  };

  const redireccion = (valores: any) => {
    // console.log(valores);
    if (valores.dpi === "" || valores.dpi === null) {
      if (valores.estatus === "En Proceso" || valores.estatus === "EN PROCESO") {
        history.push(`/prestamoCompany/${valores.credito}`);
      } else if (valores.estatus === "Activo" || valores.estatus === "ACTIVO") {
        history.push(`/prestamoCompanyDetail/${valores.credito}`);
      }
    } else {
      if (valores.estatus === "En Proceso" || valores.estatus === "EN PROCESO") {
        history.push(`/prestamo/${valores.credito}`);
      } else if (
        valores.estatus === "Activo" ||
        valores.estatus === "ACTIVO" ||
        valores.estatus === "Cancelado por migración" ||
        valores.estatus === "Liquidado por Recrédito" ||
        valores.estatus === "Cancelado" ||
        valores.estatus === "Planillero X" ||
        valores.estatus === "Cartera Saneada"
      ) {
        history.push(`/prestamoDetail/${valores.credito}`);
      }
    }
  };

  return (
    <>
      <NavBar show></NavBar>

      <LargeMenu></LargeMenu>

      <div className="container-fluid mt-3 mb-2">
        <div className="row justify-content-end ">
          <div className="col-12 col-sm-4">
            <FormInputSearch
              onSubmit={(x: any) => search(dataAux, x.name.trim())}
            ></FormInputSearch>
          </div>
        </div>
      </div>
      <div className="container-fluid">
        <TableHome
          data={dataTable}
          loading={loading}
          onClick={redireccion}
        ></TableHome>
      </div>
    </>
  );
}
