import React from "react";

export const TagTwin = (props: TagTwinProps) => {
  return (
    <div className={props.col}>
      <div className="row">
        <span className={props.titleClass}>{props.label}</span>
        <span className={props.descriptionClass}>{props.labelResult}</span>
      </div>
    </div>
  );
};

interface TagTwinProps {
  col: string;
  titleClass: string;
  descriptionClass: string;
  label: string;
  labelResult: string;
}
