export const ReturnResponseInList = (aux: any,filter: string) =>{
    const newData = aux.filter(function(item: any){
        let campo = ""
        for(let clave in item){
            campo += item[clave] !== null && item[clave].toString().toUpperCase()+" ";
        }
        return campo.indexOf(filter.toString().toUpperCase()) > -1
    })
    if(newData.length === 0){
        return []
    }else{
        return newData;
    }
}

export const ReturnResponseInListComplicated = (aux: any,filter: string) =>{
    const newData = aux.filter(function(item: any){
        let campo = "";
        let campo2 = "";
        for(let clave in item){
            if(typeof item[clave] === "object"){
                for(let clave2 in item[clave]){
                    campo2 += (item[clave])[clave2] !== null && (item[clave])[clave2].toString().toUpperCase()+" ";
                }
            }else{
                campo += item[clave] !== null && item[clave].toString().toUpperCase()+" ";
            }
        }
        campo += campo2;
        return campo.indexOf(filter.toString().toUpperCase()) > -1
    })
    if(newData.length === 0){
        return []
    }else{
        return newData;
    }
}