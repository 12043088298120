import { claim, respuestaAutenticacion } from "./Auth.model";

const llaveToken = "token";
const llaveExpiracion = "token-expiracion";
const llaveCompanyId = "token-companyId";

export function guardarTokenLocalStorage(
  autenticacion: respuestaAutenticacion
) {
  localStorage.setItem(llaveToken, autenticacion.token);
  localStorage.setItem(llaveExpiracion, autenticacion.expiration.toString());
}

export function obtenerClaims(): claim[] {
  const token = localStorage.getItem(llaveToken);

  if (!token) {
    return [];
  }

  const expiracion = localStorage.getItem(llaveExpiracion)!;
  const expiracionFecha = new Date(expiracion);

  if (expiracionFecha <= new Date()) {
    logout();
    return [];
  }

  const dataToken = JSON.parse(atob(token.split(".")[1]));
  const respuesta: claim[] = [];

  for (const propiedad in dataToken) {
    respuesta.push({ nombre: propiedad, valor: dataToken[propiedad] });
  }

  return respuesta;
}

export function logout() {
  localStorage.clear();
  // localStorage.removeItem(llaveToken);
  // localStorage.removeItem(llaveExpiracion);
  // localStorage.removeItem(llaveCompanyId);
  // localStorage.removeItem('NameProject');
  // localStorage.removeItem('idExpediente');
  // localStorage.removeItem('idFolder');
  // localStorage.removeItem('idProject');
  // localStorage.removeItem('clientName');
  // localStorage.removeItem('idRFP');
  // localStorage.removeItem('id');
  // localStorage.removeItem('name');
  // localStorage.removeItem('internalTaskFolderId');
  // localStorage.removeItem('ProviderName');
  // localStorage.removeItem('internalTaskFolder');
  // localStorage.removeItem('projectFolder');
  // localStorage.removeItem('client');
}

export function obtenerToken() {
  return localStorage.getItem(llaveToken);
}

export function obtenerUserInfo(info: string) {
  const token = obtenerToken();
  if (!token) {
    return [];
  }
  const dataToken = JSON.parse(atob(token.split(".")[1]));
  const respuesta = [];
  for (const propiedad in dataToken) {
    respuesta.push({ nombre: propiedad, valor: dataToken[propiedad] });
  }
  return respuesta.filter((x) => x.nombre === info)[0]?.valor;
}

export function obtenerUserData(option: number, token: string) {
  const dataToken = JSON.parse(atob(token.split(".")[1]));
  const respuesta = [];
  let optionSelected = [];
  for (const propiedad in dataToken) {
    respuesta.push({ nombre: propiedad, valor: dataToken[propiedad] });
  }

  switch (option) {
    case 1:
      optionSelected.push(
        respuesta.filter((x) => x.nombre === "UserName")[0]?.valor
      );
      break;
    case 2:
      optionSelected.push(
        respuesta.filter((x) => x.nombre === "SessionName")[0]?.valor
      );
      break;
    case 3:
      optionSelected.push(
        respuesta.filter((x) => x.nombre === "CompanyId")[0]?.valor
      );
      break;
    case 4:
      optionSelected.push(
        respuesta.filter((x) => x.nombre === "UserId")[0]?.valor
      );
      break;
    case 5:
      optionSelected.push(
        respuesta.filter((x) => x.nombre === "ContactId")[0]?.valor
      );
      break;
    case 6:
      optionSelected.push(
        respuesta.filter((x) => x.nombre === "exp")[0]?.valor
      );
      break;
  }

  return optionSelected;
}

export function separateToken() {
  const token = obtenerToken();
  if (!token) {
    return [];
  }

  const dataToken = token.split(".")[1];
  return [dataToken, obtenerUserData(4, token)[0]];
}

export function obtenerCompanyId(info: string) {
  const token = obtenerToken();
  if (!token) {
    return [];
  }
  const dataToken = JSON.parse(atob(token.split(".")[1]));
  localStorage.setItem(llaveCompanyId, dataToken.CompanyId);
  return true;
}
