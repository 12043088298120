import { Form, Formik, FormikHelpers } from "formik";
import { loginInterface } from "../Interfaces/login.model";
import FormGroupText from "../Utils/FormGroupText";
import * as Yup from "yup";
import Button from "../Utils/Button";
import { Link, useHistory } from "react-router-dom";
import MostrarErrores from "../Utils/MostrarErrores";

export default function FormAuth(props: formAuthProps) {
  const history = useHistory();

  return (
    <>
      <Formik
        initialValues={props.modelo}
        onSubmit={props.onSubmit}
        validationSchema={Yup.object({
          email: Yup.string().required("Campo Requerido"),
          password: Yup.string().required("Campo Requerido"),
        })}
      >
        {(formikProps) => (
          <div className="form-container">
            <div className="row d-flex flex-md-row flex-column">
              <div className="col-12 col-md-4 d-flex align-items-center justify-content-center">
                <img
                  src={require("../Assets/AmigoPaq-Logo.png")}
                  alt="logo sin fin"
                  className="imgLogo"
                />
              </div>
              <div className="col-12 col-md-8">
                <Form>
                  <div className="form-group">
                    <FormGroupText
                      campo="email"
                      clase=""
                      label="Usuario"
                    ></FormGroupText>
                  </div>
                  <div className="form-group">
                    <FormGroupText
                      campo="password"
                      clase=""
                      label="Contraseña"
                      type="password"
                    ></FormGroupText>
                    <small className="text-center">
                      <Link to={"/home"} className="recoverPassword">
                        Recuperar contraseña
                      </Link>
                      <div className="row justify-content-center">
                        <MostrarErrores errores={props.error} />
                      </div>
                    </small>
                  </div>
                  <div className="form-group">
                    <Button
                      children="Ingresar"
                      className="orange-button"
                      type="submit"
                    ></Button>
                  </div>
                </Form>
              </div>
            </div>
          </div>
        )}
      </Formik>
    </>
  );
}

interface formAuthProps {
  modelo: loginInterface;
  onSubmit(
    valores: loginInterface,
    acciones: FormikHelpers<loginInterface>
  ): void;
  error?: string[];
}
