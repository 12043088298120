import React, { useEffect, useState } from "react";
import Navbar from "../../Components/Navbar";
import { useAxiosSelectData } from "../../Hooks/useAxiosSelectData";
import { urlCatalogues, urlLending } from "../../Utils/endpoints";
import { FormEditPerson } from "./FormEditPerson";
import * as Yup from "yup";
import { PersonDTO, PersonDTO2 } from "../../Interfaces/lending.model";
import { getPetition, putPetition } from "../../Utils/ServicesPetitions";
import { useHistory, useParams } from "react-router-dom";
import {
  auxResponsePersona,
  putPersonaPayload,
} from "../../Utils/CreateObjects";
import Swal from "sweetalert2";
import { GetDataSelectSearch } from "../../Helpers/GetDataSelectSearch";
import axios, { AxiosResponse } from "axios";

export const EditPerson = () => {
  const { data: estadoCivilData, loading: estadoCivilReady } =
    useAxiosSelectData(`${urlCatalogues}/estado-civil`);
  const { data: gestoresData, loading: gestoresReady } = useAxiosSelectData(
    `${urlCatalogues}/gestores`
  );
  const { data: empresaPlanillaData, loading: empresaPlanillaReady } =
    useAxiosSelectData(`${urlCatalogues}/empresaPlanilla`);
  const { data: tipoPrestamoData, loading: tipoPrestamoReady } =
    useAxiosSelectData(`${urlCatalogues}/tipo-prestamo`);
  const { data: paisData, loading: paisReady } = useAxiosSelectData(
    `${urlCatalogues}/paises`
  );
  const { data: deptoData, loading: deptoReady } = useAxiosSelectData(
    `${urlCatalogues}/departmentos`
  );
  const { data: ocupacionData, loading: ocupacionReady } = useAxiosSelectData(
    `${urlCatalogues}/ocupaciones`
  );

  const { data: tipoVData, loading: tipoVReady } = useAxiosSelectData(
    `${urlCatalogues}/tipos_vivienda`
  );
  const [municipio, setMunicipio] = useState<any>([]);
  const handleChangeDep = (e: any) => {
    axios
      .get(`${urlCatalogues}/municipios/${e.target.value}`)
      .then((response: AxiosResponse) => {
        setMunicipio(response.data);
      });
  };

  const uploadData = () => {
    // if(props.customer.departmentId > 0) {
    //   setDepartmentIdSelected(props.customer.departmentId)
    //   if(parseInt(props.customer.townshipId) > 0) {
    //     GetDataSelectSearch(`${urlLocations}/townships/${props.customer.departmentId}`).then(response => {setTownship(response)})
    //     setTownshipIdSelected(parseInt(props.customer.townshipId))
    //   }
    // }
  };

  useEffect(() => {
    uploadData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const { creditId }: any = useParams();
  const [loadin, setLoadin] = useState(false);
  const history = useHistory();

  const modelo: PersonDTO2 = {
    personaId: 0,
    primerNombre: "",
    segundoNombre: "",
    primerApellido: "",
    segundoApellido: "",
    apellidoCasada: "",
    dpi: "",
    direccion: "",
    colonia: "",
    numeroTelefono: "",
    numeroCelular: "",
    numeroTelefonoLaboral: "",
    nit: "",
    paisNacimientoId: "GTM",
    departamentoId: -1,
    municipioId: "",
    estadoCivilId: 0,
    estadoPrestamo: "",
    asesorId: 0,
    empresaPlanillaId: 0,
    direccionLaboral: "",
    ocupacionId: 1,
    comentarios: "",
    tipoPrestamo: 0,
    email: "",
    fechaNacimiento: '',
    generoId: 1,
  };
  const [dataPerson, setDataPerson] = useState<PersonDTO2>(modelo);
  const [departmentIdSelected, setDepartmentIdSelected] = useState<any>();

  const schema = Yup.object({
    primerNombre: Yup.string().required("Campo requerido"),
    segundoNombre: Yup.string(),
    primerApellido: Yup.string().required("Campo requerido"),
    segundoApellido: Yup.string(),
    dpi: Yup.string()
      .required("Campo requerido")
      .min(13, "El número mínimo de caracteres permitidos es 13.")
      .max(13, "El número máximo de caracteres permitidos es 13."),
    colonia: Yup.string(),
    numeroTelefono: Yup.string()
      .min(8, "El número mínimo de caracteres permitidos es 8.")
      .max(8, "El número máximo de caracteres permitidos es 8.").required("Campo requerido"),
    numeroCelular: Yup.string()
      .min(8, "El número mínimo de caracteres permitidos es 8.")
      .max(8, "El número máximo de caracteres permitidos es 8."),
    numeroTelefonoLaboral: Yup.string()
      .min(8, "El número mínimo de caracteres permitidos es 8.")
      .max(8, "El número máximo de caracteres permitidos es 8."),
      nit: Yup.string()
      .required("Campo requerido"),
    direccion: Yup.string().required("Campo requerido"),
    empresaPlanillaId: Yup.number(),
    asesorId: Yup.number().required("Campo requerido"),
    tipoPrestamo: Yup.number().required("Campo requerido"),
    paisNacimientoId: Yup.string().required("Campo requerido").required("Campo requerido"),
    departamentoId: Yup.number().required("Campo requerido"),
    municipioId: Yup.string().required("Campo requerido"),
    estadoCivilId: Yup.number().required("Campo requerido"),
    ocupacionId: Yup.number().required("Campo requerido"),
    comentarios: Yup.string(),
    email: Yup.string().email(
      "Correo no válido (Por ejemplo: someone@example.com)"
    ).required("Campo requerido"),
    generoId: Yup.number(),
    fechaNacimiento: Yup.date().required("Campo requerido"),
  });

  const onSubmit = async (valores: any) => {
    let payload = putPersonaPayload(valores);

    const data = await putPetition(
      `${urlLending}/persona/${creditId}`,
      payload
    );

    if (data === -1)
      return Swal.fire({
        title: `Error`,
        text: "Error no se pudo actualizar",
        icon: "warning",
      });

    Swal.fire({
      title: `Guardado`,
      text: `Ficha Actualizada.`,
      icon: "success",
    });
    history.replace(`/prestamoDetail/${creditId}`);
  };

  const getData = async () => {
    setLoadin(true);
    let data = await getPetition(`${urlLending}/persona/${creditId}`);

    if (data === -1) return;

    let auxResponse = auxResponsePersona(data);
    if (auxResponse.departamentoId > 0) {
      await axios
        .get(`${urlCatalogues}/municipios/${auxResponse.departamentoId}`)
        .then((response: AxiosResponse) => {
          setMunicipio(response.data);
        });
    }
    setDataPerson(auxResponse);
    setDepartmentIdSelected(auxResponse.departamentoId);
    setLoadin(false);
  };

  useEffect(() => {
    getData();
  }, []);

  return (
    <div>
      <Navbar show={false}></Navbar>
      {!estadoCivilReady &&
      !gestoresReady &&
      !empresaPlanillaReady &&
      !tipoPrestamoReady &&
      !deptoReady &&
      !paisReady &&
      !ocupacionReady &&
      !tipoVReady &&
      !loadin ? (
        <>
          <FormEditPerson
            modelo={dataPerson}
            onSubmit={onSubmit}
            schema={schema}
            estadoCivil={estadoCivilData}
            emprsaPlanilla={empresaPlanillaData}
            gestores={gestoresData}
            tipoPrestamo={tipoPrestamoData}
            depto={deptoData}
            ocupacion={ocupacionData}
            pais={paisData}
            handleChangeDep={handleChangeDep}
            departmentIdSelected={departmentIdSelected}
            setDepartmentIdSelected={setDepartmentIdSelected}
            muni={municipio}
            tipoV={tipoVData}
          ></FormEditPerson>
        </>
      ) : (
        <>
          <div className="text-center w-100 h-100">
            <div
              className="spinner-border"
              style={{ width: "3rem", height: "3rem" }}
              role="status"
            ></div>
            <span className="d-block color_blue size_type_font">
              Cargando Formulario.
            </span>
          </div>
        </>
      )}
    </div>
  );
};
