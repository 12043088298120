import { Modal } from "rsuite";
import React, { ReactElement } from "react";

export default function ModalFullScreenForm(props: ModalFullScreenFormProps) {
  return (
    <div>
      <Modal
        overflow={true}
        size={props.size}
        backdrop={"static"}
        keyboard={false}
        open={props.open}
        onClose={props.handleClose}
        backdropClassName="modal_fondo"
      >
        <Modal.Body>{props.childrenBody}</Modal.Body>
        <Modal.Footer>{props.childrenFooter}</Modal.Footer>
      </Modal>
    </div>
  );
}

interface ModalFullScreenFormProps {
  title?: ReactElement;
  handleClose: any;
  open?: boolean;
  childrenBody: ReactElement;
  childrenFooter?: ReactElement;
  size: any;
}
