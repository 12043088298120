import { useFormikContext } from "formik";
import MostrarErrorCampo from "./MostrarErrorCampo";

export default function FormGroupFechas(props: FormGroupFechasProps) {
  const { values, validateForm, touched, errors } = useFormikContext<any>();

  return (
    <div className={props.clase}>
      {props.label && (
        <label htmlFor={props.campo} className={props.claseLabel}>
          {props.label}
        </label>
      )}
      <input
        type="date"
        readOnly={props.readonly}
        className={props.claseInput}
        id={props.campo}
        name={props.campo}
        defaultValue={values[props.campo]}
        onChange={(e) => {
          const fecha = new Date(e.currentTarget.value + "T00:00:00");
          values[props.campo] = fecha;
          validateForm();
        }}
      />
      {touched[props.campo] && errors[props.campo] ? (
        <MostrarErrorCampo mensaje={errors[props.campo]?.toString()!} />
      ) : null}
    </div>
  );
}

interface FormGroupFechasProps {
  clase: string;
  claseLabel: string;
  claseInput: string;
  campo: string;
  label?: string;
  readonly?: boolean;
}

FormGroupFechas.defaultProps = {
  class: "form-group",
  claseLabel: "mb-2",
  claseInput: "form-control",
};
