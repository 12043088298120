import { useEffect, useRef, useState } from "react";
import Navbar from "../../Components/Navbar";
import { FormPersonalData } from "./FormPersonalData";
import * as Yup from "yup";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPrint } from "@fortawesome/free-solid-svg-icons";
import ReactToPrint from "react-to-print";
import { PrestamoDetalle } from "./PrestamoDetalle";
import ModalFullScreenForm from "../../Components/ModalFullScreen";
import { TableConsultaBitacora } from "../../Components/TableConsultaBitacora";
import { TableDetallePrestamo } from "../../Components/DetallePrestamo";
import { useHistory, useParams } from "react-router-dom";
import axios, { AxiosResponse } from "axios";
import { LendingPost } from "../../Interfaces/lending.model";
import { persona } from "../../Interfaces/persona.model";
import { urlLending } from "../../Utils/endpoints";
import Swal from "sweetalert2";
import { PrintComponent } from "../../Components/PrintComponent";
import {
  auxResponseDataLendingPerson,
  auxResponseDataLendingPrestamo,
  createPLanPagoPayload,
  createPostPlanPagoPayload,
} from "../../Utils/CreateObjects";
import { postPetition } from "../../Utils/ServicesPetitions";
import moment from "moment";

export const PrestamoDetail = () => {
  const [loading, setLoading] = useState(true);
  const componentRef = useRef(null);
  const [openModalBitacora, setOpenModalBitacora] = useState(false);
  const [planPagoShow, setPlanPagoShow] = useState(false);
  const { creditId }: any = useParams();
  const [datosPersona, setdatosPersona] = useState<persona>();
  const [datosDetallePrestamo, setDatosDetallePrestamo] = useState({});
  const [dataPlanPago, setDataPlanPago] = useState([]);
  const [planPagoPost, setPlanPagoPost] = useState<LendingPost>();
  const history = useHistory();
  const [mora, setMora] = useState("");

  const schema = Yup.object({
    phoneNumber: Yup.string(),
    name: Yup.string(),
    dpi: Yup.string(),
    estado: Yup.string(),
    address: Yup.string(),
    estatus: Yup.string(),
    gestor: Yup.string(),
    nit: Yup.string(),
    empresaPlanilla: Yup.string(),
  });

  const modeloPrestamoDetalle = {
    plazo: "0",
    interes: "0",
    mora: "0",
    gastosAdministracion: "0",
    iva: "12",
    tipoPlan: "1",
    fecha: new Date(),
    fechaPago: new Date(),
  };

  const schemaPrestamo = Yup.object({
    plazo: Yup.number(),
    fecha: Yup.string(),
    interes: Yup.number(),
    mora: Yup.number(),
    gastosAdministracion: Yup.number(),
    iva: Yup.number(),
    tipoPlan: Yup.string(),
  });

  useEffect(() => {
    getDataLending();
  }, []);

  const handleOpenModalBitacora = () => {
    setOpenModalBitacora(true);
  };

  const handleCloseModalBitacora = () => {
    setOpenModalBitacora(false);
  };

  const onSubmit = async (valores: any) => {
    let payload = createPostPlanPagoPayload(
      dataPlanPago,
      creditId,
      planPagoPost,
      mora
    );

    if (dataPlanPago.length === 0) {
      Swal.fire({
        title: `Error`,
        text: `No puede guardar sin un plan de pago valido.`,
        icon: "warning",
      });
    } else {
      const data = await postPetition(`${urlLending}/plan-pago`, payload);

      if (data === -1)
        return Swal.fire({
          title: `Error`,
          text: `No se pudo guardar el plan de pago.`,
          icon: "warning",
        });

      history.replace("/home");
      Swal.fire({
        title: `Guardado`,
        text: `Plan de pago guardado exitosamente.`,
        icon: "success",
      });
    }
  };

  const getDataLending = () => {
    axios.get(`${urlLending}/${creditId}`).then((response: AxiosResponse) => {
      let auxPersona = auxResponseDataLendingPerson(response);
      setdatosPersona(auxPersona);

      setValorTemporal(response.data.lendingP[0].montoTotal);
      let modeloPrestamo = auxResponseDataLendingPrestamo(response);

      setDatosDetallePrestamo(modeloPrestamo);
      setLoading(false);
    });
  };

  const IsValidDate =(date: Date)=>{
    return date instanceof Date && !isNaN(date.getTime());
  }

  const changePago = async (valores: any) => {

    if( !IsValidDate( valores.fecha) || !IsValidDate( valores.fechaPago) ){
      Swal.fire("Error","Fechas no válidas","warning")
      return
    }

    if( moment(valores.fecha).format().split("T")[0] < moment(new Date()).format().split("T")[0] || 
        moment(valores.fecha).format().split("T")[0] > moment(valores.fechaPago).format().split("T")[0]
        ){
      Swal.fire("Error","La Fecha de Aprobación no válida.","warning")
      return
    }

    if(moment(valores.fechaPago).format().split("T")[0] < moment((new Date())).format().split("T")[0] || 
       moment(valores.fechaPago).format().split("T")[0] < moment(valores.fecha).format().split("T")[0]){
      Swal.fire("Error","La Fecha de Pago no válida.","warning")
      return
    }

    setPlanPagoShow(false);
    if (parseInt(valores.plazo) <= 0) {
      Swal.fire({
        title: `Error`,
        text: `El plazo de las condiciones de pago no puede ser cero`,
        icon: "warning",
      });
      setDataPlanPago([]);
    } else if (valorTemporal === "" || valorTemporal === "0") {
      Swal.fire({
        title: `Error`,
        text: `El monto total no puede ser cero`,
        icon: "warning",
      });
      setDataPlanPago([]);
    } else {
      let lendingPost = createPLanPagoPayload(valores, valorTemporal);
      setPlanPagoPost(lendingPost);
      setMora(valores.mora);

      const data = await postPetition(
        `${urlLending}/plan-pago-temporal`,
        lendingPost
      );

      if (data === -1) setDataPlanPago([]);

      setDataPlanPago(data);
      setPlanPagoShow(true);
    }
  };

  // Temporal
  const [valorTemporal, setValorTemporal] = useState("");
  const changeValorTemporal = (event: any) => {
    setValorTemporal(event.target.value);
  };

  return (
    <>
      <Navbar show={false}></Navbar>
      {!loading ? (
        <>
          <div className="container-fluid pr-sm-5">
            <FormPersonalData
              modelo={datosPersona}
              onSubmit={onSubmit}
              validationSchema={schema}
              readonly={true}
              consultaBitacora={handleOpenModalBitacora}
              new={true}
              ocultar={true}
              route={false}
              destinoPrestamo={[]}
              estadoCivil={[]}
              gestores={[]}
              tipoPrestamo={[]}
            ></FormPersonalData>
          </div>
          <div className="container-fluid pl-sm-5">
            <PrestamoDetalle
              modelo={datosDetallePrestamo}
              modeloTasas={modeloPrestamoDetalle}
              readonly={false}
              click={changePago}
              validationSchema={schemaPrestamo}
              valor={valorTemporal}
              changeValor={changeValorTemporal}
            ></PrestamoDetalle>
          </div>
          {/* Plan de Pago */}
          <div className="container-fluid row">
            <h4 className="mt-2 mb-3 col-10">
              Plan de Pago
              {planPagoShow && (
                <ReactToPrint
                  trigger={() => {
                    return (
                      <FontAwesomeIcon
                        className="mouse-pointer orange-icon ml-4"
                        icon={faPrint}
                      ></FontAwesomeIcon>
                    );
                  }}
                  content={() => componentRef.current}
                  documentTitle="new Document"
                  pageStyle="print"
                ></ReactToPrint>
              )}
            </h4>
          </div>
          {/* Tablas */}
          <div className="container-fluid pl-4 pr-4 pb-3">
            <TableDetallePrestamo data={dataPlanPago!}></TableDetallePrestamo>
            <PrintComponent
              printReference={componentRef}
              dpi={datosPersona?.dpi}
              nombre={datosPersona?.name}
              table={
                <TableDetallePrestamo
                  data={dataPlanPago!}
                ></TableDetallePrestamo>
              }
            ></PrintComponent>
          </div>
        </>
      ) : (
        <div className="text-center w-100 h-100">
          <div
            className="spinner-border"
            style={{ width: "3rem", height: "3rem" }}
            role="status"
          ></div>
          <span className="d-block color_blue size_type_font">
            Cargando datos del Prestamo.
          </span>
        </div>
      )}
      {/* Modal Consulta Bitacora */}
      <ModalFullScreenForm
        childrenBody={
          <TableConsultaBitacora
            data={[]}
            loading={false}
            handleCloseModal={handleCloseModalBitacora}
          ></TableConsultaBitacora>
        }
        handleClose={handleCloseModalBitacora}
        open={openModalBitacora}
        size="md"
      ></ModalFullScreenForm>
    </>
  );
};
