import { faCheckCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Formik, FormikHelpers, Form } from "formik";
import { useEffect, useState } from "react";
import { useLocation, useParams } from "react-router-dom";
import Button from "../../Utils/Button";
import FormGroupSelect from "../../Utils/FormGroupSelect";
import FormGroupSelect2 from "../../Utils/FormGroupSelect2";
import FormGroupText from "../../Utils/FormGroupText";
import FormGroupText2 from "../../Utils/FormGroupText2";
import { OnlyNumber, OnlyNumberFloat } from "../../Utils/Validaciones";

export const FormCompanyData = (props: formCompanyDataInterface) => {
  const { creditId }: any = useParams();

  const [ruta, setRuta] = useState(false);
  const [rutaPrestamoActivo, setRutaPrestamoActivo] = useState(false);

  const rutaActiva = useLocation();

  useEffect(() => {
    rutaActiva.pathname === "/newCompanyPrestamo"
      ? setRuta(true)
      : setRuta(false);
    rutaActiva.pathname.split("/")[1] === "prestamoCompanyDetail"
      ? setRutaPrestamoActivo(true)
      : setRutaPrestamoActivo(false);
  }, []);

  return (
    <>
      <Formik
        initialValues={props.modelo}
        onSubmit={props.onSubmit}
        validationSchema={props.validationSchema}
      >
        {(formikProps) => (
          <Form>
            <div className="row">
              <div className="col-12 col-md-2 justify-content-center align-items-center d-flex mb-2 mb-sm-none">
                <div className="person-icon">
                  <svg
                    id="Layer_1"
                    data-name="Layer 1"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 122.88 105.55"
                  >
                    <title>office-building</title>
                    <path d="M36.24,102.43H86.43l0-99.31H36.24v99.31Zm53.52,0H120V33.75H89.77v68.68Zm-56.88,0V33.75h-30v68.68ZM1.44,105.55A1.44,1.44,0,0,1,0,104.11V32.74a2.15,2.15,0,0,1,.62-1.5,2.12,2.12,0,0,1,1.5-.62h31V2.53A2.54,2.54,0,0,1,35.65,0H87a2.53,2.53,0,0,1,2.53,2.53V30.62h31.23a2.11,2.11,0,0,1,1.49.62h0a2.11,2.11,0,0,1,.62,1.49v71.37a1.44,1.44,0,0,1-1.44,1.44ZM71.67,81.18h8.49a.24.24,0,0,1,.24.24v8.49a.24.24,0,0,1-.24.24H71.67a.24.24,0,0,1-.24-.24V81.42a.24.24,0,0,1,.24-.24Zm-14.59,0h8.49a.24.24,0,0,1,.24.24v8.49a.24.24,0,0,1-.24.24H57.08a.24.24,0,0,1-.24-.24V81.42a.24.24,0,0,1,.24-.24Zm-14.59,0H51a.24.24,0,0,1,.24.24v8.49a.24.24,0,0,1-.24.24H42.49a.24.24,0,0,1-.24-.24V81.42a.24.24,0,0,1,.24-.24Zm54.66-8.26H113.3v5.83l-16.15.18v-6Zm-87.44,0H25.62v5.83l-15.91.18v-6ZM42.46,8.47H80.2a1.16,1.16,0,0,1,1.15,1.15V39.14A1.16,1.16,0,0,1,80.2,40.3H42.46a1.16,1.16,0,0,1-1.16-1.16V9.62a1.16,1.16,0,0,1,1.16-1.15ZM97.15,61.69H113.3v5.83l-16.15.17v-6ZM113.3,50.45v5.83l-16.39.18V50.62c0-.36,14.92-.17,16.39-.17ZM9.71,61.69H25.86v5.83l-16.15.17v-6Zm0-11.24H25.86v5.83l-16.15.18v-6Zm62,15.84h8.49a.24.24,0,0,1,.24.24V75a.24.24,0,0,1-.24.24H71.67a.24.24,0,0,1-.24-.24V66.53a.24.24,0,0,1,.24-.24Zm-14.59,0h8.49a.24.24,0,0,1,.24.24V75a.24.24,0,0,1-.24.24H57.08a.24.24,0,0,1-.24-.24V66.53a.24.24,0,0,1,.24-.24Zm-14.59,0H51a.24.24,0,0,1,.24.24V75a.24.24,0,0,1-.24.24H42.49a.24.24,0,0,1-.24-.24V66.53a.24.24,0,0,1,.24-.24ZM71.67,49.81h8.49a.24.24,0,0,1,.24.24v8.49a.24.24,0,0,1-.24.24H71.67a.24.24,0,0,1-.24-.24V50.05a.24.24,0,0,1,.24-.24Zm-14.59,0h8.49a.24.24,0,0,1,.24.24v8.49a.24.24,0,0,1-.24.24H57.08a.24.24,0,0,1-.24-.24V50.05a.24.24,0,0,1,.24-.24Zm-14.59,0H51a.24.24,0,0,1,.24.24v8.49a.24.24,0,0,1-.24.24H42.49a.24.24,0,0,1-.24-.24V50.05a.24.24,0,0,1,.24-.24Z" />
                  </svg>
                </div>
              </div>
              <div className="col-12 col-md-10">
                <div className="row">
                  <div className="col-12 col-md-6">
                    <div className="row mb-2">
                      <FormGroupText2
                        campo="name"
                        clase="col-10"
                        claseLabelParent="col-2"
                        label="Nombre"
                        type="text"
                        readonly={props.readonly}
                      ></FormGroupText2>
                    </div>
                    <div className="row mb-2">
                      <FormGroupText2
                        campo="phoneNumber"
                        clase="col-10 col-sm-4"
                        claseLabelParent="col-2"
                        label="Teléfono"
                        readonly={props.readonly}
                        type="text"
                        changeValue={OnlyNumber}
                      ></FormGroupText2>
                    </div>
                    <div className="row mb-2">
                      <FormGroupText2
                        campo="address"
                        clase="col-10"
                        claseLabelParent="col-2"
                        label="Dirección"
                        type="text"
                        readonly={props.readonly}
                      ></FormGroupText2>
                    </div>
                  </div>
                  <div className="col-12 col-md-3 d-flex flex-column justify-content-centermb-2 mb-sm-none">
                    <div className="row mb-2">
                      {props.route ? (
                        <>
                          <div className="col-12 col-sm-12">
                            <FormGroupSelect2
                              campo="gestor"
                              opciones={props.gestores}
                              label="Gestor"
                              className="row justify-content-center"
                              claseInput="form-control col-9"
                              claseLabel="font-16 col-3"
                            ></FormGroupSelect2>
                          </div>
                        </>
                      ) : (
                        <>
                          <FormGroupText2
                            campo="gestor"
                            clase="col-9"
                            claseLabelParent="col-3"
                            label="Gestor"
                            type="text"
                            readonly={props.readonly}
                          ></FormGroupText2>
                        </>
                      )}
                    </div>
                    <div className="row mb-2">
                      <FormGroupText2
                        campo="nit"
                        clase="col-9"
                        claseLabelParent="col-3"
                        label="Nit"
                        type="text"
                        readonly={props.readonly}
                      ></FormGroupText2>
                    </div>
                  </div>
                  <div className="col-12 col-md-3 d-flex justify-content-center align-items-center">
                    {/* {!props.route && (
                      <div className="row ">
                        <Button className="orange-dark-button col-6 mb-2">
                          Transferir
                        </Button>
                        <Button
                          className="orange-dark-button col-6 mb-2"
                          onClick={() => props.consultaBitacora!()}
                        >
                          Ver Bitacora
                        </Button>
                        <Button className="orange-dark-button col-6 mb-2">
                          Cambiar de Estatus
                        </Button>
                        <Button className="orange-dark-button col-6 mb-2">
                          Agregar Bitacora
                        </Button>
                      </div>
                    )} */}
                  </div>
                </div>
              </div>
              {/*  */}
              <hr className="hr-2 col-12" />

              <div className="d-flex justify-content-between w-100">
                {ruta ? (
                  <h4 className="pl-5">Nuevo Préstamo</h4>
                ) : (
                  <>
                    <span className="d-block d-md-flex align-items-center">
                      <h4 className="pl-md-5 pr-md-5  mr-md-5">
                        Préstamo #{creditId}
                      </h4>
                      {props.referenciaPrestamo && (
                        <h5 className="pl-md-5 pr-md-5 mr-md-5">
                          Referencia No. - {props.referenciaPrestamo}
                        </h5>
                      )}
                      {props.prestamoTipoNombre && (
                        <h5 className="pl-md-5 ml-md-5  ">
                          {props.prestamoTipoNombre}
                        </h5>
                      )}
                    </span>
                  </>
                )}

                {rutaPrestamoActivo === false && (
                  <Button type="submit" className="button-transparent">
                    <FontAwesomeIcon
                      className="mouse-pointer orange-icon ml-4"
                      icon={faCheckCircle}
                      style={{ height: "2rem" }}
                    ></FontAwesomeIcon>
                  </Button>
                )}
              </div>
              <hr className="hr-2 col-12" />
              {props.new && !props.ocultar && (
                <>
                  <div className="ml-sm-5 mb-4 col-12 col-md-6">
                    <div className="row">
                      <div className="col-12 col-sm-6">
                        <FormGroupSelect
                          campo="destinoPrestamoId"
                          opciones={props.destinoPrestamo}
                          label="Destino Prestamo"
                          claseInput="form-control"
                          claseLabel="font-weight-bold font-16"
                        ></FormGroupSelect>
                      </div>
                      <div className="col-12 col-sm-6">
                        <FormGroupSelect
                          campo="tipoPrestamoId"
                          opciones={props.tipoPrestamo}
                          label="Tipo Prestamo"
                          claseInput="form-control"
                          claseLabel="font-weight-bold font-16"
                        ></FormGroupSelect>
                      </div>
                    </div>
                    <div className="row">
                      <FormGroupText
                        campo="montoCapital"
                        clase="col-12 col-sm-6"
                        claseLabel="font-weight-bold font-16"
                        label="Monto Préstamo"
                        readonly={props.readonly}
                        type="text"
                        changeValue={OnlyNumberFloat}
                      ></FormGroupText>
                    </div>
                  </div>
                </>
              )}
              {/*  */}
            </div>
          </Form>
        )}
      </Formik>
    </>
  );
};

interface formCompanyDataInterface {
  modelo: any;
  onSubmit(valores: any, acciones: FormikHelpers<any>): void;
  error?: string[];
  validationSchema: any;
  consultaBitacora?(): void;
  readonly: boolean;
  new: boolean;
  ocultar?: boolean;
  loading?: boolean;
  route: boolean;
  destinoPrestamo?: any[];
  gestores?: any[];
  tipoPrestamo?: any[];
  prestamoTipoNombre?: string;
  referenciaPrestamo?: string;
}
